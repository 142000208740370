const ELLIPSIS = '\u2026';

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { SearchService } from '../../search/search.service';
import { SearchQueryDef } from '../../search/search-query-def';
import { PermitService } from '../services/permit.service';
import { Permit } from '@common/types/models/permit.interface';
import { Entity } from '@common/types';
import { ApiResponse } from '@services/models/api-response';

@Component({
    selector: 'permit-select',
    template: `
    <div>
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="permitFormatter"
            [keyFormatter]="permitKeyFormatter"
            [search]="searchPermitsTemplate"
            [keySearch]="searchPermitsByKey"  
            [required]="required"
            [disabled]="disabled"
            [placeholder]="placeholder"
            [namespace]="'permit'"
            (selectItem)="selectItemHandler()"
            #typeahead
        ></climb-typeahead>
    </div>
    `
})
export class PermitSelectComponent implements OnInit {
    @Input() model: number;
    @Input() required: boolean;
    @Input() disabled: boolean;
    // Only returns animal type orders if true
    @Input() animalOrdersOnly = false;

    @Output() modelChange: EventEmitter<number> = new EventEmitter<number>();

    placeholder: string;

    constructor(
        private searchService: SearchService
    ) {
    }

    ngOnInit(): void {
        this.placeholder = 'Permit Number' + ELLIPSIS;
    }

    selectItemHandler(): void {
        this.modelChange.emit(this.model);
    }

    searchPermitsTemplate = (search: string): Promise<ApiResponse<Entity<Permit>[]>> => {
        const filter = {
            PermitNumber: search
        };

        return this.searchPermits(filter, 50);
    }

    searchPermitsByKey = (key: string): Promise<ApiResponse<Entity<Permit>[]>> => {
        const filter = {
            PermitKey: key
        };
        return this.searchPermits(filter, 1);
    }

    private async searchPermits(thisFilter: { PermitKey?: string, PermitNumber?: string }, count: number): Promise<ApiResponse<Entity<Permit>[]>> {
        const searchQueryDef: SearchQueryDef = {
            entity: 'Permits',
            page: null,
            size: count,
            sortColumn: 'PermitNumber',
            sortDirection: 'asc',
            isActive: true,
            filter: thisFilter
        };     
        const results = await this.searchService.getEntitiesBySearch(searchQueryDef);
        return results.data;
    }

    permitKeyFormatter = (value: Entity<Permit>): number => {
        return value.C_Permit_key;
    }

    permitFormatter = (value: Entity<Permit>): string => {
        return value.PermitNumber;
    }
}
