import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { FacetSharedModule } from '@common/facet/facet-shared.module';
import { ClimbCommonModule } from '@common/climb-common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClimbServicesModule } from '@services/climb-services.module';
import { PermitsFacetComponent } from './components/permits-facet.component';
import { PermitService } from './services/permit.service';
import { PermitDetailComponent } from './components/permit-detail.component';
import { UsersSharedModule } from '../user/users-shared.module';
import { InstitutionsSharedModule } from '../institution/institutions-shared.module';
import { PermitSpeciesComponent } from './components/permit-species.component';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { PermitSelectComponent } from './components/permit-select.component';
import { PermitsFilterComponent } from './components/permits-filter.component';
import { PermitMultiselectComponent } from './components/permit-multiselect.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        WorkspacesSharedModule,
        UsersSharedModule,
        InstitutionsSharedModule,
        LinesSharedModule,
    ],
    declarations: [
        PermitsFacetComponent,
        PermitDetailComponent,
        PermitSpeciesComponent,
        PermitSelectComponent,
        PermitsFilterComponent,
        PermitMultiselectComponent,
    ],
    exports: [
        PermitsFacetComponent,
        PermitSelectComponent,
        PermitsFilterComponent,
        PermitMultiselectComponent,
    ],
    providers: [
        PermitService,
    ]
})
export class PermitsModule { }
