<div class="facet">
    <facet-header
        caption="Permits"
        [facet]="facet">
        <button *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="openFilter()"
                title="Filter Permits">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button data-automation-id="copy-permits-button"
            *ngIf="facetView === LIST_VIEW && facet.Privilege === 'ReadWrite'"
            climbFacetAction
            [disabled]="!selectedRows.length"
            (click)="copyPermits()"
            title="Copy Permits">
            <svg [climbIcon]="icons.squareOnSquare"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>

    <div class="facet-body" *ngIf="facet.Privilege !== 'None'">
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <climb-export-dropdown #exportDropdown
                                       [options]="permitTableOptions.options"
                                       [dataService]="dataService"
                                       [componentName]="componentName">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table data-automation-id="permits-table"
                #dataTable
                [dataService]="dataService"
                [options]="permitTableOptions.options"
                [(gridStateJson)]="facet.GridState"
                [(selectedRows)]="selectedRows"
                [(draggedRows)]="draggedRows"
                (dragStart)="dragStart()"
                (dragStop)="dragStop()"
                (selectedColumnsChange)="selectedColumnsChange($event)"
                (addItemClick)="addItemClick()"
                (detailLinkClick)="detailLinkClick($event)"
                (columnStateChanged)="dataTableColumns.next($event)"
                (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <permit-detail *ngIf="facetView === DETAIL_VIEW"
            [facet]="facet"
            [facetView]="facetView"
            [permit]="itemToEdit"
            [pageState]="detailPaginator.pageState"
            (exit)="exitDetail()"
            (previous)="detailPreviousClicked()"
            (next)="detailNextClicked()"
            (modelCopy)="modelCopied($event)"
            (reload)="refreshData()">
        </permit-detail>
    </div>
</div>
