import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';
import { buildMasterCvList, CVListItem } from '../vocabularies/master-cv-list';
import { TranslationService } from '../services/translation.service';
import { FeatureFlagService, LARGE_ANIMAL } from '../services/feature-flags.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'active-vocab-typeahead-multi-select',
    template: `
    <div *ngIf="model | notEmpty">
        <table class="table table-sm table-borderless table-nonfluid">
            <tbody>
                <tr *ngFor="let item of model; let i = index;">
                    <td class="icon-cell">
                        <a (click)="removeItem(i)" title="Remove {{vocabDefinition?.friendlyName}}">
                            <i class="fa fa-remove remove-item"></i>
                        </a>
                    </td>
                    <td>
                        {{item[vocabDefinition.fieldName]}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <climb-indirect-typeahead
            [search]="searchVocabItems"
            [resultFormatter]="vocabItemFormatter"
            placeholder="{{vocabDefinition.friendlyName}}..."
            [disabled]="readonly"
            (selectItem)="selectItem($event)"></climb-indirect-typeahead>
    `,
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ActiveVocabTypeaheadMultiSelectComponent),
            multi: true,
        }
    ]
})
export class ActiveVocabTypeaheadMultiSelectComponent implements OnInit, ControlValueAccessor {
    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    @Input() vocabTableName: string;

    @Input() vocabDefinition: CVListItem;

    // Optional include both active and inactive values
    @Input() includeInactive: boolean;

    // selector is disabled if set to have readonly access
    @Input() readonly: boolean;

    isGLP: boolean;
    largeAnimalEnabled: boolean;

    readonly MAX_SEARCH_RESULTS = 50;

    onChange = (value: unknown[]): void => {/*empty*/};
    onTouched = (): void => {/*empty*/};

    constructor(
        private _searchService: SearchService,
        private translationService: TranslationService,
        private featureFlagService: FeatureFlagService,
    ) { }

    ngOnInit() {
        this.init();
    }

    init() {
        // This is needed because some elements of the Master CV list will vary depending on the isGLP flag
        this.initIsGLP();
        this.initLargeAnimalEnabled();
        const allVocabs = buildMasterCvList(this.translationService, this.isGLP, this.largeAnimalEnabled);
        if (!this.vocabDefinition) {
            this.vocabDefinition = allVocabs.find((v: any) => v.tableName === this.vocabTableName);
        }
    }

    removeItem(index: number) {
        if (this.model && index >= 0 && index < this.model.length) {
            this.model.splice(index, 1);
        }

        this.modelChange.emit(this.model);
        this.onChange(this.model);
    }

    searchVocabItems = (term: string): Promise<any> => {
        let count = null;
        if (term.length < 3) {
            count = this.MAX_SEARCH_RESULTS;
        }

        const filter = {};
        const key = this.vocabDefinition.fieldName;
        filter[key] = term;
        if (!this.includeInactive) {
            const IS_ACTIVE_KEY = 'IsActive';
            filter[IS_ACTIVE_KEY] = true;
        }

        const searchQueryDef: SearchQueryDef = {
            entity: this.vocabDefinition.tableNameForProvider,
            page: 1,
            size: count,
            sortColumn: this.vocabDefinition.fieldName,
            sortDirection: 'asc',
            filter
        };

        return this._searchService.getEntitiesBySearch(searchQueryDef)
            .then((results: any) => {
                return results.data;
            });
    }

    vocabItemFormatter = (value: any) => {
        return value[this.vocabDefinition.fieldName];
    }

    selectItem(vocabItem: any) {
        if (this.model) {
            this.model.push(vocabItem);
        } else {
            this.model = [vocabItem];
        }

        this.modelChange.emit(this.model);
        this.onChange(this.model);
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    writeValue(value: unknown[]): void {
        this.model = value;
    }

    initLargeAnimalEnabled() {
        this.largeAnimalEnabled = this.featureFlagService.isFlagOn(LARGE_ANIMAL);
    }
}
