import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
    EntityQuery,
    Predicate
} from 'breeze-client';

import { notEmpty } from '../common/util/not-empty';

import { AuthService } from '../services/auth.service';
import { WorkspaceManagerService } from '../services/workspace-manager.service';
import { BaseEntityService } from '../services/base-entity.service';
import {sortObjectArrayByAccessor, sortObjectArrayByProperties, uniqueArrayFromPropertyPath} from '../common/util';
import { Workspace } from '@common/types/models/workspace.interface';
import { Entity } from '@common/types';
import { CustomizeWorkspaceService } from '@services/customize-workspace.service';
import { WorkspaceFilterService } from '@services/workspace-filter.service';
import { RoutingService } from '../routing/routing.service';
import {
    IconContent,
    alertOctagon,
    alertOctagonFilled,
    animals,
    animalsFilled,
    bell,
    bellFilled,
    births,
    birthsFilled,
    book,
    bookFilled,
    businessCenter,
    businessCenterFilled,
    calendar,
    calendarFilled,
    census,
    censusFilled,
    chart,
    clinical,
    clinicalFilled,
    cloudOut,
    cloudOutFilled,
    cohorts,
    cohortsFilled,
    flag,
    flagFilled,
    gear,
    gearFilled,
    genotype,
    housing,
    housingFilled,
    key,
    lines,
    listView,
    locations,
    locationsFilled,
    magnifier,
    mating,
    mechanism,
    mechanismFilled,
    orders,
    ordersFilled,
    orderDown,
    pin,
    pinFilled,
    plate,
    plateFilled,
    reports,
    reportsFilled,
    tasks,
    tasksFilled,
    tube,
    tubeFilled,
    user,
    userFilled,
    users,
    usersFilled,
    vocabularies,
    vocabulariesFilled,
    wifi,
    wifiFilled,
    workflow,
    workspacePremium,
} from '@common/icons';
import {WebApiService} from "@services/web-api.service";
import { workspacePremiumFilled } from '@common/icons/defs/workspace-premium-filled.def';

@Injectable()
export class WorkspaceService extends BaseEntityService {
    readonly facetIcons = new Map<string, { name: IconContent, header: IconContent }>(Object.entries({
        'Animals': { name: animals, header: animalsFilled },
        'Audit': { name: flag, header: flagFilled },
        'Births': { name: births, header: birthsFilled },
        'Characteristics': { name: mechanism, header: mechanismFilled },
        'Clinical': { name: clinical, header: clinicalFilled },
        'Constructs': { name: plate, header: plateFilled },
        'Enumerations': { name: listView, header: listView },
        'Genotypes': { name: genotype, header: genotype },
        'Housing': { name: housing, header: housingFilled },
        'Jobs': { name: pin, header: pinFilled },
        'Lines': { name: lines, header: lines },
        'Locations': { name: locations, header: locationsFilled },
        'Matings': { name: mating, header: mating },
        'Monitoring': { name: bell, header: bellFilled },
        'Permits': { name: workspacePremium, header: workspacePremiumFilled },
        'Plates': { name: plate, header: plateFilled },
        'Protocols': { name: orderDown, header: orderDown },
        'Reporting': { name: reports, header: reportsFilled },
        'Resources': { name: users, header: usersFilled },
        'Roles': { name: key, header: key },
        'Samples': { name: tube, header: tubeFilled },
        'Schedule': { name: calendar, header: calendarFilled },
        'Search': { name: magnifier, header: magnifier },
        'Settings': { name: gear, header: gearFilled },
        'Studies': { name: book, header: bookFilled },
        'Tasks': { name: tasks, header: tasksFilled },
        'Users': { name: user, header: userFilled },
        'Vocabularies': { name: vocabularies, header: vocabulariesFilled },
        'Workflows': { name: workflow, header: workflow },
        'Charts': { name: chart, header: chart },
        'Import': { name: cloudOut, header: cloudOutFilled },
        'Cohorts': { name: cohorts, header: cohortsFilled },
        'IoT Alerts': { name: alertOctagon, header: alertOctagonFilled },
        'Devices': { name: wifi, header: wifiFilled },
        'IoT Plots': { name: chart, header: chart },
        'Institutions': { name: businessCenter, header: businessCenterFilled },
        'Jobs Pharma': { name: pin, header: pinFilled },
        'Orders': { name: orders, header: ordersFilled },
        'Census': { name: census, header: censusFilled },
        'CageCards': { name: reports, header: reportsFilled },
    }));

    readonly WORKSPACE_NAME_DEFAULT = 'Untitled';
    readonly WORKSPACE_NAME_LENGTH_MAX = 35;
    
    private gridsterReflowSource = new Subject<void>();
    gridsterReflow$ = this.gridsterReflowSource.asObservable();

    private currentWorkspaceSource = new Subject<Entity<Workspace>>();
    currentWorkspace$ = this.currentWorkspaceSource.asObservable();

    private workspacesSource = new BehaviorSubject<Entity<Workspace>[]>(null);
    workspaces$ = this.workspacesSource.asObservable();

    private workspaceConnectivity = new Subject<any>();
    workspaceConnectivity$ = this.workspaceConnectivity.asObservable();

    currentWorkspace: Entity<Workspace> | null;
    workspaces: Entity<Workspace>[] | null = null;

    get isCustomizeWorkspaceActive(): boolean {
        return this.customizeWorkspaceService.isCustomizeWorkspaceActive();
    }

    constructor(
        private workspaceManager: WorkspaceManagerService,
        private authService: AuthService,
        private webApiService: WebApiService,
        private customizeWorkspaceService: CustomizeWorkspaceService,
        private workspaceFilterService: WorkspaceFilterService,
        private routingService: RoutingService,
    ) {
        super();
    }

    gridsterReflow() {
        this.gridsterReflowSource.next();
    }
    openClinicalFacet(animalKey: any) {
        this.workspaceConnectivity.next(animalKey);
    }

    setCurrentWorkspace(workspace: Entity<Workspace> | null) {
        this.collapseAllFacets();
        this.currentWorkspace = workspace;
        this.currentWorkspaceSource.next(workspace);
    }

    navigateToWorkspace(workspace: Entity<Workspace>) {
        // discard any facet configuration changes
        // since those only persist via un-lock mode
        this.workspaceManager.cancel();
        this.customizeWorkspaceService.setCustomizeWorkspaceActive(false);

        if (this.workspaceFilterService.getFilterKind() !== null) {
            // turn off workspace filter before switching
            this.workspaceFilterService.clearWorkspaceFilter();
        }

        this.setCurrentWorkspace(workspace);
        this.routingService.navigateToWorkspacesWithID(workspace);
    }

    getCurrentWorkspace(): any {
        return this.currentWorkspace;
    }

    async fetchWorkspaces(): Promise<Entity<Workspace>[]> {
        const userName: string = this.authService.getCurrentUserName();
        const p1 = new Predicate('UserName', '==', userName);
        const p2 = new Predicate('IsTemplate', '==', true);

        const query = EntityQuery.from('Workspaces')
            .expand('WorkspaceDetail')
            .where(p1.or(p1.and(p2)))
            .orderBy('WorkspaceName');

        await this.workspaceManager.init();
        const workspaces = await this.workspaceManager.returnQueryResults(query);
        this.updateWorkspaces(workspaces);
        if (notEmpty(workspaces)) {
            // set custom properties used by gridster
            let index = 0;
            for (const workspace of workspaces) {
                if (workspace.id === undefined) {
                    workspace.id = index;
                }
                index++;

                for (const facet of workspace.WorkspaceDetail) {
                    facet.expanded = false;
                }
            }
            if (!this.currentWorkspace) {
                this.currentWorkspace = workspaces[0];
            }
        }

        return workspaces;
    }

    updateWorkspaces(workspaces: Entity<Workspace>[]): void {
        this.workspacesSource.next(workspaces);
        this.workspaces = workspaces;
    }

    getSortedWorkspaces(workspaces: Entity<Workspace>[] | null): Entity<Workspace>[] {
        if (!workspaces) {
            return [];
        }

        workspaces = [...workspaces];
        sortObjectArrayByAccessor(workspaces, (workspace) => {
            let workspaceName: string = workspace.WorkspaceName;
            if (workspaceName && workspaceName.toLocaleLowerCase) {
                workspaceName = workspaceName.toLocaleLowerCase();
            }
            return workspaceName;
        });
        return workspaces;
    }

    getWorkspaceTemplates(): Promise<any[]> {
        const query = EntityQuery.from('Workspaces')
            .expand('WorkspaceDetail')
            .where('IsTemplate', '==', true)
            .orderBy('WorkspaceName');

        return this.workspaceManager.returnQueryResults(query);
    }

    createWorkspace(initialValues: any, index: number): any {
        const workspace = this.workspaceManager.createEntity('Workspace', initialValues);

        workspace.UserName = this.authService.getCurrentUserName();

        if (workspace.id === undefined) {
            workspace.id = index;
        }

        return workspace;
    }

    createWorkspaceFromTemplate(initialValues: any, index: number, wsTemplate: any): any {
        const workspace = this.createWorkspace(initialValues, index);

        // copy workspace details from the template workspace
        for (const detail of wsTemplate.WorkspaceDetail) {
            const detailInitialValues = {
                C_Workspace_key: this.currentWorkspace.C_Workspace_key,
                FacetName: detail.FacetName,
                FacetDisplayName: detail.FacetDisplayName,
                Column: detail.Column,
                Row: detail.Row,
                SizeX: detail.SizeX,
                SizeY: detail.SizeY,
                GridFilter: detail.GridFilter,
                GridState: detail.GridState
            };
            const newDetail = this.createWorkspaceDetail(detailInitialValues);
            workspace.WorkspaceDetail.push(newDetail);
        }

        return workspace;
    }

    createWorkspaceDetail(initialValues: any): any {
        const facet = this.workspaceManager.createEntity('WorkspaceDetail', initialValues);

        if (facet.templateURL === undefined) {
            facet.templateURL = "app/facets/" + facet.FacetName.toLowerCase() + ".html";
        }

        return facet;
    }

    getWorkspaceDetail(workspaceDetailKey: number): any {
        const manager = this.workspaceManager.getManager();

        const entityType = 'WorkspaceDetails';
        const sort = 'C_WorkspaceDetail_key';
        const predicate = new Predicate('C_WorkspaceDetail_key', '==', workspaceDetailKey);
        const query = EntityQuery.from(entityType)
            .where(predicate)
            .orderBy(sort);
        const workspaceDetails = manager.executeQueryLocally(query);

        if (notEmpty(workspaceDetails)) {
            return workspaceDetails[0];
        } else {
            return null;
        }
    }

    deleteWorkspace(workspace: any): void {
        while (workspace.WorkspaceDetail.length > 0) {
            const workspaceDetail = workspace.WorkspaceDetail[0];
            this.workspaceManager.deleteEntity(workspaceDetail);
        }

        this.workspaceManager.deleteEntity(workspace);
    }

    deleteWorkspaceDetail(workspaceDetail: any): void {
        this.workspaceManager.deleteEntity(workspaceDetail);
    }

    validateWorkspaceName(workspace: any, allWorkspaces: any[]): void {
        this.resolveBlankOrLongWorkspaceName(workspace);
        this.resolveDuplicateWorkspaceName(workspace, allWorkspaces);
    }

    private resolveBlankOrLongWorkspaceName(workspace: any): void {
        let workspaceName = workspace.WorkspaceName;

        if (!workspaceName) {
            workspaceName = this.WORKSPACE_NAME_DEFAULT;
        } else if (workspaceName.length > this.WORKSPACE_NAME_LENGTH_MAX) {
            workspaceName = workspaceName.substring(0, this.WORKSPACE_NAME_LENGTH_MAX);
        }

        workspace.WorkspaceName = workspaceName;
    }

    private resolveDuplicateWorkspaceName(workspace: any, allWorkspaces: any[]): void {
        let workspaceName = workspace.WorkspaceName;

        // Check uniqueness only if there are other workspaces.
        if (allWorkspaces && allWorkspaces.length > 0) {
            // Get array of all names excluding this workspace.
            const otherNames: string[] = this.getOtherWorkspaceNames(workspace, allWorkspaces);

            // If current name is not unique, add incrementing suffixes until it is.
            if (otherNames.indexOf(workspaceName) !== -1) {
                let ix = 1;
                while (otherNames.indexOf(
                    this.getWorkspaceNameSuffixed(workspaceName, ix)
                ) !== -1) {
                    ix++;
                }

                // Uniqueness achieved with the last suffix.
                workspaceName = this.getWorkspaceNameSuffixed(workspaceName, ix);
            }
        }

        workspace.WorkspaceName = workspaceName;
    }

    /**
     * Constructs array of workspace names excluding the current workspace.
     */
    private getOtherWorkspaceNames(workspace: any, allWorkspaces: any[]): string[] {
        const names: string[] = [];

        for (const testWorkspace of allWorkspaces) {
            if (workspace.C_Workspace_key &&
                testWorkspace.C_Workspace_key !== workspace.C_Workspace_key) {
                names.push(testWorkspace.WorkspaceName);
            }
        }

        return names;
    }

    /**
     * Adds numeric suffix to workspace name.
     */
    private getWorkspaceNameSuffixed(workspaceName: string, index: number): string {
        return workspaceName + ' (' + index + ')';
    }
    
    /**
     * Same as autoSizeFacets, but does not check if facets were changed or saved
     */
    forceAutoSizeFacets() {
        this.autoSizeFacets(true);
    }

    /**
     * Automatically set size values for all facets to fit nicely in the workspace,
     *   given that no facets have been previously saved or manually moved/resized.
     * @param facets 
     */
    autoSizeFacets(forceAutoSize = false) {
        if (!this.currentWorkspace ||
            (!forceAutoSize && this.hasManuallyChangedOrSavedFacets())
        ) {
            return;
        }

        // collapse any expanded facets
        this.collapseAllFacets();
        this._performAutoSizeCalculation(this.currentWorkspace.WorkspaceDetail);
        this.gridsterReflow();
    }

    

    /**
     * Automatically set size values for all facets to fit nicely in the workspace
     * @param facets 
     */
    private _performAutoSizeCalculation(workspaceDetails: any[]) {
        if (!workspaceDetails) {
            return;
        }
        const length = workspaceDetails.length;
        const FULL_WIDTH = 6;
        const HALF_WIDTH = 3;
        const FULL_HEIGHT = 4;
        const HALF_HEIGHT = 2;

        // order by row + column
        workspaceDetails = this.orderFacetsByPosition(workspaceDetails);

        if (length === 1) {
            // full height & width
            setSize(workspaceDetails[0], FULL_WIDTH, FULL_HEIGHT);
            setRowColumn(workspaceDetails[0], 0, 0);
        } else if (length === 2) {
            // full height, 50% width
            setSize(workspaceDetails[0], HALF_WIDTH, FULL_HEIGHT);
            setRowColumn(workspaceDetails[0], 0, 0);
            setSize(workspaceDetails[1], HALF_WIDTH, FULL_HEIGHT);
            setRowColumn(workspaceDetails[1], 0, HALF_WIDTH);
        } else if (length > 2) {
            // 50% height & width
            for (let i = 0; i < length; i++) {
                const workspaceDetail = workspaceDetails[i];
                setSize(workspaceDetail, HALF_WIDTH, HALF_HEIGHT);
                setRowColumn(workspaceDetail, Math.floor(i / 2), (i % 2) * HALF_WIDTH);
            }

            // if odd number, size the last one full width
            if (length % 2 === 1) {
                setSize(workspaceDetails[length - 1], FULL_WIDTH, HALF_HEIGHT);
            }
        }

        function setSize(workspaceDetail: any, x: number, y: number) {
            workspaceDetail.SizeX = x;
            workspaceDetail.SizeY = y;
        } 

        function setRowColumn(workspaceDetail: any, row: number, column: number) {
            workspaceDetail.Row = row;
            workspaceDetail.Column = column;
        } 
    }

    orderFacetsByPosition(workspaceDetails: any[]): any[] {
        workspaceDetails = workspaceDetails.slice();
        // assign an index so we can maintain original order of null values
        for (let i = 0; i < workspaceDetails.length; i++) {
            workspaceDetails[i].__index = i;
        }

        workspaceDetails = sortObjectArrayByProperties(
            workspaceDetails, [
                { key: 'Row' }, 
                { key: 'Column' },
                { key: '__index' }
            ]
        );
        return workspaceDetails;
    }

    hasManuallyChangedOrSavedFacets(): boolean {
        return this.hasSavedFacets() || this.hasManuallyChangedFacets();
    }

    hasSavedFacets(): boolean {
        const savedFacets = this.currentWorkspace.WorkspaceDetail.filter((item: any) => {
            return item.C_WorkspaceDetail_key > 0;
        });
        return savedFacets.length > 0;
    }

    /**
     * checks the isManuallyChanged flag set by the (end) event 
     *   on the gridster-item
     */
    hasManuallyChangedFacets(): boolean {
        const changedFacets = this.currentWorkspace.WorkspaceDetail.filter((item: any) => {
            return item.isManuallyChanged;
        });
        return changedFacets.length > 0;
    }
    
    expandFacet(facetToExpand: any) {

        this.collapseAllFacets();

        const allFacets: any[] = this.currentWorkspace.WorkspaceDetail;
        // save collapsed state of workspace
        for (const facet of allFacets) {
            facet.collapseRow = facet.Row;
            facet.collapseColumn = facet.Column;
            facet.collapseSizeX = facet.SizeX;
            facet.collapseSizeY = facet.SizeY;
            facet.alteredByExpand = true;
        }

        // max size is 6 x 4 columns
        facetToExpand.SizeX = 6;
        facetToExpand.SizeY = 4;
        facetToExpand.Row = 0;
        // -1 tells gridster that this facet must sort first
        facetToExpand.Column = -1;
        facetToExpand.expanded = true;

        this.gridsterReflow();
    }

    collapseAllFacets() {
        const allFacets: any[] = this.currentWorkspace?.WorkspaceDetail ?? [];
        // collapse any expanded facets
        const expandedFacets = allFacets.filter((facet) => {
            return facet.expanded;
        });
        for (const facet of expandedFacets) {
            this.collapseFacet(facet);
        }
    }

    collapseFacet(facetToCollapse: any) {
        if (!facetToCollapse.expanded) {
            return;
        }

        // restore collapsed state of workspace
        for (const facet of this.currentWorkspace.WorkspaceDetail) {
            if (facet.alteredByExpand &&
                facet.collapseSizeX &&
                facet.collapseSizeY
            ) {
                facet.Row = facet.collapseRow;
                facet.Column = facet.collapseColumn;
                facet.SizeX = facet.collapseSizeX;
                facet.SizeY = facet.collapseSizeY;
                facet.alteredByExpand = false;
            }
        }

        facetToCollapse.expanded = false;

        this.gridsterReflow();
    }

    /**
     * Save just the BulkEditConfiguration value for the facet
     *
     * @param facet (aka WorkspaceDetail) entity
     */
    saveBulkEditConfiguration(facet: any): Promise<any> {
        return this.workspaceManager.saveChangesToProperty(facet, 'BulkEditConfiguration');
    }

    /**
     * Save just the BulkDataConfiguration value for the facet
     *
     * @param facet (aka WorkspaceDetail) entity
     */
    saveBulkDataConfiguration(facet: any): Promise<any> {
        return this.workspaceManager.saveChangesToProperty(facet, 'BulkDataConfiguration');
    }

    /**
     * Save just the TaskGridConfiguration value for the facet
     *
     * @param facet (aka WorkspaceDetail) entity
     */
    saveTaskGridConfiguration(facet: any): Promise<any> {
        return this.workspaceManager.saveChangesToProperty(facet, 'TaskGridConfiguration');
    }

    /**
     * Mark Workspace facet as favourite
     *
     * @param workspaceKey number
     * @param favouriteFlag boolean
     */
    markWorkspaceAsFavourite(workspaceKey: number, favouriteFlag: boolean): Promise<any> {
        return this.webApiService.postApi(`api/workspace/mark-as-favourite/${workspaceKey}`, {
            favouriteFlag
        });
    }
}
