import { LoggingService } from './../../services/logging.service';
import { TranslationService } from '../../services/translation.service';
import {
    BulkEditOptions,
    BulkEditField,
    BulkEditSection,
} from '../../common/facet/models';
import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    TemplateRef,
    ViewChild,
    AfterViewInit,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    ViewChildren,
} from '@angular/core';

import { AnimalService } from '../services/animal.service';
import { AnimalVocabService } from '../services/animal-vocab.service';
import { EnumerationService } from '../../enumerations/enumeration.service';
import { JobService } from '../../jobs/job.service';
import { LineService } from '../../lines/line.service';
import { NamingService } from '../../services/naming.service';
import { AnimalLogic } from '../animal-logic.shared';

import {
    notEmpty,
    getSafeProp,
} from '../../common/util';

import { FacetLoadingStateService } from '../../common/facet';
import { FeatureFlagService, IS_DOTMATICS } from '../../services/feature-flags.service';
import { DataManagerService } from '../../services/data-manager.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { JobLogicService } from '../../jobs/job-logic.service';
import { DataContextService } from '../../services/data-context.service';
import { Animal, AnimalComment, Entity, Resource, TaxonCharacteristic, TaxonCharacteristicInstance, TaxonCharacteristicTaxon } from '@common/types';
import { AuthService } from '@services/auth.service';
import { ConfirmOptions, ConfirmService } from '@common/confirm';
import { AnimalCommentsBulkActions } from './animal-comments-bulk-actions.enum';
import { SettingService } from '../../settings/setting.service';
import { CharacteristicService } from '../../characteristics/characteristic.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { CharacteristicInputComponent } from 'src/app/characteristics/characteristic-input/characteristic-input.component';
import { PermitService } from '../../permits/services/permit.service';

/**
 * Shared component and configuration templates
 * for BulkAdd and BulkEdit tables
 * 
 * 
 */
@Component({
    selector: 'animal-bulk-templates',
    templateUrl: './animal-bulk-templates.component.html',
    styles: [
        `
            .bolded {
                font-weight: bold;
            }
            .italicized {
                font-style: italic;
            }
            .hidden {
                visibility: hidden;
            }
            .entity-dropdown {
                min-width: 200px;
            }
        `
    ],
})
export class AnimalBulkTemplatesComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    @Input() animals: any[];
    @Input() loadDefaults: boolean;
    @Input() animalsToAdd: number;
    // Active and required fields set by facet settings
    @Input() activeFields: string[];
    @Input() requiredFields: string[];
    @Input() bulkFillAnimalCommentFieldConfig: { field: string, action: string, value: string };
    @Output() onAnimalCommentsUpdate: EventEmitter<void> = new EventEmitter<void>();

    // date controls
    @ViewChildren('dateControl') dateControls: NgModel[];
    @ViewChildren('characteristicInput') characteristicInput: CharacteristicInputComponent[];
    // bulk edit input templates
    @ViewChild('idTmpl') idTmpl: TemplateRef<any>;
    @ViewChild('nameEditMenuTmpl') nameEditMenuTmpl: TemplateRef<any>;
    @ViewChild('nameTmpl') nameTmpl: TemplateRef<any>;
    @ViewChild('housingTmpl') housingTmpl: TemplateRef<any>;
    @ViewChild('lineTmpl') lineTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl') statusTmpl: TemplateRef<any>;
    @ViewChild('heldForTmpl') heldForTmpl: TemplateRef<any>;
    @ViewChild('breedingStatusTmpl') breedingStatusTmpl: TemplateRef<any>;
    @ViewChild('animalClassificationTmpl') animalClassificationTmpl: TemplateRef<any>;
    @ViewChild('ownerTmpl') ownerTmpl: TemplateRef<any>;
    @ViewChild('animalUseTmpl') animalUseTmpl: TemplateRef<any>;
    @ViewChild('originTmpl') originTmpl: TemplateRef<any>;
    @ViewChild('sexTmpl') sexTmpl: TemplateRef<any>;
    @ViewChild('generationTmpl') generationTmpl: TemplateRef<any>;
    @ViewChild('dietTmpl') dietTmpl: TemplateRef<any>;
    @ViewChild('shipmentIdTmpl') shipmentIdTmpl: TemplateRef<any>;
    @ViewChild('vendorIdTmpl') vendorIdTmpl: TemplateRef<any>;
    @ViewChild('orderIdTmpl') orderIdTmpl: TemplateRef<any>;
    @ViewChild('cITESNumberTmpl') cITESNumberTmpl: TemplateRef<any>;
    @ViewChild('dateBornTmpl') dateBornTmpl: TemplateRef<any>;
    @ViewChild('dateExitTmpl') dateExitTmpl: TemplateRef<any>;
    @ViewChild('exitReasonTmpl') exitReasonTmpl: TemplateRef<any>;
    @ViewChild('markerTypeTmpl') markerTypeTmpl: TemplateRef<any>;
    @ViewChild('markerTmpl') markerTmpl: TemplateRef<any>;
    @ViewChild('microchipIdTmpl') microchipIdTmpl: TemplateRef<any>;
    @ViewChild('externalIdTmpl') externalIdTmpl: TemplateRef<any>;
    @ViewChild('alternatePhysicalIDTmpl') alternatePhysicalIDTmpl: TemplateRef<any>;
    @ViewChild('commentsTmpl') commentsTmpl: TemplateRef<any>;
    @ViewChild('commentStatusTmpl') commentStatusTmpl: TemplateRef<any>;
    @ViewChild('jobTmpl') jobTmpl: TemplateRef<any>;
    @ViewChild('characteristicTmpl') characteristicTmpl: TemplateRef<any>;
    @ViewChild('iacucProtocolTmpl') iacucProtocolTmpl: TemplateRef<any>;
    @ViewChild('permitNumberTmpl') permitNumberTmpl: TemplateRef<any>;
    @ViewChild('permitOwnerTmpl') permitOwnerTmpl: TemplateRef<any>;
    @ViewChild('dateOriginTmpl') dateOriginTmpl: TemplateRef<any>;
    @ViewChild('genotypeTmpl') genotypeTmpl: TemplateRef<any>;
    @ViewChild('ageWeeksTmpl') ageWeeksTmpl: TemplateRef<any>;
    @ViewChild("dynamicCharacteristicTmpl") dynamicCharacteristicTmpl: TemplateRef<any>;
    @ViewChild("refreshCharacteristicsButtonTmpl") refreshCharacteristicsButtonTmpl: TemplateRef<any>;

    // shared logic for animal facet
    animalLogic: AnimalLogic;

    // vocabs
    sexes: any[];
    animalStatuses: any[];
    animalStatusDefaultKey: string;
    animalCommentStatuses: any[];
    systemGeneratedAnimalCommentStatus: Promise<any>;
    breedingStatuses: any[];
    breedingStatusDefaultKey: string;
    animalClassifications: any[];
    animalClassificationDefaultKey: string;
    animalUses: any[];
    animalUseDefaultKey: string;
    materialOrigins: any[];
    originDefaultKey: string;
    generations: any[];
    generationsDefaultKey: string;
    diets: any[];
    dietDefaultKey: string;
    exitReasons: any[];
    iacucProtocols: any[] = [];
    iacucProtocolDefaultKey: string;
    physicalMarkerTypes: any[] = [];
    physicalMarkerTypeDefaultKey: string;
    genotypeAssays: any[] = null;
    genotypeSymbols: any[] = null;
    resources: Resource[];

    microchipIds: {
        [key: number]: UniqueFieldData
    };
    alternatePhysicalIds: {
        [key: number]: UniqueFieldData
    };

    // This is used to keep track of the comment status key for bulk fill separately
    // because using the modelPath does not work well for accessing properties of a
    // dynamically indexed array on the dummy object used to hold bulk-filled data
    bulkFillAnimalCommentStatusKey: number;
    bulkFillAnimalComment: string;

    animalNamingActive = false;
    housingNamingActive = false;

    secondGT = false;

    readonly COMPONENT_LOG_TAG = 'animal-bulk-edit';
    readonly MULTI_PASTE_INPUT_LIMIT = 600;

    bulkOptions: BulkEditOptions;
    BulkEditSection = BulkEditSection;

    subscription: any;

    isGLP: boolean;
    isDotmatics: boolean;

    taxonCharacteristicMap: any = {};

    taxonCharacteristicsInListView: TaxonCharacteristic[] = [];

    isRefreshNeeded: boolean;

    taxonCharacteristicsLoading = true;

    public permitOwner: string | null;

    constructor(
        private animalService: AnimalService,
        private animalVocabService: AnimalVocabService,
        private enumerationService: EnumerationService,
        private facetLoadingState: FacetLoadingStateService,
        private jobService: JobService,
        private lineService: LineService,
        private loggingService: LoggingService,
        private namingService: NamingService,
        private translateService: TranslationService,
        private featureFlagService: FeatureFlagService,
        private dataManager: DataManagerService,
        private vocabularyService: VocabularyService,
        private jobLogicService: JobLogicService,
        private translationService: TranslationService,
        private dataContextService: DataContextService,
        private authService: AuthService,
        private confirmService: ConfirmService,
        private settingService: SettingService,
        private characteristicService: CharacteristicService,
        private permitService: PermitService,
    ) {
        this.animalLogic = new AnimalLogic(
            animalService,
            enumerationService,
        );
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.getData();
        if (this.dataContextService && this.dataContextService.changesSaved$) {
            this.subscription = this.dataContextService.changesSaved$.subscribe(() => {
                this.mapIdsForUniqueFields();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.bulkFillAnimalCommentFieldConfig && !changes.bulkFillAnimalCommentFieldConfig.firstChange) {
            this.fillDownAnimalComments(this.bulkFillAnimalCommentFieldConfig);
        }
    }

    /**
     * Configuration with TemplateRefs can only be assigned
     *   after "ngAfterViewInit".
     * Otherwise they will be undefined.
     */
    async ngAfterViewInit() {
        // assign all the BulkAdd and BulkEdit configuration options
        this.bulkOptions = {
            itemTypeLabel: "Animal",
            itemTypeLabelPlural: "Animals",
            clearForm: false,
            maxNumberItemsToAdd: 600,
            saveButtonValidators: [() => {
                return this.isValidToSave();
            }],
            fields: [
                {
                    label: "ID",
                    modelPath: 'Material.Identifier',
                    template: this.idTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: 'Name',
                    labelTooltip: 'Enter multiple names or generate names using a name format.',
                    modelPath: 'AnimalNames',
                    template: this.nameTmpl,
                    extraMenuItemsTemplate: this.nameEditMenuTmpl,
                    onUpdateItem: (item, value) => {
                        if (item.useNameFormatInput) {
                            this.fillDownNameFormat(item);
                        } else {
                            this.fillDownName(item.AnimalNames);
                        }
                    }
                },
                {
                    label: 'Housing',
                    modelPath: 'Material.C_MaterialPool_key',
                    template: this.housingTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true,
                    inactive: this.activeFields && !this.activeFields.includes('Housing')
                },
                {
                    label: this.translateService.translate('Line'),
                    modelPath: 'Material.C_Line_key',
                    template: this.lineTmpl,
                    onUpdateItem: (item, value) => {
                        this.fillDownLine(item.Material.C_Line_key);
                    }
                },
                {
                    label: 'Status',
                    modelPath: 'C_AnimalStatus_key',
                    template: this.statusTmpl,
                    onUpdateItem: (item, value) => {
                        this.fillDownStatus(item.C_AnimalStatus_key);
                    }
                },
                {
                    label: 'Held For',
                    modelPath: 'HeldFor',
                    template: this.heldForTmpl,
                    inactive: !this.isGLP || (this.activeFields && !this.activeFields.includes('Held For')) 
                },
                {
                    label: 'Breeding Status',
                    modelPath: 'C_BreedingStatus_key',
                    template: this.breedingStatusTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Breeding Status')
                },
                {
                    label: 'Classification',
                    modelPath: 'C_AnimalClassification_key',
                    template: this.animalClassificationTmpl,
                    inactive: !this.isGLP || (this.activeFields && !this.activeFields.includes('Classification'))
                },
                {
                    label: 'Owner',
                    modelPath: 'Owner',
                    template: this.ownerTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Owner')
                },
                {
                    label: 'Use',
                    modelPath: 'C_AnimalUse_key',
                    template: this.animalUseTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Use')
                },
                {
                    label: 'IACUC Protocol',
                    modelPath: 'C_IACUCProtocol_key',
                    template: this.iacucProtocolTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('IACUC Protocol')
                },
                {
                    label: 'Permit',
                    modelPath: 'C_Permit_key',
                    template: this.permitNumberTmpl,
                    // to do: make inactive conditional after settings facet for Permits facet ready
                    inactive: false,
                },
                {
                    label: 'Permit Owner',
                    modelPath: 'Permit.Resource.ResourceName',
                    template: this.permitOwnerTmpl,
                    hideFromEditHeader: true,
                    // to do: make inactive conditional after settings facet for Permits facet ready
                    inactive: false,
                },
                {
                    label: 'Origin',
                    modelPath: 'Material.C_MaterialOrigin_key',
                    template: this.originTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Origin')
                },
                {
                    label: 'Sex',
                    modelPath: 'C_Sex_key',
                    template: this.sexTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Sex')
                },
                {
                    label: 'Generation',
                    modelPath: 'C_Generation_key',
                    template: this.generationTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Generation')
                },
                {
                    label: 'Diet',
                    modelPath: 'C_Diet_key',
                    template: this.dietTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Diet')
                },
                {
                    label: 'Shipment',
                    modelPath: 'ShipmentID',
                    template: this.shipmentIdTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Shipment')
                },
                {
                    label: 'Vendor ID',
                    modelPath: 'VendorID',
                    template: this.vendorIdTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Vendor ID')
                },
                {
                    label: 'Order ID',
                    modelPath: 'C_Order_key',
                    template: this.orderIdTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Order ID')
                },
                {
                    label: 'CITES Number',
                    modelPath: 'CITESNumber',
                    template: this.cITESNumberTmpl,
                    inactive: !this.isGLP || (this.activeFields && !this.activeFields.includes('CITES Number'))
                },
                {
                    label: 'Arrival Date',
                    modelPath: 'DateOrigin',
                    template: this.dateOriginTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Arrival Date'),
                    onItemInit: (item) => {
                        item.DateOrigin = new Date();
                    }
                },
                {
                    label: 'Birth Date',
                    modelPath: 'DateBorn',
                    template: this.dateBornTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Birth Date'),
                    onItemInit: (item: Partial<Animal>) => {
                        item.DateBorn = new Date();
                    }
                },
                {
                    label: 'Age (weeks)',
                    modelPath: 'AgeWeeks',
                    template: this.ageWeeksTmpl,
                    hideFromEditHeader: true,
                    inactive: this.activeFields && !this.activeFields.includes('Age (weeks)')
                },
                {
                    label: 'Death/Exit Date',
                    modelPath: 'DateExit',
                    template: this.dateExitTmpl,
                    hideFromAddScreen: true,
                    inactive: this.activeFields && !this.activeFields.includes('Death/Exit Date')
                },
                {
                    label: 'Death/Exit Reason',
                    modelPath: 'C_ExitReason_key',
                    template: this.exitReasonTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Death/Exit Reason'),
                    hideFromAddScreen: true
                },
                {
                    label: 'Marker Type',
                    modelPath: 'C_PhysicalMarkerType_key',
                    template: this.markerTypeTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Marker Type'),
                    onUpdateItem: (item, value) => {
                        this.fillDownMarkerType(item.C_PhysicalMarkerType_key);
                    }
                },
                {
                    label: 'Marker',
                    modelPath: 'PhysicalMarker',
                    template: this.markerTmpl,
                    hideFromAddScreen: true,
                    inactive: this.activeFields && !this.activeFields.includes('Marker'),
                    onUpdateItem: (item, value) => {
                        this.fillDownMarker(item.PhysicalMarker);
                    }
                },
                {
                    label: 'Microchip ID',
                    modelPath: 'Material.MicrochipIdentifier',
                    template: this.microchipIdTmpl,
                    hideFromAddScreen: true,
                    inactive: this.activeFields && !this.activeFields.includes('Microchip ID'),
                    onUpdateItem: (item, value) => {
                        this.fillDownMicrochipIdentifier(item.MicrochipIdentifiers);
                    }
                },
                {
                    label: 'External ID',
                    modelPath: 'Material.ExternalIdentifier',
                    template: this.externalIdTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: this.isDotmatics,
                    inactive: this.activeFields && !this.activeFields.includes('External ID'),
                    onUpdateItem: (item, value) => {
                        this.fillDownExternalIdentifier(item.ExternalIdentifiers);
                    }
                },
                {
                    label: 'Alternate Physical ID',
                    modelPath: 'AlternatePhysicalID',
                    template: this.alternatePhysicalIDTmpl,
                    hideFromAddScreen: true,
                    inactive: !this.isGLP || (this.activeFields && !this.activeFields.includes('Alternate Physical ID')),
                    onUpdateItem: (item, value) => {
                        this.fillDownAlternatePhysicalID(item.AlternatePhysicalIDs);
                    },
                },
                {
                    label: 'Comment',
                    modelPath: 'AnimalComment.Comment',
                    template: this.commentsTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Comments'),
                    hideFromAddScreen: true,
                },
                {
                    label: 'Comment Status',
                    modelPath: 'AnimalComment.AnimalCommentStatus',
                    template: this.commentStatusTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Comments'),
                    hideFromAddScreen: true,
                    onUpdateItem: (item, value) => {
                        // Can not use item object here due to serialized model path not playing
                        // well with dynamic indexing, so we opt for an instance variable used
                        // as the vocab select's model only when it is in the Edit Header section
                        this.fillDownAnimalCommentsStatus(this.bulkFillAnimalCommentStatusKey);
                    }
                },
                {
                    label: this.translateService.translate('Job'),
                    modelPath: 'Material.JobMaterial',
                    template: this.jobTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Jobs'),
                    onUpdateItem: (item, value) => {
                        this.fillDownJob(item.JobKey);
                    }
                },
                {
                    label: 'Genotype',
                    modelPath: 'Genotype',
                    template: this.genotypeTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Genotypes'),
                    hideFromEditHeader: true,
                    onItemInit: (item) => {
                        item.Genotype = [];
                        item.Genotype[0] = {
                            cv_GenotypeAssay: {},
                            cv_GenotypeSymbol: {}
                        };
                        item.Genotype[1] = {
                            cv_GenotypeAssay: {},
                            cv_GenotypeSymbol: {}
                        };
                    }
                },
            ]
        };
        await this.setCharacteristicColumns();
        this.configNaming();
    }

    initialize() {
        this.initFeatureFlags();

         // Copy the input so we don't touch the grid data
        this.animals = this.animals.slice();

        for (const animal of this.animals) {
            if (notEmpty(animal.AnimalComment)) {
                for (const animalComment of animal.AnimalComment) {
                    animalComment.trackId = Math.random();
                }
            }
        }

        this.mapIdsForUniqueFields();

        for (const animal of this.animals) {
            animal.selectedForDelete = false;
        }

        this.systemGeneratedAnimalCommentStatus = this.vocabularyService.getSystemGeneratedCV('cv_AnimalCommentStatuses');
    }

    mapIdsForUniqueFields() {
        this.microchipIds = this.animals.reduce((prev: any, curr: any) => {
            prev[curr.C_Material_key] = {
                error: false,
                originalValue: curr.Material.MicrochipIdentifier
            };
            return prev;
        }, {});
        this.alternatePhysicalIds = this.animals.reduce((prev: any, curr: any) => {
            prev[curr.C_Material_key] = {
                error: false,
                originalValue: curr.AlternatePhysicalID
            };
            return prev;
        }, {});
    }

    initFeatureFlags() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");

        this.isDotmatics = this.featureFlagService.isFlagOn(IS_DOTMATICS);
    }



    getData() {
        this.facetLoadingState.changeLoadingState(true);

        return this.isNamingActive().then(() => {
            return this.getAnimalsDetails();
        }).then(() => {
            return this.getIsRefreshNeeded().then((needsRefresh) => {
                this.isRefreshNeeded = needsRefresh;
            });
        }).then(() => {
            return this.getCVs();
        }).then(() => {
            this.facetLoadingState.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        });
    }

    private isNamingActive(): Promise<any> {
        return this.namingService.isAnimalNamingActive()
            .then((active: boolean) => {
                this.animalNamingActive = active;
            }).then(() => {
                return this.namingService.isHousingNamingActive();
            }).then((active: boolean) => {
                this.housingNamingActive = active;
            }).then(() => {
                this.configNaming();
            });
    }

    private configNaming() {
        const nameField = this.getField('Name');
        if (nameField) {
            nameField.subLabel = this.animalNamingActive ? '(assigned automatically)' : '';
            nameField.hideFromAddScreen = this.animalNamingActive;
        }

        const housingField = this.getField('Housing');
        if (housingField) {
            housingField.subLabel = this.housingNamingActive ? '(assigned automatically)' : '';
        }
    }

    private getField(label: string): BulkEditField {
        if (!this.bulkOptions ||
            !this.bulkOptions.fields
        ) {
            return null;
        }
        return this.bulkOptions.fields.find((field) => {
            return field.label === label;
        });
    }

    getAnimalsDetails(): Promise<any[]> {
        if (notEmpty(this.animals)) {
            const queryDef = {
                page: 0,
                size: this.animals.length,
                filter: {}
            };

            if (this.animals.length > 50) {
                queryDef.filter = {
                    mKeys: this.animals.map((animal) => {
                        return animal.C_Material_key;
                    })
                };
            } else {
                queryDef.filter = {
                    materialKeys: this.animals.map((animal) => {
                        return animal.C_Material_key;
                    })
                };
            }

            const p1 = this.animalService.getAnimals(queryDef).then((result) => {
                return result.results;
            });

            const p2 = p1.then((animals) => {
                return this.animalService.ensureBulkEditAssociatedDataLoaded(animals);
            });

            return Promise.all([p1, p2]).then(() => {
                return this.animals;
            });
        }

        return Promise.resolve(this.animals);
    }

    getCVs(): Promise<any> {
        return Promise.all([
            this.animalVocabService.sexes$.toPromise().then((sexes) => {
                this.sexes = sexes;
            }),
            this.animalVocabService.animalStatuses$.toPromise().then(
                (animalStatuses) => {
                    this.animalStatuses = animalStatuses;
                    if (this.loadDefaults) {
                        this.animalStatusDefaultValue();
                    }
                }),
            this.animalVocabService.animalCommentStatuses$.toPromise().then(
                (animalCommentStatuses) => {
                    this.animalCommentStatuses = animalCommentStatuses;
                    this.animalCommentStatusDefaultValue();
                }),
            this.animalVocabService.breedingStatuses$.toPromise().then(
                (breedingStatuses) => {
                    this.breedingStatuses = breedingStatuses;
                    if (this.loadDefaults) {
                        this.breedingStatusDefaultValue();
                    }
                }),
            this.animalVocabService.animalClassifications$.toPromise().then(
                (animalClassifications) => {
                    this.animalClassifications = animalClassifications;
                    if (this.loadDefaults) {
                        this.animalClassificationDefaultValue();
                    }
                }),
            this.animalVocabService.animalUses$.toPromise().then((animalUses) => {
                this.animalUses = animalUses;
                if (this.loadDefaults) {
                    this.useDefaultValue();
                }
            }),
            this.animalVocabService.materialOrigins$.toPromise().then((materialOrigins) => {
                this.materialOrigins = materialOrigins;
                if (this.loadDefaults) {
                    this.originDefaultValue();
                }
            }),
            this.animalVocabService.generations$.toPromise().then((generations) => {
                this.generations = generations;
                if (this.loadDefaults) {
                    this.generationsDefaultValue();
                }
            }),
            this.animalVocabService.diets$.toPromise().then((diets) => {
                this.diets = diets;
                if (this.loadDefaults) {
                    this.dietDefaultValue();
                }
            }),
            this.animalVocabService.exitReasons$.toPromise().then((exitReasons) => {
                this.exitReasons = exitReasons;
            }),
            this.animalVocabService.iacucProtocols$.toPromise().then((iacucProtocols) => {
                this.iacucProtocols = iacucProtocols;
                if (this.loadDefaults) {
                    this.iacucDefaultvalue();
                }
            }),
            this.animalVocabService.physicalMarkerTypes$.toPromise().then((physicalMarkerTypes) => {
                this.physicalMarkerTypes = physicalMarkerTypes;
                if (this.loadDefaults) {
                    this.physicalMarkerTypeDefaultvalue();
                }
            }),
            this.animalVocabService.genotypeAssays$.toPromise().then((genotypeAssays) => {
                this.genotypeAssays = genotypeAssays;
            }),
            this.animalVocabService.genotypeSymbols$.toPromise().then((genotypeSymbols) => {
                this.genotypeSymbols = genotypeSymbols;
            }),
            this.animalVocabService.resources$.toPromise().then((resources) => {
                this.resources = resources;
            }),
        ]);
    }

    async setCharacteristicColumns(): Promise<void> {
        this.bulkOptions.fields.push({
            label: 'Characteristics',
            modelPath: 'TaxonCharacteristicInstance',
            template: this.characteristicTmpl,
            hideFromEditHeader: true,
            customSideButtonTemplate: this.refreshCharacteristicsButtonTmpl,
            onItemInit: (item) => {
                item.TaxonCharacteristicInstance = [];
            }
        });

        this.taxonCharacteristicsInListView = await this.settingService.getTaxonCharacteristicsShownInListView();

        const characteristicFields: BulkEditField[] = [];
        for (const characteristic of this.taxonCharacteristicsInListView) {
            // since the characteristic input component only takes characteristic instances, create a dummy characteristic instance to accomodate.
            const fakeTaxonCharacteristic: TaxonCharacteristicInstance = {
                C_TaxonCharacteristic_key: characteristic.C_TaxonCharacteristic_key,
                CharacteristicName: characteristic.CharacteristicName,
                CharacteristicValue: "",
                TaxonCharacteristic: characteristic
            } as unknown as TaxonCharacteristicInstance;
            this.taxonCharacteristicMap[characteristic.CharacteristicName] = fakeTaxonCharacteristic;

            characteristicFields.push({
                label: characteristic.CharacteristicName,
                hideFromAddScreen: true,
                modelPath: "TaxonCharacteristicInstance[" + characteristic.ListViewOrder + "]",
                template: this.dynamicCharacteristicTmpl,
                onUpdateItem: () => this.fillDownCharacteristicInstance(characteristic.CharacteristicName)
            });
        }
        const fieldsWithCharacteristics = this.bulkOptions.fields.concat(characteristicFields);
        const bulkEditOptionsCopy = Object.assign({}, this.bulkOptions);
        bulkEditOptionsCopy.fields = this.bulkOptions.fields = fieldsWithCharacteristics;
        this.bulkOptions = bulkEditOptionsCopy;
        this.taxonCharacteristicsLoading = false;
    }

    /**
     * If any of the animals selected in the bulk edit are missing characteristics, return true.
     * @returns
     */
    async getIsRefreshNeeded(): Promise<boolean> {
        // check for all possible characteristics according to the available taxons.
        // get a unique list of all animal taxons in the selected bunch.
        const animalTaxons = [... new Set(this.animals.map((a: Animal) => a.Material.Line?.C_Taxon_key))];
        // retrieve all characteristics that belong to those taxons
        const allCharacteristics = (await this.characteristicService.getTaxonCharacteristics({ IsActive: true, C_Taxon_keys: animalTaxons }, true)).results;
        for (const characteristic of allCharacteristics) {
            // get taxon keys from the characteristic.
            const characteristicTaxons = characteristic.TaxonCharacteristicTaxon.map((tct: TaxonCharacteristicTaxon) => tct.C_Taxon_key);
            for (const animal of this.animals) {
                // check the specific animal's taxon key
                const animalTaxon = animal.Material.Line?.C_Taxon_key;
                if (characteristicTaxons.includes(animalTaxon)) {
                    // attempt to find the current characteristic within the animal's instance list, if the animal is allowed to have that characteristic.
                    const foundCharacteristic = animal.TaxonCharacteristicInstance
                        .filter((tci: TaxonCharacteristicInstance) => tci.TaxonCharacteristic.IsActive)
                        .find((tci: TaxonCharacteristicInstance) => tci.C_TaxonCharacteristic_key === characteristic.C_TaxonCharacteristic_key);
                    if (!foundCharacteristic) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    onRefreshCharacteristics(): void {
        this.facetLoadingState.changeLoadingState(true);
        this.characteristicService.refreshTaxonCharacteristics(this.animals).then(() => {
            this.isRefreshNeeded = false;

            this.loggingService.logWarning("Characteristics Refreshed. Please reload Climb to see the results", null, this.COMPONENT_LOG_TAG, true);

            this.facetLoadingState.changeLoadingState(false);
        }).catch((error: Error) => {
            console.error(error.message);
            this.facetLoadingState.changeLoadingState(false);
        });
    }

    animalStatusDefaultValue() {
        for (const animalStatus of this.animalStatuses) {
            if (animalStatus.IsDefault) {
                this.animalStatusDefaultKey = animalStatus.C_AnimalStatus_key;
            }
        }
    }

    animalCommentStatusDefaultValue() {
        for (const animalCommentStatus of this.animalCommentStatuses) {
            if (animalCommentStatus.IsDefault) {
                this.animalCommentStatusDefaultValue = animalCommentStatus.C_AnimalCommentStatus_key;
            }
        }
    }

    breedingStatusDefaultValue() {
        for (const breedingStatus of this.breedingStatuses) {
            if (breedingStatus.IsDefault) {
                this.breedingStatusDefaultKey = breedingStatus.C_BreedingStatus_key;
            }
        }
    }

    animalClassificationDefaultValue() {
        for (const animalClassification of this.animalClassifications) {
            if (animalClassification.IsDefault) {
                this.animalClassificationDefaultKey = animalClassification.C_AnimalClassification_key;
            }
        }
    }

    useDefaultValue() {
        for (const animalUse of this.animalUses) {
            if (animalUse.IsDefault) {
                this.animalUseDefaultKey = animalUse.C_AnimalUse_key;
            }
        }
    }

    iacucDefaultvalue() {
        for (const iacucProtocol of this.iacucProtocols) {
            if (iacucProtocol.IsDefault) {
                this.iacucProtocolDefaultKey = iacucProtocol.C_IACUCProtocol_key;
            }
        }
    }

    originDefaultValue() {
        for (const materialOrigin of this.materialOrigins) {
            if (materialOrigin.IsDefault) {
                this.originDefaultKey = materialOrigin.C_MaterialOrigin_key;
            }
        }
    }

    generationsDefaultValue() {
        for (const generation of this.generations) {
            if (generation.IsDefault) {
                this.generationsDefaultKey = generation.C_Generation_key;
            }
        }
    }

    dietDefaultValue() {
        for (const diet of this.diets) {
            if (diet.IsDefault) {
                this.dietDefaultKey = diet.C_Diet_key;
            }
        }
    }

    physicalMarkerTypeDefaultvalue() {
        for (const physicalMarkerType of this.physicalMarkerTypes) {
            if (physicalMarkerType.IsDefault) {
                this.physicalMarkerTypeDefaultKey = physicalMarkerType.C_PhysicalMarkerType_key;
            }
        }

    }


    statusChanged(animal: any) {
        this.animalService.statusChangePostProcess(animal);
    }

    lineChanged(animal: any, section: BulkEditSection) {
        if (!animal.Material || !animal.Material.C_Line_key) {
            return;
        }

        const expands = [
            'cv_Taxon'
        ];
        if (animal.Material.C_Line_key) {
            this.lineService.getLine(animal.Material.C_Line_key, expands).then((line) => {
                if (animal.Material.C_Line_key && line) {
                    const oldTaxon = animal.Material.cv_Taxon;
                    animal.Material.cv_Taxon = line.cv_Taxon;
                    if (oldTaxon !== line.cv_Taxon) {
                        this.taxonChanged(animal, section);
                    }
                    this.namingService.isAnimalNamingActive().then((isNamingActive: boolean) => {
                        if (isNamingActive) {
                            this.updateAnimalName(animal);
                        }
                    });
                }
            });
        }
    }

    updateAnimalName(animal: any) {
        // Apply new name only if is an update
        if (animal.AnimalName) {
            this.animalService.getAnimalPrefixField().then((animalPrefixField: string) => {
                if (animalPrefixField.toLowerCase() === 'line') {
                    // Automatically regenerate AnimalName
                    this.animalService.autoGenerateAnimalName(animal).then((newName: string) => {
                        if (newName !== animal.AnimalName) {
                            animal.AnimalName = newName;
                            // Alert user of automatic change
                            this.loggingService.logWarning(
                                `The Name field has been automatically changed due to changing the ${this.translationService.translate(animalPrefixField)} field.`,
                                null, this.COMPONENT_LOG_TAG, true);
                        }
                    });
                }
            });
        }
    }

    taxonChanged(animal: any, section: BulkEditSection) {
        switch (section) {
            case BulkEditSection.InputCell:
                this.animalLogic.taxonChanged(animal);
                break;
            case BulkEditSection.AddScreen:
                this.createMockTaxonCharacteristics(animal);
                break;
        }
    }

    microchipIdChanged(material: any) {
        if ((material.MicrochipIdentifier || '').length === 0) {
            if (this.microchipIds[material.C_Material_key]) {
                this.microchipIds[material.C_Material_key].error = false;
            } else {
                this.microchipIds[material.C_Material_key] = {
                    error: false,
                    originalValue: material.MicrochipIdentifier
                };
            }
            return;
        }

        const uniqueInData = this.isItemUniqueInArray(material.MicrochipIdentifier, this.animals.map((a: any) => a.Material.MicrochipIdentifier));
        if (!uniqueInData) {
            if (this.microchipIds[material.C_Material_key]) {
                this.microchipIds[material.C_Material_key].error = true;
            } else {
                this.microchipIds[material.C_Material_key] = {
                    error: true,
                    originalValue: material.MicrochipIdentifier
                };
            }
            return;
        }

        this.dataManager.isPropertyValueUnique('Materials', 'MicrochipIdentifier', material.MicrochipIdentifier, material.C_Material_key, "C_Material_key").then((unique: boolean) => {
            const data = this.microchipIds[material.C_Material_key];
            if (data) {
                data.error = !unique && data.originalValue !== material.MicrochipIdentifier;
            } else {
                this.microchipIds[material.C_Material_key] = {
                    error: !unique,
                    originalValue: material.MicrochipIdentifier
                };
            }
        });
    }

    public async onPermitSelect(permitKey: number, section: BulkEditSection): Promise<void> {
        const permit = await this.permitService.getPermit(permitKey, ['Resource']);
        if (section === BulkEditSection.AddScreen) {
            this.permitOwner = permit?.Resource.ResourceName || null;
        }
    }

    alternatePhysicalIdChanged(animal: any) {
        if ((animal.AlternatePhysicalID || '').length === 0) {
            if (this.alternatePhysicalIds[animal.C_Material_key]) {
                this.alternatePhysicalIds[animal.C_Material_key].error = false;
            } else {
                this.alternatePhysicalIds[animal.C_Material_key] = {
                    error: false,
                    originalValue: animal.AlternatePhysicalID
                };
            }
            return;
        }

        const uniqueInData = this.isItemUniqueInArray(animal.AlternatePhysicalID, this.animals.map((a: any) => a.AlternatePhysicalID));
        if (!uniqueInData) {
            if (this.alternatePhysicalIds[animal.C_Material_key]) {
                this.alternatePhysicalIds[animal.C_Material_key].error = true;
            } else {
                this.alternatePhysicalIds[animal.C_Material_key] = {
                    error: true,
                    originalValue: animal.AlternatePhysicalID
                };
            }
            return;
        }

        this.dataManager.isPropertyValueUnique('Animals', 'AlternatePhysicalID', animal.AlternatePhysicalID, animal.C_Material_key, "C_Material_key").then((unique: boolean) => {
            const data = this.alternatePhysicalIds[animal.C_Material_key];
            if (data) {
                data.error = !unique && data.originalValue !== animal.AlternatePhysicalID;
            } else {
                this.alternatePhysicalIds[animal.C_Material_key] = {
                    error: !unique,
                    originalValue: animal.AlternatePhysicalID
                };
            }
        });
    }

    /**
     * Create mock TaxonCharacteristics for the bulk Add or Edit screen
     */
    createMockTaxonCharacteristics(animal: any) {
        // clear old characteristics
        animal.TaxonCharacteristicInstance = [];

        const taxonKey: number = getSafeProp(
            animal, 'Material.cv_Taxon.C_Taxon_key'
        );
        this.animalService.getActiveCharacteristics(taxonKey).then((characteristics) => {
            const newCharacteristics: any[] = [];
            for (const characteristic of characteristics) {
                const mockCharacteristic = {
                    C_TaxonCharacteristic_key: characteristic.C_TaxonCharacteristic_key,
                    CharacteristicValue: '',
                    CharacteristicName: characteristic.CharacteristicName,
                    TaxonCharacteristic: characteristic
                };
                newCharacteristics.push(mockCharacteristic);
            }
            animal.TaxonCharacteristicInstance = newCharacteristics;
        });
    }

    fillDownNameFormat(settings: any) {
        const prefix = settings.prefix || "";
        let counter = settings.counter;
        const suffix = settings.suffix || "";
        if (counter === null || counter === undefined) {
            const message = "Counter must be set to assign name values";
            this.loggingService.logWarning(message, null, this.COMPONENT_LOG_TAG, true);
            return;
        }

        for (const animal of this.animals) {
            animal.AnimalName = prefix + counter + suffix;
            counter += 1;
        }
    }

    fillDownName(names: string[]) {
        if (names) {
            for (let i = 0; i < names.length; i++) {
                // only update the name if it's an existing animal or auto naming is disabled
                if (this.animals[i].C_Material_key > 0 || !this.animalNamingActive) {
                    this.animals[i].AnimalName = names[i];
                }
            }
        }
    }

    fillDownLine(lineKey: number) {
        if (lineKey) {
            for (const row of this.animals) {
                row.Material.C_Line_key = lineKey;
            }

            this.lineService.getLine(lineKey).then((line) => {
                for (const row of this.animals) {
                    const prevTaxon = row.Material.cv_Taxon;
                    row.Material.cv_Taxon = line.cv_Taxon;
                    if (prevTaxon !== row.Material.cv_Taxon) {
                        this.animalLogic.taxonChanged(row);
                    }
                    this.updateAnimalName(row);
                }
            });
        }
    }

    fillDownStatus(animalStatusKey: number) {
        if (animalStatusKey) {
            this.animalService.statusBulkChangePostProcess(this.animals, animalStatusKey);
        }
    }

    createAnimalComment(comment: string, animal: Animal, commentStatus?: number) {
        const animalComment = {
            C_Material_key: animal.C_Material_key,
            Animal: animal,
            Comment: comment,
            C_AnimalCommentStatus_key: commentStatus || this.animalCommentStatusDefaultValue,
            CreatedBy: this.authService.getCurrentUserName(),
            DateCreated: new Date(),
            ModifiedBy: this.authService.getCurrentUserName(),
            DateModified: new Date()
        };
        this.dataManager.createEntity("AnimalComment", animalComment);
        const index = animal.AnimalComment.length > 0 ? animal.AnimalComment.length - 1 : 0;
        animal.AnimalComment[index].trackId = Math.random();
    }

    animalCommentChanged(event: InputEvent, animal: Animal) {
        let { value } = event.target as HTMLTextAreaElement;
        if (!animal.AnimalComment.length && value) {
            this.createAnimalComment(value, animal);
        } else if (!value && animal.AnimalComment.length) {
            this.dataManager.deleteEntity(animal.AnimalComment[animal.AnimalComment.length - 1] as Entity<AnimalComment>);
            value = '';
        } else {
            animal.AnimalComment[animal.AnimalComment.length - 1].Comment = value;
        }
        this.onAnimalCommentsUpdate.emit();
    }

    fillDownAnimalComments(config: { field: string, action: string, value: string }): void {
        if (config.action === AnimalCommentsBulkActions.CreateNew) {
            for (const row of this.animals) {
                this.createAnimalComment(config.value, row);
            }
        } else if (config.action === AnimalCommentsBulkActions.UpdateExisting) {
            const title = 'Bulk Update Most Recent Animal Comments';
            const message = 'This action will overwrite existing comments. Do you wish to proceed?';
            const confirmOptions: ConfirmOptions = {
                title,
                message,
                yesButtonText: 'Continue',
                noButtonText: 'Cancel',
            };
            this.confirmService.confirm(confirmOptions).then(() => {
                for (const row of this.animals) {
                    const length = row.AnimalComment.length;
                    if (length && row.AnimalComment[length - 1].entityAspect.entityState.name !== 'Added') {
                        const item = row.AnimalComment[length - 1];
                        item.Comment = config.value;
                    }
                }
            });
        }
    }

    onChangeAnimalCommentStatus(animal: Animal, status: string) {
        if (!animal.AnimalComment.length) {
            this.createAnimalComment('', animal, +status);
        }
    }

    fillDownAnimalCommentsStatus(animalCommentStatusKey: number) {
        if (animalCommentStatusKey) {
            for (const row of this.animals) {
                const comment = row.AnimalComment[row.AnimalComment.length - 1];
                if (comment) {
                    comment.C_AnimalCommentStatus_key = animalCommentStatusKey;
                    this.onModelChange(comment);
                } else {
                    this.createAnimalComment('', row, animalCommentStatusKey);
                }
            }
        }
    }

    fillDownMarkerType(physicalMarkerTypeKey: number) {
        if (physicalMarkerTypeKey) {
            for (const row of this.animals) {
                row.C_PhysicalMarkerType_key = physicalMarkerTypeKey;
                this.statusChanged(row);
            }
        }
    }

    fillDownMarker(physicalMarker: string) {
        if (physicalMarker) {
            for (const row of this.animals) {
                if (!row.cv_PhysicalMarkerType || !row.cv_PhysicalMarkerType.Vendor) {
                    row.PhysicalMarker = physicalMarker;
                }
            }
        }
    }

    fillDownMicrochipIdentifier(ids: string[]) {
        if (ids) {
            for (let i = 0; i < ids.length; i++) {
                const material = this.animals[i].Material;
                material.MicrochipIdentifier = ids[i];
                this.microchipIdChanged(material);
            }
        }
    }

    fillDownAlternatePhysicalID(ids: string[]) {
        if (ids) {
            for (let i = 0; i < ids.length; i++) {
                const animal = this.animals[i];
                animal.AlternatePhysicalID = ids[i];
                this.alternatePhysicalIdChanged(animal);
            }
        }
    }

    fillDownExternalIdentifier(ids: string[]) {
        if (ids) {
            for (let i = 0; i < ids.length; i++) {
                this.animals[i].Material.ExternalIdentifier = ids[i];
            }
        }
    }

    fillDownJob(jobKey: number) {
        if (jobKey) {
            // load job into breeze cache
            this.jobService.getJob(jobKey).then((job: any) => {
                this.facetLoadingState.changeLoadingState(true);
                if (this.isGLP) {
                    const animalsWithoutJobs: any[] = [];
                    for (const animal of this.animals) {
                        if (animal.Material && animal.Material.JobMaterial) {
                            const jobMaterials: any[] = animal.Material.JobMaterial;
                            // check if the animal has any active jobs
                            const hasActiveJob = jobMaterials.some((jm: any) => !jm.DateOut);
                            if (!hasActiveJob) {
                                animalsWithoutJobs.push(animal);
                            }
                        } else {
                            animalsWithoutJobs.push(animal);
                        }
                    }
                    this.doAddAnimalsToJob(animalsWithoutJobs, job);
                    this.facetLoadingState.changeLoadingState(false);
                } else {
                    this.doAddAnimalsToJob(this.animals, job);
                }
            });
        }
    }

    fillDownCharacteristicInstance(label: string) {
        const characteristicValue = this.taxonCharacteristicMap[label].CharacteristicValue;
        for (const animal of this.animals) {
            if (animal.TaxonCharacteristicInstance && animal.TaxonCharacteristicInstance.length > 0) {
                const targetInstance = animal.TaxonCharacteristicInstance.find((tci: TaxonCharacteristicInstance) => tci.CharacteristicName === label);
                if (targetInstance) {
                    targetInstance.CharacteristicValue = characteristicValue;
                }
            }
        }
    }

    /**
     * Actually add the Animals to the Job
     */
    private doAddAnimalsToJob(animals: any[], job: any) {
        for (const animal of animals) {
            this.jobService.createJobMaterial({
                C_Job_key: job.C_Job_key,
                C_Material_key: animal.C_Material_key,
                DateIn: new Date()
            });

            animal.isSelected = false;
        }
    }

    isValidToSave(): boolean {
        if (this.isGLP) {
            return !(this.hasMicrochipIdErrors() || this.hasAlternatePhysicalIdErrors());
        }

        return true;
    }

    hasMicrochipIdErrors(): boolean {
        return this.hasUniqueErrors(this.microchipIds);
    }

    hasAlternatePhysicalIdErrors(): boolean {
        return this.hasUniqueErrors(this.alternatePhysicalIds);
    }

    private hasUniqueErrors(field: Record<number, UniqueFieldData>): boolean {
        return Object.values(field).some(({ error }) => error);
    }

    // <select> formatters
    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }
    animalStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }
    animalCommentStatusKeyFormatter = (value: any) => {
        return value.C_AnimalCommentStatus_key;
    }
    animalCommentStatusFormatter = (value: any) => {
        return value.AnimalCommentStatus;
    }
    breedingStatusKeyFormatter = (value: any) => {
        return value.C_BreedingStatus_key;
    }
    breedingStatusFormatter = (value: any) => {
        return value.BreedingStatus;
    }
    animalClassificationKeyFormatter = (value: any) => {
        return value.C_AnimalClassification_key;
    }
    animalClassificationFormatter = (value: any) => {
        return value.AnimalClassification;
    }
    animalUseKeyFormatter = (value: any) => {
        return value.C_AnimalUse_key;
    }
    animalUseFormatter = (value: any) => {
        return value.AnimalUse;
    }
    materialOriginKeyFormatter = (value: any) => {
        return value.C_MaterialOrigin_key;
    }
    materialOriginFormatter = (value: any) => {
        return value.MaterialOrigin;
    }
    sexKeyFormatter = (value: any) => {
        return value.C_Sex_key;
    }
    sexFormatter = (value: any) => {
        return value.Sex;
    }
    generationKeyFormatter = (value: any) => {
        return value.C_Generation_key;
    }
    generationFormatter = (value: any) => {
        return value.Generation;
    }
    dietKeyFormatter = (value: any) => {
        return value.C_Diet_key;
    }
    dietFormatter = (value: any) => {
        return value.Diet;
    }
    exitReasonKeyFormatter = (value: any) => {
        return value.C_ExitReason_key;
    }
    exitReasonFormatter = (value: any) => {
        return value.ExitReason;
    }
    iacucProtocolKeyFormatter = (value: any) => {
        return value.C_IACUCProtocol_key;
    }
    iacucProtocolFormatter = (value: any) => {
        return value.IACUCProtocol;
    }
    physicalMarkerTypeKeyFormatter = (value: any) => {
        return value.C_PhysicalMarkerType_key;
    }
    physicalMarkerTypeFormatter = (value: any) => {
        return value.PhysicalMarkerType;
    }
    resourceKeyFormatter = (value: Resource): number => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: Resource): string => {
        return value.ResourceName;
    }

    // Assumes item is already in array
    private isItemUniqueInArray(item: any, array: any[]): boolean {
        const matches = array.filter((x: any) => !!x && x.toLowerCase() === item.toLowerCase());
        return matches.length === 1;
    }

    onModelChange(animalComment: any) {
        animalComment.trackId++;
    }

    // trackItem is used in the trackBy clause in the ngFor, which allows the list to be arbitrarily redrawn (thus, allowing the pipe to update the style of table row).
    // this setup is to prevent the row's style being determined only when the relevant information changes, rather than every change detection interval.
    trackItem(index: number, item: any) {
        return item.trackId;
    }

    validate(): string {
        const inputsError = this.characteristicInput.map(input => input.validate()).find(message => Boolean(message));
        return inputsError ?? dateControlValidator(this.dateControls ?? []) ?? '';
    }
}

interface UniqueFieldData {
    error: boolean;
    originalValue: any;
}
