<form role="form" class="form-label-right">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Permits Filter</h4>
    </div>

    <div class="modal-body">
        <div class="form-group col-md-12">
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Number</label>
                <div class="col-md-4">
                    <permit-multiselect [(model)]="filter.permitNumber" data-auto-focus="permitNumber"></permit-multiselect>
                </div>

                <label class="col-md-2 col-form-label">{{'Line' | translate}}</label>
                <div class="col-md-4">
                    <line-multiselect [(model)]="filter.lines"></line-multiselect>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Owner</label>
                <div class="col-md-4">
                    <climb-multi-select
                        [(model)]="filter.permitOwnerKeys"
                        [values]="resources"
                        [keyProperty]="'C_Resource_key'"
                        [valueProperty]="'ResourceName'"
                        [placeholder]="'Select permit owners...'">
                    </climb-multi-select>
                </div>

                <label class="col-md-2 col-form-label">External Purpose</label>
                <div class="col-md-4">
                    <climb-multi-select [(model)]="filter.permitExternalPurposeKeys"
                                        [values]="permitExternalPurposes"
                                        [keyProperty]="'C_PermitExternalPurpose_key'"
                                        [valueProperty]="'PermitExternalPurpose'"
                                        [placeholder]="'Select external purposes...'"></climb-multi-select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Title</label>
                <div class="col-md-4">
                    <input type="text" name="title" [(ngModel)]="filter.title" class="form-control input-medium">
                    <small class="form-text text-muted">
                        Find titles <em>containing</em> this value.
                    </small>
                </div>

                <label class="col-md-2 col-form-label">Other Purpose</label>
                <div class="col-md-4">
                    <textarea name="otherPurpose" [(ngModel)]="filter.otherPurpose" class="form-control input-medium"></textarea>
                    <small class="form-text text-muted">
                        Find other purpose <em>containing</em> this value.
                    </small>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Status</label>
                <div class="col-md-4">
                    <climb-multi-select [(model)]="filter.permitStatusKeys"
                                        [values]="permitStatuses"
                                        [keyProperty]="'C_PermitStatus_key'"
                                        [valueProperty]="'PermitStatus'"
                                        [placeholder]="'Select statuses...'"></climb-multi-select>
                </div>

                <label class="col-md-2 col-form-label">{{'Institution' | translate}}(s)</label>
                <div class="col-md-4">
                    <institution-multiselect [(model)]="filter.institutions"></institution-multiselect>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Category</label>
                <div class="col-md-4">
                    <climb-multi-select [(model)]="filter.permitCategoryKeys"
                                        [values]="permitCategories"
                                        [keyProperty]="'C_PermitCategory_key'"
                                        [valueProperty]="'PermitCategory'"
                                        [placeholder]="'Select categories...'"></climb-multi-select>
                </div>

                <label class="col-md-2 col-form-label">Species</label>
                <div class="col-md-4">
                    <climb-multi-select [(model)]="filter.permitSpeciesKeys"
                                        [values]="species"
                                        [keyProperty]="'C_Taxon_key'"
                                        [valueProperty]="'Taxon'"
                                        [placeholder]="'Select species...'"></climb-multi-select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Start Date</label>
                <div class="col-md-5">
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="filter.startDateFrom"
                                    name="startDateFrom"
                                    placeholder="From&hellip;"></climb-ngb-date>
                </div>
                <div class="col-md-5">
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="filter.startDateTo"
                                    name="startDateTo"
                                    placeholder="To&hellip;"></climb-ngb-date>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-2 col-form-label">Expiry Date</label>
                <div class="col-md-5">
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="filter.expiryDateFrom"
                                    name="expiryDateFrom"
                                    placeholder="From&hellip;"></climb-ngb-date>
                </div>
                <div class="col-md-5">
                    <climb-ngb-date #dateControl="ngModel"
                                    [(ngModel)]="filter.expiryDateTo"
                                    name="expiryDateTo"
                                    placeholder="To&hellip;"></climb-ngb-date>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>
