import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChildren,
} from '@angular/core';

import { BaseFilter } from '../../common/facet';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    focusElementByQuery,
} from '../../common/util/';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { NgModel } from '@angular/forms';
import { LoggingService } from '@services/logging.service';
import { dateControlValidator } from '@common/util/date-control.validator';
import { AnimalVocabService } from 'src/app/animals/services/animal-vocab.service';
import { cv_PermitCategory, cv_PermitExternalPurpose, cv_PermitStatus, cv_Taxon, Resource } from '@common/types';
import { IPermitFilter } from '../models/permit-filter.interface';


@Component({
    selector: 'permits-filter',
    templateUrl: './permits-filter.component.html'
})
export class PermitsFilterComponent extends BaseFilter implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() filter: IPermitFilter = {};
    @Output() onFilter = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();
    public permitCategories: cv_PermitCategory[];
    public permitStatuses: cv_PermitStatus[];
    public permitExternalPurposes: cv_PermitExternalPurpose[];
    public species: cv_Taxon[] = [];
    public resources: Resource[];

    readonly MULTI_PASTE_INPUT_LIMIT = 100;

    constructor(
        private activeModal: NgbActiveModal,
        private vocabularyService: VocabularyService,
        private loggingService: LoggingService,
        private animalVocabService: AnimalVocabService,
    ) {
        super(activeModal);
    }

    public ngOnInit(): void {
        this.cloneFilter();

        this.getCVs();
        
        focusElementByQuery('[data-auto-focus="permitNumber"] input');
    }

    private cloneFilter() {
        const savedValues: Partial<IPermitFilter> = { ...this.filter };

        this.filter = { ...this.filter, ...savedValues };
    }

    public filterClicked(event: Event): void {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }
        event.preventDefault();
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    public closeClicked(): void {
        this.close.emit();
        this.activeModal.dismiss();
    }

    public clearClicked(): void {
        this.filter = {};
        this.onFilter.emit(this.filter);
        this.close.emit();
        this.activeModal.close();
    }

    private async getCVs(): Promise<void> {
        this.animalVocabService.resources$.subscribe((data) => {
            this.resources = data.filter((resource) => {
                return !resource.IsGroup;
            });
        });

        const result = await Promise.all([
            this.vocabularyService.getCV('cv_PermitCategories'),
            this.vocabularyService.getCV('cv_PermitStatuses'),
            this.vocabularyService.getCV('cv_PermitExternalPurposes'),
            this.vocabularyService.getCV('cv_Taxons'),
        ]);
        [this.permitCategories, this.permitStatuses, this.permitExternalPurposes, this.species] = result;
    }
}
