import { ApiResponse } from '@services/models/api-response';
import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult,
} from 'breeze-client';

import {
    notEmpty,
} from '../common/util';

import { getDateRangePredicatesUTC } from '@services/queries';

import { DataManagerService } from '@services/data-manager.service';
import { QueryDef } from '@services/query-def';
import { BaseEntityService } from '@services/base-entity.service';
import { WebApiService } from '@services/web-api.service';
import { AuditFilterVisibility } from './audit-filter-visibility';
import { AdminManagerService } from '@services/admin-manager.service';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { TableColumnDef } from '@common/datatable';
import { Subject } from 'rxjs';
import { AuditGridView } from './models/audit-grid-view.enum';
import { trackModifiedDateFieldInAudit } from '../config/environment';
import { TranslationService } from '@services/translation.service';
import { getApiAuditUsername } from '@common/audit/api-user-constants';

@Injectable()
export class AuditService extends BaseEntityService {
    auditLinkClicked$: Subject<AuditGridView> = new Subject<AuditGridView>();
    currentGridView = "";

    constructor(
        private dataManager: DataManagerService,
        private adminManager: AdminManagerService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private webApiService: WebApiService,
        private translationService: TranslationService
    ) {
        super();
    }

    getAnimals(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditAnimals', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildAnimalPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getAnimalComments(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditAnimalComments', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildAnimalCommentPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }
    getAnimalsTasks(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditAnimalsTasks', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildAnimalTaskPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getSamples(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditSamples', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildSamplePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getSettings(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditSettings', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildSettingPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }


    getJobs(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobs', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobAnimals(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobAnimals', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobAnimalPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobSamples(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobSamples', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobSamplePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    async getJobSampleGroups(queryDef: QueryDef, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobSampleGroups', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = await this.buildJobSampleGroupPredicates(queryDef.filter, isCRO, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    async getJobTasks(queryDef: QueryDef, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobTasks', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = await this.buildJobTaskPredicates(queryDef.filter, isCRO, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobLines(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobLines', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobLinePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobLineDetails(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobLineDetails', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobLineDetailPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobAnimalPlaceholders(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobAnimalPlaceholders', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobAnimalPlaceholderPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobGroupPlaceholders(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobGroupPlaceholders', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobGroupPlaceholderPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobGroups(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobGroups', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobGroupPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobInstitutions(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobInstitutions', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobInstitutionPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobOrders(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobOrders', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobOrderPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobStandardPhrases(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobStandardPhrases', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobStandardPhrasePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobTestArticles(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobTestArticles', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobTestArticlePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobCharacteristics(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditJobCharacteristics', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobCharacteristicPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    async getWorkflowInputs(queryDef: QueryDef, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditInputs', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = await this.buildWorkflowInputPredicates(queryDef.filter, isCRO, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    async getWorkflowOutputs(queryDef: QueryDef, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditOutputs', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = await this.buildWorkflowOutputPredicates(queryDef.filter, isCRO, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getTaskInputs(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditTaskInputs', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildTaskInputPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getTaskOutputs(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditTaskOutputs', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildTaskOutputPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getMaterialPools(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditMaterialPools', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildMaterialPoolPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getMaterialPoolMaterials(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditMaterialPoolMaterials', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildMaterialPoolMaterialPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getMaterialPoolTasks(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditMaterialPoolTasks', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildMaterialPoolTaskPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getMaterialPoolSocialStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditMaterialPoolSocialStatuses', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildMaterialPoolSocialStatusPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getMaterialLocations(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditMaterialLocations', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildMaterialLocationPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getMatings(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditMatings', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildMatingPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getMatingMaterials(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditMatingMaterials', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildMatingMaterialPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getCohorts(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditCohorts', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildCohortPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getCohortAnimals(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditCohortAnimals', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildCohortAnimalPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getLines(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditLines', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildLinePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getStudies(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditStudies', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildStudyPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getStudyLines(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditStudyLines', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildStudyLinePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getBirths(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditBirths', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildBirthPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getLocations(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditLocations', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildLocationPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getAnimalClinicalObservations(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditAnimalClinicalObservations', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildAnimalClinicalObservationPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    getAnimalHealthRecords(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditAnimalHealthRecords', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildAnimalHealthRecordPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getTaskAnimalHealthRecords(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditTaskAnimalHealthRecords', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildTaskAnimalHealthRecordPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getClinicalObservationDetails(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditClinicalObservationDetails', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildClinicalObservationDetailPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getObservationDetails(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditObservationDetails', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildObservationDetailPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    async getWorkflowTasks(queryDef: QueryDef, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditWorkflowTasks', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = await this.buildWorkflowTaskPredicates(queryDef.filter, isCRO, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    async getTaskAssignments(queryDef: QueryDef, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditTaskAssignments', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = await this.buildTaskAssignmentPredicates(queryDef.filter, isCRO, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    async getOutputFlags(queryDef: QueryDef, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditOutputFlags', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = await this.buildOutputFlagPredicates(queryDef.filter, isCRO, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getWorkflowTaskDetails(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditWorkflowTaskDetails', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildWorkflowTaskDetailPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getOrders(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditOrders', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildOrderPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getOrdersAnimals(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditOrdersAnimals', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildOrderAnimalPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getOrdersLocations(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditOrdersLocations', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildOrderLocationPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getFiles(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditFiles', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildFilePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    async getProtocolDefaultInputs(queryDef: QueryDef, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditInputDefaults', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = await this.buildProtocolDefaultInputPredicates(queryDef.filter, isCRO, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getProtocols(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditProtocols', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildProtocolPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    async getProtocolsTasks(queryDef: QueryDef, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditProtocolsTasks', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = await this.buildProtocolTaskPredicates(queryDef.filter, isCRO, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    async getProtocolsSamplesGroups(queryDef: QueryDef, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditProtocolsSamplesGroups', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = await this.buildProtocolSampleGroupPredicates(queryDef.filter, isCRO, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getResources(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditResources', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildResourcePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getStudyAdministratorStudies(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditStudyAdministratorStudies', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildStudyAdministratorStudyPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getUsers(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditUsers', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildUserPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.adminManager.executeQuery(query)
            .catch(this.adminManager.queryFailed);
    }

    getWorkgroupUsers(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditWorkgroupUsers', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildUserPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.adminManager.executeQuery(query)
            .catch(this.adminManager.queryFailed) as Promise<QueryResult>;
    }

    getUserRoles(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditClimbRoles', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildUserRolePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.adminManager.executeQuery(query)
            .catch(this.adminManager.queryFailed);
    }

    getRoleFacets(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditClimbRoleFacets', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildRoleFacetPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.adminManager.executeQuery(query)
            .catch(this.adminManager.queryFailed);
    }

    getVocabularyTaskFlagMessages(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyTaskFlagMessages', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyTaskFlagMessagePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyTaskStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyTaskStatuses', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyTaskStatusPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyAnimalClassifications(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyAnimalClassifications', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyAnimalClassificationPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyAnimalCommentStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyAnimalCommentStatuses', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyAnimalCommentStatusPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    getVocabularyStandardPhrases(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyStandardPhrases', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyStandardPhrasePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    getVocabularyTestArticles(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyTestArticles', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyTestArticlePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    getVocabularyAnimalStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyAnimalStatuses', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyAnimalStatusPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyMatingStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyMatingStatuses', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyMatingStatusPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyCouriers(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyCouriers', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyCourierPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    getVocabularyLineStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyLineStatuses', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyLineStatusPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getCharacteristicAnimals(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditCharacteristicAnimals', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildCharacteristicAnimalsPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getCharacteristicJobs(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditCharacteristicJobs', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildCharacteristicJobsPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyJobStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyJobStatuses', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyJobStatusPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyAnimalUses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyAnimalUses', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyAnimalUsePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getCharacteristicStudies(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditCharacteristicStudies', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildCharacteristicStudiesPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyTaskOutcomes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyTaskOutcomes', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyTaskOutcomePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularySampleAnalyses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularySampleAnalysisMethods', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularySampleAnalysisPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularySampleConditions(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularySampleConditions', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularySampleConditionPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getCharacteristicSamples(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditCharacteristicSamples', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildCharacteristicSamplesPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyPlateStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyPlateStatuses', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyPlateStatusPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }


    getVocabularyBirthStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyBirthStatuses', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyBirthStatusPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyBodyConditionScores(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyBodyConditionScores', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyBodyConditionScores(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyBreedingStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyBreedingStatuses', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyBreedingStatusPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getNameFormats(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditNameFormats', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildNameFormatPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getFacetSettings(queryDef: QueryDef, filterVisibility: AuditFilterVisibility, isCRO: boolean, isGLP: boolean, isCRL: boolean): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditFacetSettings', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildFacetSettingPredicates(queryDef.filter, filterVisibility, isCRO, isGLP, isCRL);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyShipmentAnimalConditions(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyShipmentAnimalConditions', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyShipmentAnimalConditionPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyShipmentContainerConditions(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyShipmentContainerConditions', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyShipmentContainerConditionPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyPhysicalMarkerTypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyPhysicalMarkerTypes', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyPhysicalMarkerTypePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyClinicalObservationStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyClinicalObservationStatuses', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyClinicalObservationStatusPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyClinicalObservations(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyClinicalObservations', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyClinicalObservationPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyClinicalObservationBodySystems(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyClinicalObservationBodySystems', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyClinicalObservationBodySystemPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyDiets(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyDiets', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyDietPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyGenerations(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyGenerations', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyGenerationPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyLocationTypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyLocationTypes', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyLocationTypePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getEnumerationClasses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditEnumerationClasses', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildEnumerationClassPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getEnumerationItems(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditEnumerationItems', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildEnumerationItemPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyResourceGroups(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyResourceGroups', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyResourceGroupPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyResourceTypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyResourceTypes', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyResourceTypePredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyCompliances(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyCompliances', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyCompliancePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyInstitutionTypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyInstitutionTypes', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyInstitutionTypePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }
    getVocabularyLineTypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyLineTypes', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyLineTypePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyIACUCProtocols(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyIACUCProtocols', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyIACUCProtocolPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyExitReasons(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyExitReasons', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyExitReasonPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }
    getVocabularyGenotypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyGenotypes', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyGenotypePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }


    getVocabularyMaterialPoolStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyMaterialPoolStatuses', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyMaterialPoolStatusPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }


    getVocabularyMatingPurposes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyMatingPurposes', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyMatingPurposePredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyPaymentTerms(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyPaymentTerms', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyPaymentTermPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyMatingTypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyMatingTypes', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyMatingTypePredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyCompatibilityAccessLevels(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyCompatibilityAccessLevels', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyCompatibilityAccessLevelPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyModifiers1(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyModifiers1', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyModifier1Predicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyModifiers2(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyModifiers2', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyModifier2Predicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyModifiers3(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyModifiers3', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyModifier3Predicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyModifiers4(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyModifiers4', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyModifier4Predicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularySocialGroupAccessLevels(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularySocialGroupAccessLevels', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularySocialGroupAccessLevelPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyContainerTypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyContainerTypes', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyContainerTypePredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyJobReports(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyJobReports', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyJobReportPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }


    getVocabularyMaterialPoolTypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyMaterialPoolTypes', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyMaterialPoolTypePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyContatPersonTypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyContactPersonTypes', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyContactPersonTypePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyJobTypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyJobTypes', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyJobTypePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyQuarantineFacilities(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyQuarantineFacilities', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyQuarantineFacilityPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }


    getVocabularyGenotypeAssays(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyGenotypeAssays', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyGenotypeAssayPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularySiteTypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularySiteTypes', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularySiteTypePredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyUnits(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyUnits', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyUnitPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyRouteOfAdministrations(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyRouteOfAdministrations', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyRouteOfAdministrationPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyVeterinaryRequestStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyVeterinaryRequestStatuses', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyVeterinaryRequestStatusPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyJobSubtypes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyJobSubtypes', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyJobSubtypePredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    private getAuditAdminPredicate(): Predicate {
        const workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();

        return Predicate.create('WorkgroupKey', 'eq', workgroupKey);
    }

    getVocabularyMaterialOrigins(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyMaterialOrigins', queryDef);

        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyMaterialOriginPredicates(queryDef.filter, filterVisibility);

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobLocations(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery("AuditJobLocations", queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildJobLocationPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getStudyCharacteristics(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery("AuditStudyCharacteristics", queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildStudyCharacteristicPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getSampleCharacteristics(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery("AuditSampleCharacteristics", queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildSampleCharacteristicPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getAnimalCharacteristics(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery("AuditAnimalCharacteristics", queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildAnimalCharacteristicPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getJobVariablePhrases(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        const query = this.buildJobVariablePhraseQuery(queryDef, filterVisibility);
        return this.dataManager
            .executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyPermitStatuses(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyPermitStatuses', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyPermitStatusesPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyPermitExternalPurposes(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyPermitExternalPurposes', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyPermitExternalPurposesPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularySeverities(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularySeverities', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularySeveritiesPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    getVocabularyPermitCategories(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AuditVocabularyPermitCategories', queryDef);
        if (queryDef.filter) {
            const predicates: Predicate[] = this.buildVocabularyPermitCategoriesPredicates(queryDef.filter, filterVisibility);
            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    private baseAuditPredicates(filter: any): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = [];

        if (filter.UpdateTypes && filter.UpdateTypes.length > 0) {
            predicates.push(Predicate.create('UpdateType', 'in', filter.UpdateTypes));
        }

        if (filter.ModifiedBy && filter.ModifiedBy.length > 0) {
            const userNames = filter.ModifiedBy.map((x: any) => x.User.UserName);
            const apiAuditUserNames = userNames.map((username: string) => getApiAuditUsername(username));
            predicates.push(Predicate.create('ModifiedBy', 'in', userNames)
                .or(Predicate.create('ModifiedBy', 'in', apiAuditUserNames)));
        }

        if (filter.CreatedBy && filter.CreatedBy.length > 0) {
            const userNames = filter.CreatedBy.map((x: any) => x.User.UserName);
            const userNamesWithApiSuffix = userNames.map((username: string) => getApiAuditUsername(username));
            predicates.push(Predicate.create('CreatedBy', 'in', userNames)
                .or(Predicate.create('CreatedBy', 'in', userNamesWithApiSuffix)));
        }

        if (filter.ModifiedDateStart || filter.ModifiedDateEnd) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'ModifiedDate',
                filter.ModifiedDateStart,
                filter.ModifiedDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.CreatedDateStart || filter.CreatedDateEnd) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'DateCreated',
                filter.CreatedDateStart,
                filter.CreatedDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (!trackModifiedDateFieldInAudit){
            predicates.push(Predicate.create('ModifiedFields', 'ne', '"ModifiedDate"'));
        }

        return predicates;
    }

    private baseAnimalPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = [];

        if (filter.AnimalIdentifier && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('Identifier', FilterQueryOp.Contains, { value: filter.AnimalIdentifier }));
        } else if (filter.AnimalIdentifiers &&
            filter.AnimalIdentifiers.length > 0 &&
            filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('Identifier', 'in', filter.AnimalIdentifiers));
        }
        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('Name', 'in', animalNames));
        }

        return predicates;
    }

    private baseSamplePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = [];

        if (filter.SampleIdentifier && filterVisibility.sampleIdentifiersVisible) {
            predicates.push(Predicate.create('Identifier', FilterQueryOp.Contains, { value: filter.SampleIdentifier }));
        } else if (filter.SampleIdentifiers &&
            filter.SampleIdentifiers.length > 0 &&
            filterVisibility.sampleIdentifiersVisible) {
            predicates.push(Predicate.create('Identifier', 'in', filter.SampleIdentifiers));
        }
        if (filter.Samples && filter.Samples.length > 0 && filterVisibility.samplesVisible) {
            const sampleNames = filter.Samples.map((x: any) => x.SampleName);
            predicates.push(Predicate.create('Name', 'in', sampleNames));
        }

        return predicates;
    }

    private baseJobPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = [];
        if (filter.Jobs && filter.Jobs.length && filterVisibility.jobsVisible) {
            const jobNames = filter.Jobs.map((x: any) => x.JobID);
            predicates.push(Predicate.create('JobName', 'in', jobNames));
        }

        return predicates;
    }

    private async baseTaskPredicates(filter: any, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<Predicate[]> {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = [];
        if (filter.Studies && filter.Studies.length > 0 && filterVisibility.studiesVisible) {
            const studyKeys = filter.Studies.map((x: any) => x.StudyKey);
            predicates.push(Predicate.create('C_Study_key', 'in', studyKeys));
        }

        if (filter.WorkflowTaskKeys &&
            filter.WorkflowTaskKeys.length > 0 &&
            filterVisibility.workflowTaskKeysVisible) {
            if (isCRO) {
                const parentAndchildTasks = await this.getChildWorkflowTasks(filter.WorkflowTaskKeys);
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', parentAndchildTasks));
            } else {
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', filter.WorkflowTaskKeys));
            }
        }

        if (filter.TaskNames && filter.TaskNames.length > 0 && filterVisibility.taskNamesVisible) {
            predicates.push(Predicate.create('TaskName', 'in', filter.TaskNames));
        }

        return predicates;
    }

    private baseMatingPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = [];

        if (filter.Matings && filter.Matings.length > 0 && filterVisibility.matingsVisible) {
            const ids = filter.Matings.map((x: any) => x.MatingID);
            predicates.push(Predicate.create('MatingID', 'in', ids));
        }

        if (filter.Housings && filter.Housings.length > 0 && filterVisibility.housingsVisible) {
            const ids = filter.Housings.map((x: any) => x.MaterialPoolID);
            predicates.push(Predicate.create('MaterialPoolID', 'in', ids));
        }

        if (filter.MatingStatuses &&
            filter.MatingStatuses.length > 0 &&
            filterVisibility.matingStatusesVisible) {
            predicates.push(Predicate.create('MatingStatus', 'in', filter.MatingStatuses));
        }

        if (filter.AnimalMatingStatuses &&
            filter.AnimalMatingStatuses.length > 0 &&
            filterVisibility.animalMatingStatusesVisible) {
            predicates.push(Predicate.create('AnimalMatingStatus', 'in', filter.AnimalMatingStatuses));
        }

        if (filter.MatingTypes &&
            filter.MatingTypes.length > 0 &&
            filterVisibility.matingTypesVisible) {
            predicates.push(Predicate.create('MatingType', 'in', filter.MatingTypes));
        }

        if ((filter.MatingDateStart || filter.MatingDateEnd) && filterVisibility.matingDateRangeVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'DateMating',
                filter.MatingDateStart,
                filter.MatingDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        return predicates;
    }

    private baseCohortPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = [];

        if (filter.Cohorts && filter.Cohorts.length > 0 && filterVisibility.cohortsVisible) {
            const names = filter.Cohorts.map((x: any) => x.CohortName);
            predicates.push(Predicate.create('Name', 'in', names));
        }

        return predicates;
    }

    private baseLinePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = [];

        if (filter.Lines && filter.Lines.length > 0 && filterVisibility.linesVisible) {
            const names = filter.Lines.map((x: any) => x.LineName);
            predicates.push(Predicate.create('LineName', 'in', names));
        }

        return predicates;
    }

    private baseProtocolPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = [];

        if (filter.Protocols && filter.Protocols.length > 0 && filterVisibility.protocolsVisible) {
            const names = filter.Protocols.map((x: any) => x.ProtocolName);
            predicates.push(Predicate.create('Name', 'in', names));
        }

        return predicates;
    }

    private buildAnimalPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = predicates.concat(this.baseAnimalPredicates(filter, filterVisibility));

        if (filter.MicrochipIdentifier && filterVisibility.microchipIdentifiersVisible) {
            predicates.push(Predicate.create('MicrochipID', FilterQueryOp.Contains, { value: filter.MicrochipIdentifier }));
        } else if (filter.MicrochipIdentifiers &&
            filter.MicrochipIdentifiers.length > 0 &&
            filterVisibility.microchipIdentifiersVisible) {
            predicates.push(Predicate.create('MicrochipID', 'in', filter.MicrochipIdentifiers));
        }

        predicates.push(Predicate.create('ModifiedFields', 'ne', "'ReasonForChange'"));
        return predicates;
    }

    private buildAnimalCommentPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = predicates.concat(this.baseAnimalPredicates(filter, filterVisibility));

        if (filter.AnimalComments && filterVisibility.animalCommentsVisible) {
            predicates.push(Predicate.create('tolower(Comment)', FilterQueryOp.Contains, { value: filter.AnimalComments.toLowerCase() }));
        }

        if (filter.AnimalCommentStatuses && filter.AnimalCommentStatuses.length > 0 && filterVisibility.animalCommentStatusesVisible) {
            predicates.push(Predicate.create('Status', 'in', filter.AnimalCommentStatuses));
        }

        if (filterVisibility.animalCommentAnimalIDsVisible) {
            if (filter.AnimalCommentID) {
                predicates.push(Predicate.create('AnimalID', FilterQueryOp.Contains, { value: filter.AnimalCommentID }));
            } else if (filter.AnimalCommentIDs && filter.AnimalCommentIDs.length > 0) {
                predicates.push(Predicate.create('AnimalID', 'in', filter.AnimalCommentIDs));
            }
        }


        if (filter.animalCommentAnimalNames && filter.animalCommentAnimalNames.length > 0 && filterVisibility.animalCommentAnimalNamesVisible) {
            const animalNames = filter.animalCommentAnimalNames.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('AnimalName', 'in', animalNames));
        }

        return predicates;
    }

    private buildAnimalTaskPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.AnimalIdentifier && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', FilterQueryOp.Contains, { value: filter.AnimalIdentifier }));
        } else if (filter.AnimalIdentifiers &&
            filter.AnimalIdentifiers.length > 0 &&
            filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', 'in', filter.AnimalIdentifiers));
        }

        if (filter.TaskNames && filter.TaskNames.length > 0 && filterVisibility.taskNamesVisible) {
            predicates.push(Predicate.create('TaskName', 'in', filter.TaskNames));
        }

        if ((filter.DueDateStart || filter.DueDateEnd) && filterVisibility.dueDateRangeVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'DueDate',
                filter.DueDateStart,
                filter.DueDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        return predicates;
    }

    private buildSamplePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = predicates.concat(this.baseSamplePredicates(filter, filterVisibility));
        if (filter.SourceIdentifiers &&
            filter.SourceIdentifiers.length > 0 &&
            filterVisibility.sourceIdentifiersVisible) {
            predicates.push(
                this.buildSubstringPredicates('SourceIdentifiers', filter.SourceIdentifiers));
        }
        if (filter.SourceMaterials &&
            filter.SourceMaterials.length > 0 &&
            filterVisibility.sourceMaterialsVisible) {
            const sourceNames = filter.SourceMaterials.map((x: any) => x.SampleName || x.AnimalName);
            predicates.push(
                this.buildSubstringPredicates('SourceNames', sourceNames));
        }

        return predicates;
    }

    private buildJobPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));

        if (filter.Studies && filter.Studies.length > 0 && filterVisibility.studiesVisible) {
            const studyNames = filter.Studies.map((x: any) => x.StudyName);
            predicates.push(Predicate.create('Study', 'in', studyNames));
        }
        if (filter.JobStatuses && filter.JobStatuses.length > 0 && filterVisibility.jobStatusesVisible) {
            predicates.push(Predicate.create('Status', 'in', filter.JobStatuses));
        }
        if (filter.JobTypes && filter.JobTypes.length > 0 && filterVisibility.jobTypesVisible) {
            predicates.push(Predicate.create('JobType', 'in', filter.JobTypes));
        }
        if (filter.JobSubtypes && filter.JobSubtypes.length > 0 && filterVisibility.jobSubtypesVisible) {
            predicates.push(Predicate.create('JobSubtype', 'in', filter.JobSubtypes));
        }

        return predicates;
    }

    private buildJobAnimalPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));
        predicates = predicates.concat(this.baseAnimalPredicates(filter, filterVisibility));

        return predicates;
    }

    private buildJobSamplePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));
        predicates = predicates.concat(this.baseSamplePredicates(filter, filterVisibility));

        return predicates;
    }

    private async buildJobSampleGroupPredicates(filter: any, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<Predicate[]> {
        if (!filter) {
            return Promise.resolve([]);
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));

        if (filter.WorkflowTaskKeys &&
            filter.WorkflowTaskKeys.length > 0 &&
            filterVisibility.workflowTaskKeysVisible) {
            if (isCRO) {
                const parentAndchildTasks = await this.getChildWorkflowTasks(filter.WorkflowTaskKeys);
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', parentAndchildTasks));
            } else {
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', filter.WorkflowTaskKeys));
            }
        }

        return Promise.resolve(predicates);
    }

    private async buildJobTaskPredicates(filter: any, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<Predicate[]> {
        if (!filter) {
            return Promise.resolve([]);
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));
        predicates = predicates.concat(await this.baseTaskPredicates(filter, isCRO, filterVisibility));

        if (filter.TaskStatuses &&
            filter.TaskStatuses.length > 0 &&
            filterVisibility.taskStatusesVisible) {
            predicates.push(Predicate.create('TaskStatus', 'in', filter.TaskStatuses));
        }

        if ((filter.DueDateStart || filter.DueDateEnd) && filterVisibility.dueDateRangeVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'DueDate',
                filter.DueDateStart,
                filter.DueDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        return Promise.resolve(predicates);
    }

    private buildJobLinePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));
        predicates = predicates.concat(this.baseLinePredicates(filter, filterVisibility));

        if (filter.Taxons && filter.Taxons.length > 0 && filterVisibility.taxonsVisible) {
            predicates.push(Predicate.create('Species', 'in', filter.Taxons));
        }

        return predicates;
    }

    private buildJobLineDetailPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));
        predicates = predicates.concat(this.baseLinePredicates(filter, filterVisibility));

        return predicates;
    }

    private buildJobStandardPhrasePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));

        if (filter.StandardPhrases &&
            filter.StandardPhrases.length > 0 &&
            filterVisibility.standardPhrasesVisible) {
            const standardPhrases = filter.StandardPhrases.map((x: any) => x.StandardPhrase);
            predicates.push(Predicate.create('StandardPhrase', 'in', standardPhrases));
        }
        return predicates;
    }

    private buildJobVariablePhraseQuery(queryDef: QueryDef, filterVisibility: AuditFilterVisibility): EntityQuery {
        const query = this.buildDefaultQuery("AuditJobVariablePhrases", queryDef);
        if (!queryDef.filter) {
            return query;
        }

        const filter = queryDef.filter;
        const predicates: Predicate[] = this.baseAuditPredicates(filter)
            .concat(this.baseJobPredicates(filter, filterVisibility));
        if (filter.VariablePhraseTypes &&
            filter.VariablePhraseTypes.length > 0 &&
            filterVisibility.variablePhraseTypesVisible) {
            predicates.push(Predicate.create('VariablePhraseType', 'in', filter.VariablePhraseTypes));
        }

        return query.where(Predicate.and(predicates));
    }

    private buildJobGroupPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));

        if (filter.Groups && filter.Groups.length > 0 && filterVisibility.groupsVisible) {
            const groups = filter.Groups.map((g: any) => g.toLowerCase());
            predicates.push(Predicate.create('tolower(Group)', 'in', groups));
        }

        return predicates;
    }

    private buildJobTestArticlePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));

        if (filter.TestArticles &&
            filter.TestArticles.length > 0 &&
            filterVisibility.testArticlesVisible) {
            const testArticles = filter.TestArticles.map((x: any) => x.TestArticle);
            predicates.push(Predicate.create('TestArticle', 'in', testArticles));
        }

        return predicates;
    }

    private buildJobCharacteristicPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));

        if (filter.JobCharacteristics &&
            filter.JobCharacteristics.length > 0 &&
            filterVisibility.jobCharacteristicsVisible) {
            predicates.push(Predicate.create('CharacteristicName', 'in', filter.JobCharacteristics));
        }

        return predicates;
    }

    private buildJobInstitutionPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));

        if (filter.Institutions &&
            filter.Institutions.length > 0 &&
            filterVisibility.institutionsVisible) {
            const institutionNames = filter.Institutions.map((x: any) => x.Name);
            predicates.push(Predicate.create('InstitutionName', 'in', institutionNames));
        }

        return predicates;
    }

    private buildJobOrderPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));
        if (filter.OrderId && filterVisibility.orderIdVisible) {
            predicates.push(Predicate.create('tolower(OrderID)', FilterQueryOp.Contains, { value: filter.OrderId.toLowerCase() }));
        }
        if (filter.Studies && filter.Studies.length > 0 && filterVisibility.studiesVisible) {
            const studyNames = filter.Studies.map((x: any) => x.StudyName);
            predicates.push(Predicate.create('StudyName', 'in', studyNames));
        }
        return predicates;
    }

    private buildJobAnimalPlaceholderPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));

        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('AnimalName', 'in', animalNames));
        }

        if (filter.AnimalPlaceholders &&
            filter.AnimalPlaceholders.length > 0 &&
            filterVisibility.animalPlaceholdersVisible) {
            const placeholderNames = filter.AnimalPlaceholders.map((x: any) => x.Name);
            predicates.push(Predicate.create('PlaceholderName', 'in', placeholderNames));
        }

        return predicates;
    }

    private buildJobGroupPlaceholderPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));

        if (filter.Cohorts && filter.Cohorts.length > 0 && filterVisibility.cohortsVisible) {
            const cohortNames = filter.Cohorts.map((x: any) => x.CohortName);
            predicates.push(Predicate.create('CohortName', 'in', cohortNames));
        }

        if (filter.GroupPlaceholders &&
            filter.GroupPlaceholders.length > 0 &&
            filterVisibility.groupPlaceholdersVisible) {
            const placeholderNames = filter.GroupPlaceholders.map((x: any) => x.PlaceholderName);
            predicates.push(Predicate.create('PlaceholderName', 'in', placeholderNames));
        }

        return predicates;
    }

    private async buildWorkflowInputPredicates(filter: any, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<Predicate[]> {
        if (!filter) {
            return Promise.resolve([]);
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));
        predicates = predicates.concat(await this.baseTaskPredicates(filter, isCRO, filterVisibility));

        if (filter.Studies && filter.Studies.length > 0 && filterVisibility.studiesVisible) {
            const studyNames = filter.Studies.map((x: any) => x.StudyName);
            predicates.push(Predicate.create('StudyName', 'in', studyNames));
        }

        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('AnimalName', 'in', animalNames));
        }

        if (filter.Samples && filter.Samples.length > 0 && filterVisibility.samplesVisible) {
            const sampleNames = filter.Samples.map((x: any) => x.SampleName);
            predicates.push(Predicate.create('SampleName', 'in', sampleNames));
        }

        if (filter.TaskTypes && filter.TaskTypes.length > 0 && filterVisibility.taskTypesVisible) {
            predicates.push(Predicate.create('TaskType', 'in', filter.TaskTypes));
        }

        return Promise.resolve(predicates);
    }

    private async buildWorkflowOutputPredicates(filter: any, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<Predicate[]> {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);
        predicates = predicates.concat(this.baseJobPredicates(filter, filterVisibility));
        predicates = predicates.concat(await this.baseTaskPredicates(filter, isCRO, filterVisibility));

        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('AnimalName', 'in', animalNames));
        }

        if (filter.Samples && filter.Samples.length > 0 && filterVisibility.samplesVisible) {
            const sampleNames = filter.Samples.map((x: any) => x.SampleName);
            predicates.push(Predicate.create('SampleName', 'in', sampleNames));
        }

        if (filter.TaskTypes && filter.TaskTypes.length > 0 && filterVisibility.taskTypesVisible) {
            predicates.push(Predicate.create('TaskType', 'in', filter.TaskTypes));
        }

        return predicates;
    }

    private buildSettingPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);


        if (filter.DefaultHealthTechnician && filterVisibility.defaultHealthTechVisible) {
            predicates.push(Predicate.create('tolower(DefaultHealthTechnician)', FilterQueryOp.Contains, { value: filter.DefaultHealthTechnician.toLowerCase() }));
        }

        if (filter.InactivityTimeout && filterVisibility.inactivityTimeoutVisible) {
            predicates.push(Predicate.create('TimeoutMinutes', 'eq', filter.InactivityTimeout));
        }
        return predicates;
    }

    private buildTaskInputPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.InputName && filterVisibility.inputNameVisible) {
            predicates.push(Predicate.create("tolower(InputName)", FilterQueryOp.Contains, { value: filter.InputName.toLowerCase() }));
        }

        if (filter.WorkflowTaskKeys &&
            filter.WorkflowTaskKeys.length > 0 &&
            filterVisibility.workflowTaskKeysVisible) {
            predicates.push(Predicate.create('C_WorkflowTask_key', 'in', filter.WorkflowTaskKeys));
        }

        if (filter.ActiveInputs && filter.ActiveInputs.length > 0 && filterVisibility.activeInputVisible) {
            predicates.push(Predicate.create("IsActive", "in", filter.ActiveInputs));
        }

        if (filter.RequiredInputs && filter.RequiredInputs.length > 0 && filterVisibility.requiredInputVisible) {
            predicates.push(Predicate.create("IsRequired", "in", filter.RequiredInputs));
        }

        if (filter.DataTypes && filter.DataTypes.length > 0 && filterVisibility.dataTypeVisible) {
            predicates.push(Predicate.create("DataType", "in", filter.DataTypes));
        }

        return predicates;
    }

    private buildTaskOutputPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.OutputName && filterVisibility.outputNameVisible) {
            predicates.push(Predicate.create("tolower(OutputName)", FilterQueryOp.Contains, { value: filter.OutputName.toLowerCase() }));
        }

        if (filter.WorkflowTaskKeys &&
            filter.WorkflowTaskKeys.length > 0 &&
            filterVisibility.workflowTaskKeysVisible) {
            predicates.push(Predicate.create('C_WorkflowTask_key', 'in', filter.WorkflowTaskKeys));
        }

        if (filter.ActiveOutputs && filter.ActiveOutputs.length > 0 && filterVisibility.activeOutputVisible) {
            predicates.push(Predicate.create("IsActive", "in", filter.ActiveOutputs));
        }

        if (filter.DataTypes && filter.DataTypes.length > 0 && filterVisibility.dataTypeVisible) {
            predicates.push(Predicate.create("DataType", "in", filter.DataTypes));
        }

        return predicates;
    }

    private buildMaterialPoolPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Housings && filter.Housings.length > 0 && filterVisibility.housingsVisible) {
            const ids = filter.Housings.map((x: any) => x.MaterialPoolID);
            predicates.push(Predicate.create('ID', 'in', ids));
        }

        if (filter.MaterialPoolTypes &&
            filter.MaterialPoolTypes.length > 0 &&
            filterVisibility.materialPoolTypesVisible) {
            predicates.push(Predicate.create('HousingType', 'in', filter.MaterialPoolTypes));
        }

        if (filter.MaterialPoolStatuses &&
            filter.MaterialPoolStatuses.length > 0 &&
            filterVisibility.materialPoolStatusesVisible) {
            predicates.push(Predicate.create('Status', 'in', filter.MaterialPoolStatuses));
        }

        if ((filter.HousingDateStart || filter.HousingDateEnd) && filterVisibility.housingDateRangeVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'HousingDate',
                filter.HousingDateStart,
                filter.HousingDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        return predicates;
    }

    private buildMaterialPoolMaterialPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Housings && filter.Housings.length > 0 && filterVisibility.housingsVisible) {
            const ids = filter.Housings.map((x: any) => x.MaterialPoolID);
            predicates.push(Predicate.create('HousingID', 'in', ids));
        }

        if (filter.MaterialPoolTypes &&
            filter.MaterialPoolTypes.length > 0 &&
            filterVisibility.materialPoolTypesVisible) {
            predicates.push(Predicate.create('HousingType', 'in', filter.MaterialPoolTypes));
        }

        if (filter.MaterialPoolStatuses &&
            filter.MaterialPoolStatuses.length > 0 &&
            filterVisibility.materialPoolStatusesVisible) {
            predicates.push(Predicate.create('HousingStatus', 'in', filter.MaterialPoolStatuses));
        }

        if (filter.AnimalIdentifier && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', FilterQueryOp.Contains, { value: filter.AnimalIdentifier }));
        } else if (filter.AnimalIdentifiers && filter.AnimalIdentifiers.length > 0) {
            predicates.push(Predicate.create('AnimalID', 'in', filter.AnimalIdentifiers));
        }
        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('AnimalName', 'in', animalNames));
        }

        if (filter.AnimalStatuses &&
            filter.AnimalStatuses.length > 0 &&
            filterVisibility.animalStatusesVisible) {
            predicates.push(Predicate.create('AnimalStatus', 'in', filter.AnimalStatuses));
        }

        if ((filter.HousingDateStart || filter.HousingDateEnd) &&
            filterVisibility.housingDateRangeVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'DateIn',
                filter.HousingDateStart,
                filter.HousingDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        return predicates;
    }

    private buildMaterialPoolTaskPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Housings && filter.Housings.length > 0 && filterVisibility.housingsVisible) {
            const ids = filter.Housings.map((x: any) => x.MaterialPoolID);
            predicates.push(Predicate.create('HousingID', 'in', ids));
        }

        if (filter.TaskNames && filter.TaskNames.length > 0) {
            predicates.push(Predicate.create('TaskName', 'in', filter.TaskNames));
        }

        if ((filter.DueDateStart || filter.DueDateEnd) && filterVisibility.dueDateRangeVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'DueDate',
                filter.DueDateStart,
                filter.DueDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        return predicates;
    }

    private buildMaterialPoolSocialStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Housings && filter.Housings.length > 0 && filterVisibility.housingsVisible) {
            const ids = filter.Housings.map((x: any) => x.MaterialPoolID);
            predicates.push(Predicate.create('HousingID', 'in', ids));
        }

        if (filter.AccessLevels && filter.AccessLevels.length > 0 && filterVisibility.accessLevelsVisible) {
            predicates.push(Predicate.create('AccessLevel', 'in', filter.AccessLevels));
        }

        if ((filter.DateInStart || filter.DateInEnd) &&
            filterVisibility.dateInRangeVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'DateIn',
                filter.DateInStart,
                filter.DateInEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if ((filter.DateOutStart || filter.DateOutEnd) &&
            filterVisibility.dateOutRangeVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'DateOut',
                filter.DateOutStart,
                filter.DateOutEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        return predicates;
    }

    private buildMaterialLocationPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Housings && filter.Housings.length > 0 && filterVisibility.housingsVisible) {
            const ids = filter.Housings.map((x: any) => x.MaterialPoolID);
            predicates.push(Predicate.create('HousingID', 'in', ids));
        }

        if (filter.Locations && filter.Locations.length > 0 && filterVisibility.locationsVisible) {
            const names = filter.Locations.map((x: any) => x.PositionName);
            predicates.push(
                this.buildSubstringPredicates('Location', names));
        }

        if ((filter.DateInStart || filter.DateInEnd) &&
            filterVisibility.dateInRangeVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'DateIn',
                filter.DateInStart,
                filter.DateInEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if ((filter.DateOutStart || filter.DateOutEnd) &&
            filterVisibility.dateOutRangeVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'DateOut',
                filter.DateOutStart,
                filter.DateOutEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        return predicates;
    }

    private buildMatingPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = predicates.concat(this.baseMatingPredicates(filter, filterVisibility));

        return predicates;
    }

    private buildMatingMaterialPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = predicates.concat(this.baseMatingPredicates(filter, filterVisibility));

        if (filter.AnimalIdentifier && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('MaterialID', FilterQueryOp.Contains, { value: filter.AnimalIdentifier }));
        } else if (filter.AnimalIdentifiers && filter.AnimalIdentifiers.length > 0) {
            predicates.push(Predicate.create('MaterialID', 'in', filter.AnimalIdentifiers));
        }
        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('MaterialName', 'in', animalNames));
        }

        return predicates;
    }

    private buildCohortPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = predicates.concat(this.baseCohortPredicates(filter, filterVisibility));

        return predicates;
    }

    private buildCohortAnimalPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Cohorts && filter.Cohorts.length > 0 && filterVisibility.cohortsVisible) {
            const names = filter.Cohorts.map((x: any) => x.CohortName);
            predicates.push(Predicate.create('CohortName', 'in', names));
        }

        if (filter.AnimalIdentifier && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', FilterQueryOp.Contains, { value: filter.AnimalIdentifier }));
        } else if (filter.AnimalIdentifiers &&
            filter.AnimalIdentifiers.length > 0 &&
            filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', 'in', filter.AnimalIdentifiers));
        }

        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('AnimalName', 'in', animalNames));
        }

        if (filter.AnimalStatuses &&
            filter.AnimalStatuses.length > 0 &&
            filterVisibility.animalStatusesVisible) {
            predicates.push(Predicate.create('AnimalStatus', 'in', filter.AnimalStatuses));
        }

        return predicates;
    }

    private buildLinePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = predicates.concat(this.baseLinePredicates(filter, filterVisibility));

        if (filter.LineShortNames &&
            filter.LineShortNames.length > 0 &&
            filterVisibility.lineShortNamesVisible) {
            const stockNumbers = filter.LineShortNames.map((x: any) => x.StockNumber);
            predicates.push(Predicate.create('StockNumber', 'in', stockNumbers));
        }

        if (filter.Taxons && filter.Taxons.length > 0 && filterVisibility.taxonsVisible) {
            predicates.push(Predicate.create('CommonName', 'in', filter.Taxons));
        }

        return predicates;
    }

    private buildStudyPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Studies && filter.Studies.length > 0 && filterVisibility.studiesVisible) {
            const studyNames = filter.Studies.map((x: any) => x.StudyName);
            predicates.push(Predicate.create('StudyName', 'in', studyNames));
        }
        if (filter.StudyStatuses &&
            filter.StudyStatuses.length > 0 &&
            filterVisibility.studyStatusesVisible) {
            predicates.push(Predicate.create('StudyStatus', 'in', filter.StudyStatuses));
        }
        if (filter.StudyTypes &&
            filter.StudyTypes.length > 0 &&
            filterVisibility.studyTypesVisible) {
            predicates.push(Predicate.create('StudyType', 'in', filter.StudyTypes));
        }
        if (filter.ApprovalNumbers &&
            filter.ApprovalNumbers.length > 0 &&
            filterVisibility.approvalNumbersVisible) {
            predicates.push(Predicate.create('ApprovalNumber', 'in', filter.ApprovalNumbers));
        }

        return predicates;
    }

    private buildStudyLinePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Studies && filter.Studies.length > 0 && filterVisibility.studiesVisible) {
            const studyNames = filter.Studies.map((x: any) => x.StudyName);
            predicates.push(Predicate.create('StudyName', 'in', studyNames));
        }

        if (filter.Lines && filter.Lines.length > 0 && filterVisibility.linesVisible) {
            const linesNames = filter.Lines.map((x: any) => x.LineName);
            predicates.push(Predicate.create('LineName', 'in', linesNames));
        }

        return predicates;
    }

    private buildBirthPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Births && filter.Births.length > 0 && filterVisibility.birthsVisible) {
            const ids = filter.Births.map((x: any) => x.BirthID);
            predicates.push(Predicate.create('BirthID', 'in', ids));
        }

        if (filter.Matings && filter.Matings.length > 0 && filterVisibility.matingsVisible) {
            const ids = filter.Matings.map((x: any) => x.MatingID);
            predicates.push(Predicate.create('MatingID', 'in', ids));
        }

        if (filter.Housings && filter.Housings.length > 0 && filterVisibility.housingsVisible) {
            const ids = filter.Housings.map((x: any) => x.MaterialPoolID);
            predicates.push(Predicate.create('HousingID', 'in', ids));
        }

        if (filter.BirthStatuses &&
            filter.BirthStatuses.length > 0 &&
            filterVisibility.birthStatusesVisible) {
            predicates.push(Predicate.create('BirthStatus', 'in', filter.BirthStatuses));
        }

        return predicates;
    }

    private buildLocationPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Locations && filter.Locations.length > 0 && filterVisibility.locationsVisible) {
            const names = filter.Locations.map((x: any) => x.PositionName);
            predicates.push(Predicate.create('Name', 'in', names));
        }

        if (filter.ParentLocations &&
            filter.ParentLocations.length > 0 &&
            filterVisibility.parentLocationsVisible) {
            const names = filter.ParentLocations.map((x: any) => x.PositionName);
            predicates.push(Predicate.create('ParentLocation', 'in', names));
        }

        if (filter.LocationTypes &&
            filter.LocationTypes.length > 0 &&
            filterVisibility.locationTypesVisible) {
            predicates.push(Predicate.create('LocationType', 'in', filter.LocationTypes));
        }

        return predicates;
    }

    private buildAnimalClinicalObservationPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.AnimalIdentifier && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', FilterQueryOp.Contains, { value: filter.AnimalIdentifier }));
        } else if (filter.AnimalIdentifiers &&
            filter.AnimalIdentifiers.length > 0 &&
            filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', 'in', filter.AnimalIdentifiers));
        }
        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('AnimalName', 'in', animalNames));
        }
        if (filter.ObservedBy && filter.ObservedBy.length > 0 && filterVisibility.observedByVisible) {
            predicates.push(Predicate.create('ResourceName', 'in', filter.ObservedBy));
        }
        if (filter.ObservationStatuses && filter.ObservationStatuses.length > 0 && filterVisibility.observationStatusesVisible) {
            predicates.push(Predicate.create('ClinicalObservationStatus', 'in', filter.ObservationStatuses));
        }
        return predicates;
    }

    private buildAnimalHealthRecordPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.AnimalIdentifier && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', FilterQueryOp.Contains, { value: filter.AnimalIdentifier }));
        } else if (filter.AnimalIdentifiers &&
            filter.AnimalIdentifiers.length > 0 &&
            filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', 'in', filter.AnimalIdentifiers));
        }

        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('AnimalName', 'in', animalNames));
        }

        return predicates;
    }

    private buildClinicalObservationDetailPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.UrgentClinicalObservations && filter.UrgentClinicalObservations.length > 0 && filterVisibility.urgentClinicalObservationVisible) {
            predicates.push(Predicate.create('Urgent', 'in', filter.UrgentClinicalObservations));
        }

        if (filter.AnimalIdentifier && filter.AnimalIdentifier.length > 0 && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', FilterQueryOp.Contains, { value: filter.AnimalIdentifier }));
        } else if (filter.AnimalIdentifiers && filter.AnimalIdentifiers.length > 0 && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', 'in', filter.AnimalIdentifiers));
        }

        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('AnimalName', 'in', animalNames));
        }

        if ((filter.ObservedDateStart || filter.ObservedDateEnd) && filterVisibility.observedDateRangeVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'DateObserved',
                filter.ObservedDateStart,
                filter.ObservedDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.ObservedBy && filter.ObservedBy.length > 0 && filterVisibility.observedByVisible) {
            predicates.push(Predicate.create('ResourceName', 'in', filter.ObservedBy));
        }
        if (filter.ObservationStatuses && filter.ObservationStatuses.length > 0 && filterVisibility.observationStatusesVisible) {
            predicates.push(Predicate.create('ObservationStatus', 'in', filter.ObservationStatuses));
        }

        if (filter.Observations && filter.Observations.length > 0 && filterVisibility.observationsVisible) {
            predicates.push(Predicate.create('ClinicalObservation', 'in', filter.Observations));
        }

        if (filter.ExperimentalClinicalObservations && filter.ExperimentalClinicalObservations.length > 0 && filterVisibility.experimentalClinicalObservationVisible) {
            predicates.push(Predicate.create('Experimental', 'in', filter.ExperimentalClinicalObservations));
        }

        return predicates;
    }


    private buildObservationDetailPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.UrgentClinicalObservations && filter.UrgentClinicalObservations.length > 0 && filterVisibility.urgentClinicalObservationVisible) {
            predicates.push(Predicate.create('Urgent', 'in', filter.UrgentClinicalObservations));
        }

        if (filter.AnimalIdentifier && filter.AnimalIdentifier.length > 0 && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', FilterQueryOp.Contains, { value: filter.AnimalIdentifier }));
        } else if (filter.AnimalIdentifiers && filter.AnimalIdentifiers.length > 0 && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', 'in', filter.AnimalIdentifiers));
        }

        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('AnimalName', 'in', animalNames));
        }

        if ((filter.ObservedDateStart || filter.ObservedDateEnd) && filterVisibility.observedDateRangeVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'DateObserved',
                filter.ObservedDateStart,
                filter.ObservedDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.ObservedBy && filter.ObservedBy.length > 0 && filterVisibility.observedByVisible) {
            predicates.push(Predicate.create('ResourceName', 'in', filter.ObservedBy));
        }
        if (filter.ObservedByUsername && filter.ObservedByUsername.length > 0 && filterVisibility.observedByUsernameVisible) {
            predicates.push(Predicate.create('ObservedByUsername', 'eq', filter.ObservedByUsername));
        }
        if (filter.ObservationStatuses && filter.ObservationStatuses.length > 0 && filterVisibility.observationStatusesVisible) {
            predicates.push(Predicate.create('ObservationStatus', 'in', filter.ObservationStatuses));
        }

        if (filter.ObservationSearch && filter.ObservationSearch.length > 0 && filterVisibility.observationSearchVisible) {
            predicates.push(Predicate.create('tolower(Observation)', FilterQueryOp.Contains, { value: filter.ObservationSearch.toLowerCase() }));
        }

        if (filter.ExperimentalClinicalObservations && filter.ExperimentalClinicalObservations.length > 0 && filterVisibility.experimentalClinicalObservationVisible) {
            predicates.push(Predicate.create('Experimental', 'in', filter.ExperimentalClinicalObservations));
        }

        if (filter.ObservationType && filter.ObservationType.length > 0 && filterVisibility.observationTypeVisible) {
            predicates.push(Predicate.create('ObservationType', 'in', filter.ObservationType));
        }

        return predicates;
    }

    private buildTaskAnimalHealthRecordPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.AnimalName && filterVisibility.animalNameVisible) {
            predicates.push(Predicate.create('tolower(AnimalName)', FilterQueryOp.Contains, { value: filter.AnimalName.toLowerCase() }));
        }

        if (filter.AnimalIdentifier && filterVisibility.animalIdentifierVisible) {
            predicates.push(Predicate.create('tolower(AnimalIdentifier)', FilterQueryOp.Contains, { value: filter.AnimalIdentifier.toLowerCase() }));
        }

        if (filter.AssignedTo && filterVisibility.assignedToVisible) {
            predicates.push(Predicate.create('tolower(AssignedTo)', FilterQueryOp.Contains, { value: filter.AssignedTo.toLowerCase() }));
        }

        if (filter.Completed && filter.Completed.length > 0 && filterVisibility.completedVisible) {
            predicates.push(Predicate.create('Completed', 'in', filter.Completed));
        }

        if (filter.CompletedBy && filterVisibility.completedByVisible) {
            predicates.push(Predicate.create('tolower(CompletedBy)', FilterQueryOp.Contains, { value: filter.CompletedBy.toLowerCase() }));
        }

        if (filter.TaskStatuses && filter.TaskStatuses.length > 0 && filterVisibility.taskStatusesVisible) {
            predicates.push(Predicate.create('Status', 'in', filter.TaskStatuses));
        }

        return predicates;
    }

    private async buildWorkflowTaskPredicates(filter: any, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<Predicate[]> {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.WorkflowTaskKeys &&
            filter.WorkflowTaskKeys.length > 0 &&
            filterVisibility.workflowTaskKeysVisible) {
            if (isCRO) {
                const parentAndchildTasks = await this.getChildWorkflowTasks(filter.WorkflowTaskKeys);
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', parentAndchildTasks));
            } else {
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', filter.WorkflowTaskKeys));
            }
        }

        if (filter.TaskTypes && filter.TaskTypes.length > 0 && filterVisibility.taskTypesVisible) {
            predicates.push(Predicate.create('TaskType', 'in', filter.TaskTypes));
        }


        if (filter.ActiveTasks && filter.ActiveTasks.length > 0 && filterVisibility.activeTaskVisible) {
            predicates.push(Predicate.create('IsActive', 'in', filter.ActiveTasks));
        }

        return predicates;
    }

    private async buildTaskAssignmentPredicates(filter: any, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<Predicate[]> {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.TaskTypes && filter.TaskTypes.length > 0 && filterVisibility.taskTypesVisible) {
            predicates.push(Predicate.create('TaskType', 'in', filter.TaskTypes));
        }

        if (filter.WorkflowTaskKeys &&
            filter.WorkflowTaskKeys.length > 0 &&
            filterVisibility.workflowTaskKeysVisible) {
            if (isCRO) {
                const parentAndchildTasks = await this.getChildWorkflowTasks(filter.WorkflowTaskKeys);
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', parentAndchildTasks));
            } else {
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', filter.WorkflowTaskKeys));
            }
        }

        if (filter.TaskNames && filter.TaskNames.length > 0 && filterVisibility.taskNamesVisible) {
            predicates.push(Predicate.create('TaskName', 'in', filter.TaskNames));
        }

        if (filter.Jobs && filter.Jobs.length && filterVisibility.jobsVisible) {
            const jobNames = filter.Jobs.map((x: any) => x.JobID);
            predicates.push(Predicate.create('JobName', 'in', jobNames));
        }

        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('AnimalName', 'in', animalNames));
        }

        if (filter.SampleIdentifier && filterVisibility.sampleIdentifiersVisible) {
            predicates.push(Predicate.create('tolower(SampleID)', FilterQueryOp.Contains, { value: filter.SampleIdentifier }));
        } else if (filter.SampleIdentifiers &&
            filter.SampleIdentifiers.length > 0 &&
            filterVisibility.sampleIdentifiersVisible) {
            predicates.push(Predicate.create('SampleID', 'in', filter.SampleIdentifiers));
        }

        if (filter.AssignedTo && filterVisibility.assignedToVisible) {
            predicates.push(Predicate.create('tolower(AssignedTo)', FilterQueryOp.Contains, { value: filter.AssignedTo.toLowerCase() }));
        }

        return predicates;
    }

    private async buildOutputFlagPredicates(filter: any, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<Predicate[]> {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.TaskTypes && filter.TaskTypes.length > 0 && filterVisibility.taskTypesVisible) {
            predicates.push(Predicate.create('TaskType', 'in', filter.TaskTypes));
        }

        if (filter.WorkflowTaskKeys &&
            filter.WorkflowTaskKeys.length > 0 &&
            filterVisibility.workflowTaskKeysVisible) {
            if (isCRO) {
                const parentAndchildTasks = await this.getChildWorkflowTasks(filter.WorkflowTaskKeys);
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', parentAndchildTasks));
            } else {
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', filter.WorkflowTaskKeys));
            }
        }

        if (filter.OutputNames && filter.OutputNames.length > 0 && filterVisibility.outputNamesVisible) {
            predicates.push(Predicate.create('tolower(OutputName)', FilterQueryOp.Contains, { value: filter.OutputNames.toLowerCase() }));
        }

        if (filter.FlagMessages && filterVisibility.flagMessagesVisible) {
            predicates.push(Predicate.create('tolower(FlagMessage)', FilterQueryOp.Contains, { value: filter.FlagMessages.toLowerCase() }));
        }

        return predicates;
    }

    private buildWorkflowTaskDetailPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.TaskNames && filter.TaskNames.length > 0 && filterVisibility.taskNamesVisible) {
            predicates.push(Predicate.create('TaskName', 'in', filter.TaskNames));
        }
        if (filter.Collected && filter.Collected.length > 0 && filterVisibility.collectedVisible) {
            predicates.push(Predicate.create('Collected', 'in', filter.Collected));
        }
        if (filter.Completed && filter.Completed.length > 0 && filterVisibility.completedVisible) {
            predicates.push(Predicate.create('Completed', 'in', filter.Completed));
        }
        if (filter.Reviewed && filter.Reviewed.length > 0 && filterVisibility.reviewedVisible) {
            predicates.push(Predicate.create('Reviewed', 'in', filter.Reviewed));
        }
        return predicates;
    }

    private buildOrderPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.OrderId && filterVisibility.orderIdVisible) {
            predicates.push(Predicate.create('tolower(OrderID)', FilterQueryOp.Contains, { value: filter.OrderId.toLowerCase() }));
        }

        if (filter.OrderTypes && filter.OrderTypes.length > 0 && filterVisibility.orderTypesVisible) {
            predicates.push(Predicate.create('OrderType', 'in', filter.OrderTypes));
        }

        if (filter.Jobs && filter.Jobs.length > 0 && filterVisibility.jobsVisible) {
            const jobsNumbers = filter.Jobs.map((x: any) => x.JobID);
            predicates.push(Predicate.create('JobName', 'in', jobsNumbers));
        }

        return predicates;
    }

    private buildOrderAnimalPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.OrderIdOrdersAnimals && filterVisibility.orderIdOrdersAnimalsVisible) {
            predicates.push(Predicate.create('tolower(OrderID)', FilterQueryOp.Contains, { value: filter.OrderIdOrdersAnimals.toLowerCase() }));
        }

        return predicates;
    }

    private buildOrderLocationPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.OrderIdOrdersLocations && filterVisibility.orderIdOrdersLocationsVisible) {
            predicates.push(Predicate.create('tolower(OrderID)', FilterQueryOp.Contains, { value: filter.OrderIdOrdersLocations.toLowerCase() }));
        }

        return predicates;
    }

    private buildFilePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.facets && filter.facets.length > 0 && filterVisibility.facetsVisible) {
            predicates.push(Predicate.create('FileFacet', 'in', filter.facets));
        }

        if (filter.FileName && filterVisibility.filesNameVisible) {
            predicates.push(Predicate.create('tolower(FileName)', FilterQueryOp.Contains, { value: filter.FileName.toLowerCase() }));
        }

        if (filter.ConstructIdentifier && filterVisibility.constructIdentifierVisible) {
            predicates.push(Predicate.create('ConstructID', FilterQueryOp.Contains, { value: filter.ConstructIdentifier }));
        }

        if (filter.AnimalIdentifier && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', FilterQueryOp.Contains, { value: filter.AnimalIdentifier }));
        } else if (filter.AnimalIdentifiers &&
            filter.AnimalIdentifiers.length > 0 &&
            filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', 'in', filter.AnimalIdentifiers));
        }

        if (filter.TaskNames && filter.TaskNames.length > 0) {
            predicates.push(Predicate.create('TaskName', 'in', filter.TaskNames));
        }

        if (filter.Protocols && filter.Protocols.length > 0 && filterVisibility.protocolsVisible) {
            const names = filter.Protocols.map((x: any) => x.ProtocolName);
            predicates.push(Predicate.create('ProtocolName', 'in', names));
        }

        if (filter.SampleIdentifier && filterVisibility.sampleIdentifiersVisible) {
            predicates.push(Predicate.create('SampleID', FilterQueryOp.Contains, { value: filter.SampleIdentifier }));
        } else if (filter.SampleIdentifiers &&
            filter.SampleIdentifiers.length > 0 &&
            filterVisibility.sampleIdentifiersVisible) {
            predicates.push(Predicate.create('SampleID', 'in', filter.SampleIdentifiers));
        }

        if (filter.Studies && filter.Studies.length > 0 && filterVisibility.studiesVisible) {
            const studyNames = filter.Studies.map((x: any) => x.StudyName);
            predicates.push(Predicate.create('StudyName', 'in', studyNames));
        }

        if (filter.Lines && filter.Lines.length > 0 && filterVisibility.linesVisible) {
            const linesNames = filter.Lines.map((x: any) => x.LineName);
            predicates.push(Predicate.create('LineName', 'in', linesNames));
        }

        if (filter.OrderId && filterVisibility.orderIdVisible) {
            predicates.push(Predicate.create('tolower(OrderID)', FilterQueryOp.Contains, { value: filter.OrderId.toLowerCase() }));
        }

        if (filter.Jobs && filter.Jobs.length && filterVisibility.jobsVisible) {
            const jobIds = filter.Jobs.map((x: any) => x.JobID);
            predicates.push(Predicate.create('JobID', 'in', jobIds));
        }

        return predicates;
    }

    private buildProtocolPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = predicates.concat(this.baseProtocolPredicates(filter, filterVisibility));

        return predicates;
    }

    private async buildProtocolDefaultInputPredicates(filter: any, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<Predicate[]> {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Protocols && filter.Protocols.length > 0 && filterVisibility.protocolsVisible) {
            const names = filter.Protocols.map((x: any) => x.ProtocolName);
            predicates.push(Predicate.create('Protocol', 'in', names));
        }

        if (filter.WorkflowTaskKeys &&
            filter.WorkflowTaskKeys.length > 0 &&
            filterVisibility.workflowTaskKeysVisible) {
            if (isCRO) {
                const parentAndchildTasks = await this.getChildWorkflowTasks(filter.WorkflowTaskKeys);
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', parentAndchildTasks));
            } else {
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', filter.WorkflowTaskKeys));
            }
        }

        if (filter.ProtocolTaskNames && filter.ProtocolTaskNames.length > 0 && filterVisibility.protocolTaskNamesVisible) {
            predicates.push(Predicate.create('TaskName', 'in', filter.ProtocolTaskNames));
        }

        if (filter.InputName && filterVisibility.inputNameVisible) {
            predicates.push(Predicate.create('tolower(Input)', FilterQueryOp.Contains, { value: filter.InputName.toLowerCase() }));
        }

        if (filter.DefaultValue && filterVisibility.defaultValueVisible) {
            predicates.push(Predicate.create('tolower(Default)', FilterQueryOp.Contains, { value: filter.DefaultValue.toLowerCase() }));
        }

        return predicates;
    }

    private async buildProtocolTaskPredicates(filter: any, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<Predicate[]> {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Protocols && filter.Protocols.length > 0 && filterVisibility.protocolsVisible) {
            const names = filter.Protocols.map((x: any) => x.ProtocolName);
            predicates.push(Predicate.create('ProtocolName', 'in', names));
        }

        if (filter.WorkflowTaskKeys &&
            filter.WorkflowTaskKeys.length > 0 &&
            filterVisibility.workflowTaskKeysVisible) {
            if (isCRO) {
                const parentAndchildTasks = await this.getChildWorkflowTasks(filter.WorkflowTaskKeys);
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', parentAndchildTasks));
            } else {
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', filter.WorkflowTaskKeys));
            }
        }

        return predicates;
    }

    private async buildProtocolSampleGroupPredicates(filter: any, isCRO: boolean, filterVisibility: AuditFilterVisibility): Promise<Predicate[]> {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Protocols && filter.Protocols.length > 0 && filterVisibility.protocolsVisible) {
            const names = filter.Protocols.map((x: any) => x.ProtocolName);
            predicates.push(Predicate.create('ProtocolName', 'in', names));
        }

        if (filter.WorkflowTaskKeys &&
            filter.WorkflowTaskKeys.length > 0 &&
            filterVisibility.workflowTaskKeysVisible) {
            if (isCRO) {
                const parentAndchildTasks = await this.getChildWorkflowTasks(filter.WorkflowTaskKeys);
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', parentAndchildTasks));
            } else {
                predicates.push(Predicate.create('C_WorkflowTask_key', 'in', filter.WorkflowTaskKeys));
            }
        }

        return predicates;
    }

    private buildResourcePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.ResourceName && filterVisibility.resourceNameVisible) {
            predicates.push(Predicate.create('tolower(ResourceName)', FilterQueryOp.Contains, { value: filter.ResourceName.toLowerCase() }));
        }

        if (filter.ActiveResources && filter.ActiveResources.length > 0 && filterVisibility.activeResourceVisible) {
            predicates.push(Predicate.create('IsActive', 'in', filter.ActiveResources));
        }

        if (filter.EmailAddress && filterVisibility.emailAddressVisible) {
            predicates.push(Predicate.create('tolower(EmailAddress)', FilterQueryOp.Contains, { value: filter.EmailAddress.toLowerCase() }));
        }

        if (filter.ResourceTypes && filter.ResourceTypes.length > 0 && filterVisibility.resourceTypesVisible) {
            predicates.push(Predicate.create('ResourceType', 'in', filter.ResourceTypes));
        }

        if (filter.Usernames && filter.Usernames.length > 0 && filterVisibility.usernamesVisible) {
            const userNames = filter.Usernames.map((x: any) => x.User.UserName);
            predicates.push(Predicate.create('UserName', 'in', userNames));
        }

        if (filter.ResourceGroups && filter.ResourceGroups.length > 0 && filterVisibility.resourceGroupsVisible) {
            predicates.push(Predicate.create('ResourceGroup', 'in', filter.ResourceGroups));
        }

        return predicates;
    }

    private buildStudyAdministratorStudyPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Users && filter.Users.length > 0 && filterVisibility.usersVisible) {
            const userKeys = filter.Users.map((x: any) => x.User.Id);
            predicates.push(Predicate.create('C_User_key', 'in', userKeys));
        }

        if (filter.Studies && filter.Studies.length > 0 && filterVisibility.studiesVisible) {
            const studies = filter.Studies.map((x: any) => x.StudyName);
            predicates.push(Predicate.create('StudyName', 'in', studies));
        }

        return predicates;
    }

    private buildUserPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates.push(this.getAuditAdminPredicate());

        if (filter.UserName && filterVisibility.userNameVisible) {
            predicates.push(Predicate.create('tolower(UserName)', FilterQueryOp.Contains, { value: filter.UserName.toLowerCase() }));
        }

        if (filter.FirstName && filterVisibility.firstNameVisible) {
            predicates.push(Predicate.create('tolower(FirstName)', FilterQueryOp.Contains, { value: filter.FirstName.toLowerCase() }));
        }

        if (filter.LastName && filterVisibility.lastNameVisible) {
            predicates.push(Predicate.create('tolower(LastName)', FilterQueryOp.Contains, { value: filter.LastName.toLowerCase() }));
        }

        if (filter.RoleName && filterVisibility.roleNameVisible) {
            predicates.push(Predicate.create('tolower(RoleName)', FilterQueryOp.Contains, { value: filter.RoleName.toLowerCase() }));
        }

        if ((filter.IsStudyAdministrator === true || filter.IsStudyAdministrator === false) && filterVisibility.isStudyAdministratorVisible) {
            predicates.push(Predicate.create('IsStudyAdministrator', 'eq', filter.IsStudyAdministrator));
        }

        return predicates;
    }

    private buildUserRolePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates.push(this.getAuditAdminPredicate());

        if (filter.RoleName && filterVisibility.roleNameVisible) {
            predicates.push(Predicate.create('tolower(RoleName)', FilterQueryOp.Contains, { value: filter.RoleName.toLowerCase() }));
        }

        return predicates;
    }

    private buildRoleFacetPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates.push(this.getAuditAdminPredicate());

        if (filter.FacetName && filterVisibility.facetNameVisible) {
            predicates.push(Predicate.create('tolower(FacetName)', FilterQueryOp.Contains, { value: filter.FacetName.toLowerCase() }));
        }

        if (filter.RoleName && filterVisibility.roleNameVisible) {
            predicates.push(Predicate.create('tolower(RoleName)', FilterQueryOp.Contains, { value: filter.RoleName.toLowerCase() }));
        }

        if ((filter.HasReadAccess === true || filter.HasReadAccess === false) && filterVisibility.hasReadAccessVisible) {
            predicates.push(Predicate.create('HasReadAccess', 'eq', filter.HasReadAccess));
        }

        if ((filter.HasWriteAccess === true || filter.HasWriteAccess === false) && filterVisibility.hasWriteAccessVisible) {
            predicates.push(Predicate.create('HasWriteAccess', 'eq', filter.HasWriteAccess));
        }

        return predicates;
    }

    private buildVocabularyTaskFlagMessagePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.TaskFlagMessage && filterVisibility.taskFlagMessageVisible) {
            predicates.push(Predicate.create('tolower(TaskFlagMessage)', FilterQueryOp.Contains, { value: filter.TaskFlagMessage.toLowerCase() }));
        }

        if (filter.ActiveTaskFlagMessages && filter.ActiveTaskFlagMessages.length > 0 && filterVisibility.activeTaskFlagMessageVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveTaskFlagMessages));
        }

        return predicates;
    }

    private buildVocabularyStandardPhrasePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.StandardPhrase && filterVisibility.standardPhraseVisible) {
            predicates.push(Predicate.create('tolower(StandardPhrase)', 'substringof', filter.StandardPhrase.toLowerCase()));
        }

        if (filter.ActiveStandardPhrases && filter.ActiveStandardPhrases.length > 0 && filterVisibility.activeStandardPhraseVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveStandardPhrases));
        }

        if (filter.StandardPhraseCategories && filter.StandardPhraseCategories.length > 0 && filterVisibility.standardPhraseCategoriesVisible) {
            predicates.push(Predicate.create('Category', 'in', filter.StandardPhraseCategories));
        }

        if (filter.StandardPhraseLinkTypes && filter.StandardPhraseLinkTypes.length > 0 && filterVisibility.standardPhraseLinkTypesVisible) {
            predicates.push(Predicate.create('StandardPhraseType', 'in', filter.StandardPhraseLinkTypes));
        }

        if (filter.JobReports && filter.JobReports.length > 0 && filterVisibility.jobReportsVisible) {
            predicates.push(Predicate.create('Report', 'in', filter.JobReports));
        }

        return predicates;
    }

    private buildVocabularyTaskStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.TaskStatus && filterVisibility.taskStatusVisible) {
            predicates.push(Predicate.create('tolower(TaskStatus)', FilterQueryOp.Contains, { value: filter.TaskStatus.toLowerCase() }));
        }

        if (filter.ActiveTaskStatus && filter.ActiveTaskStatus.length > 0 && filterVisibility.activeTaskStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveTaskStatus));
        }

        if (filter.EndStateTaskStatus && filter.EndStateTaskStatus.length > 0 && filterVisibility.endStateTaskStatusVisible) {
            predicates.push(Predicate.create('EndState', 'in', filter.EndStateTaskStatus));
        }

        if (filter.DefaultTaskStatus && filter.DefaultTaskStatus.length > 0 && filterVisibility.defaultTaskStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultTaskStatus));
        }

        if (filter.DefaultEndStateTaskStatus && filter.DefaultEndStateTaskStatus.length > 0 && filterVisibility.defaultEndStateTaskStatusVisible) {
            predicates.push(Predicate.create('DefaultEndState', 'in', filter.DefaultEndStateTaskStatus));
        }

        if (filter.DefaultAutoEndStateTaskStatus && filter.DefaultAutoEndStateTaskStatus.length > 0 && filterVisibility.defaultAutoEndStateTaskStatusVisible) {
            predicates.push(Predicate.create('DefaultAutoEndState', 'in', filter.DefaultAutoEndStateTaskStatus));
        }

        return predicates;
    }

    private buildVocabularyAnimalClassificationPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.AnimalClassification && filterVisibility.animalClassificationVisible) {
            predicates.push(Predicate.create('tolower(AnimalClassification)', FilterQueryOp.Contains, { value: filter.AnimalClassification.toLowerCase() }));
        }

        if (filter.ActiveAnimalClassifications && filter.ActiveAnimalClassifications.length > 0 && filterVisibility.activeAnimalClassificationVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveAnimalClassifications));
        }

        if (filter.DefaultAnimalClassifications && filter.DefaultAnimalClassifications.length > 0 && filterVisibility.defaultAnimalClassificationVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultAnimalClassifications));
        }

        return predicates;
    }

    private buildVocabularyAnimalCommentStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.AnimalCommentStatus && filterVisibility.animalCommentStatusVisible) {
            predicates.push(Predicate.create('tolower(AnimalCommentStatus)', FilterQueryOp.Contains, { value: filter.AnimalCommentStatus.toLowerCase() }));
        }

        if (filter.ActiveAnimalCommentStatuses && filter.ActiveAnimalCommentStatuses.length > 0 && filterVisibility.activeAnimalCommentStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveAnimalCommentStatuses));
        }

        if (filter.EndStateAnimalCommentStatuses && filter.EndStateAnimalCommentStatuses.length > 0 && filterVisibility.endStateAnimalCommentStatusVisible) {
            predicates.push(Predicate.create('EndState', 'in', filter.EndStateAnimalCommentStatuses));
        }

        if (filter.DefaultAnimalCommentStatuses && filter.DefaultAnimalCommentStatuses.length > 0 && filterVisibility.defaultAnimalCommentStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultAnimalCommentStatuses));
        }

        if (filter.PermanentAnimalCommentStatuses && filter.PermanentAnimalCommentStatuses.length > 0 && filterVisibility.permanentAnimalCommentStatusVisible) {
            predicates.push(Predicate.create('Permanent', 'in', filter.PermanentAnimalCommentStatuses));
        }

        return predicates;
    }

    private buildVocabularyAnimalStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.AnimalStatus && filterVisibility.animalStatusVisible) {
            predicates.push(Predicate.create('tolower(AnimalStatus)', FilterQueryOp.Contains, { value: filter.AnimalStatus.toLowerCase() }));
        }
        if (filter.ActiveAnimalStatuses && filter.ActiveAnimalStatuses.length > 0 && filterVisibility.activeAnimalStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveAnimalStatuses));
        }
        if (filter.EndStateAnimalStatuses && filter.EndStateAnimalStatuses.length > 0 && filterVisibility.endStateAnimalStatusVisible) {
            predicates.push(Predicate.create('EndState', 'in', filter.EndStateAnimalStatuses));
        }
        if (filter.DefaultAnimalStatuses && filter.DefaultAnimalStatuses.length > 0 && filterVisibility.defaultAnimalStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultAnimalStatuses));
        }
        if (filter.DefaultClinicalStatusAnimalStatuses && filter.DefaultClinicalStatusAnimalStatuses.length > 0 && filterVisibility.defaultClinicalStatusAnimalStatusVisible) {
            predicates.push(Predicate.create('DefaultClinicalStatus', 'in', filter.DefaultClinicalStatusAnimalStatuses));
        }
        return predicates;
    }

    private buildVocabularyMatingStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.MatingStatus && filterVisibility.matingStatusVisible) {
            predicates.push(Predicate.create('tolower(MatingStatus)', FilterQueryOp.Contains, { value: filter.MatingStatus.toLowerCase() }));
        }
        if (filter.ActiveMatingStatuses && filter.ActiveMatingStatuses.length > 0 && filterVisibility.activeMatingStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveMatingStatuses));
        }
        if (filter.EndStateMatingStatuses && filter.EndStateMatingStatuses.length > 0 && filterVisibility.endStateMatingStatusVisible) {
            predicates.push(Predicate.create('EndState', 'in', filter.EndStateMatingStatuses));
        }
        if (filter.DefaultMatingStatuses && filter.DefaultMatingStatuses.length > 0 && filterVisibility.defaultMatingStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultMatingStatuses));
        }
        return predicates;
    }

    
    private buildVocabularyPermitStatusesPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.PermitStatus && filterVisibility.permitStatusVisible) {
            predicates.push(Predicate.create('tolower(PermitStatus)', FilterQueryOp.Contains, { value: filter.PermitStatus.toLowerCase() }));
        }
        if (filter.ActivePermitStatuses && filter.ActivePermitStatuses.length > 0 && filterVisibility.activePermitStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActivePermitStatuses));
        }
        if (filter.DefaultPermitStatuses && filter.DefaultPermitStatuses.length > 0 && filterVisibility.defaultPermitStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultPermitStatuses));
        }
        return predicates;
    }

    private buildVocabularySeveritiesPredicates(filter: Record<string, any>, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Severity && filterVisibility.severityVisible) {
            predicates.push(Predicate.create('tolower(Severity)', FilterQueryOp.Contains, { value: filter.Severity.toLowerCase() }));
        }
        if (filter.ActiveSeverityStatuses && filter.ActiveSeverityStatuses.length > 0 && filterVisibility.activeSeverityStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveSeverityStatuses));
        }
        if (filter.DefaultSeverityStatuses && filter.DefaultSeverityStatuses.length > 0 && filterVisibility.defaultSeverityStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultSeverityStatuses));
        }
        return predicates;
    }

    private buildVocabularyPermitExternalPurposesPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.PermitExternalPurposes && filterVisibility.permitExternalPurposeVisible) {
            predicates.push(Predicate.create('tolower(PermitExternalPurpose)', FilterQueryOp.Contains, { value: filter.PermitExternalPurposes.toLowerCase() }));
        }
        if (filter.ActivePermitExternalPurposeStatuses && filter.ActivePermitExternalPurposeStatuses.length > 0 && filterVisibility.activePermitExternalPurposeStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActivePermitExternalPurposeStatuses));
        }
        if (filter.DefaultPermitExternalPurposeStatuses && filter.DefaultPermitExternalPurposeStatuses.length > 0 && filterVisibility.defaultPermitExternalPurposeStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultPermitExternalPurposeStatuses));
        }
        return predicates;
    }

    private buildVocabularyPermitCategoriesPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {        
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);        
        if (filter.PermitCategory && filterVisibility.permitCategoryVisible) {
            predicates.push(Predicate.create('tolower(PermitCategory)', FilterQueryOp.Contains, { value: filter.PermitCategory.toLowerCase() }));
        }
        if (filter.ActivePermitCategoryStatuses && filter.ActivePermitCategoryStatuses.length > 0 && filterVisibility.activePermitCategoryStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActivePermitCategoryStatuses));
        }
        if (filter.DefaultPermitCategoryStatuses && filter.DefaultPermitCategoryStatuses.length > 0 && filterVisibility.defaultPermitCategoryStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultPermitCategoryStatuses));
        }
        return predicates;
    }

    private buildCharacteristicAnimalsPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.AnimalCharacteristicName && filterVisibility.animalCharacteristicNameVisible) {
            predicates.push(Predicate.create('tolower(AnimalCharacteristicName)', FilterQueryOp.Contains, { value: filter.AnimalCharacteristicName.toLowerCase() }));
        }

        if (filter.ActiveAnimalCharacteristics && filter.ActiveAnimalCharacteristics.length > 0 && filterVisibility.activeAnimalCharacteristicVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveAnimalCharacteristics));
        }

        if (filter.SpeciesAnimalCharacteristics && filter.SpeciesAnimalCharacteristics.length > 0 && filterVisibility.speciesAnimalCharacteristicVisible) {
            const orPredicate: Predicate[] = [];
            filter.SpeciesAnimalCharacteristics.forEach((item: any) => {              
                orPredicate.push(Predicate.create('tolower(Species)', FilterQueryOp.Contains, { value: item.toLowerCase() }));                
            });
            predicates.push(Predicate.or(orPredicate));           
        }

        if ((this.isDate(filter.LastReviewedAnimalCharacteristicDateStart) || this.isDate(filter.LastReviewedAnimalCharacteristicDateEnd))
            && filterVisibility.lastReviewedAnimalCharacteristicVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'LastReviewed',
                filter.LastReviewedAnimalCharacteristicDateStart,
                filter.LastReviewedAnimalCharacteristicDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        return predicates;
    }

    private buildVocabularyIACUCProtocolPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.IACUCProtocol && filterVisibility.iacucProtocolVisible) {
            predicates.push(Predicate.create('tolower(IACUCProtocol)', FilterQueryOp.Contains, { value: filter.IACUCProtocol.toLowerCase() }));
        }
        if (filter.ActiveIACUCProtocols && filter.ActiveIACUCProtocols.length > 0 && filterVisibility.activeIACUCProtocolVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveIACUCProtocols));
        }
        if (filter.DefaultIACUCProtocols && filter.DefaultIACUCProtocols.length > 0 && filterVisibility.defaultIACUCProtocolVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultIACUCProtocols));
        }
        return predicates;
    }

    private buildVocabularyInstitutionTypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.InstitutionType && filterVisibility.institutionTypeVisible) {
            predicates.push(Predicate.create('tolower(InstitutionType)', FilterQueryOp.Contains, { value: filter.InstitutionType.toLowerCase() }));
        }
        if (filter.ActiveInstitutionTypes && filter.ActiveInstitutionTypes.length > 0 && filterVisibility.activeInstitutionTypeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveInstitutionTypes));
        }
        if (filter.DefaultInstitutionTypes && filter.DefaultInstitutionTypes.length > 0 && filterVisibility.defaultInstitutionTypeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultInstitutionTypes));
        }
        return predicates;
    }

    private buildVocabularyPaymentTermPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.PaymentTerm && filterVisibility.paymentTermVisible) {
            predicates.push(Predicate.create('tolower(PaymentTerm)', FilterQueryOp.Contains, { value: filter.PaymentTerm.toLowerCase() }));
        }
        if (filter.ActivePaymentTerms && filter.ActivePaymentTerms.length > 0 && filterVisibility.activePaymentTermVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActivePaymentTerms));
        }
        if (filter.DefaultPaymentTerms && filter.DefaultPaymentTerms.length > 0 && filterVisibility.defaultPaymentTermVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultPaymentTerms));
        }
        return predicates;
    }

    private buildVocabularyMatingTypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.MatingType && filterVisibility.matingTypeVisible) {

            predicates.push(Predicate.create('tolower(MatingType)', FilterQueryOp.Contains, { value: filter.MatingType.toLowerCase() }));
        }
        if (filter.ActiveMatingTypes && filter.ActiveMatingTypes.length > 0 && filterVisibility.activeMatingTypeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveMatingTypes));
        }
        if (filter.DefaultMatingTypes && filter.DefaultMatingTypes.length > 0 && filterVisibility.defaultMatingTypeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultMatingTypes));
        }
        return predicates;
    }

    private buildVocabularyCompatibilityAccessLevelPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.CompatibilityAccessLevel && filterVisibility.compatibilityAccessLevelVisible) {
            predicates.push(Predicate.create('tolower(CompatibilityAccessLevel)', FilterQueryOp.Contains, { value: filter.CompatibilityAccessLevel.toLowerCase() }));
        }
        if (filter.ActiveCompatibilityAccessLevels && filter.ActiveCompatibilityAccessLevels.length > 0 && filterVisibility.activeCompatibilityAccessLevelVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveCompatibilityAccessLevels));
        }
        if (filter.DefaultCompatibilityAccessLevels && filter.DefaultCompatibilityAccessLevels.length > 0 && filterVisibility.defaultCompatibilityAccessLevelVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultCompatibilityAccessLevels));
        }

        return predicates;
    }

    private buildVocabularyModifier1Predicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Modifier1 && filterVisibility.modifier1Visible) {
            predicates.push(Predicate.create('tolower(Value)', FilterQueryOp.Contains, { value: filter.Modifier1.toLowerCase() }));
        }
        if (filter.ActiveModifiers1 && filter.ActiveModifiers1.length > 0 && filterVisibility.activeModifier1Visible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveModifiers1));
        }

        return predicates;
    }

    private buildVocabularyModifier2Predicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.Modifier2 && filterVisibility.modifier2Visible) {
            predicates.push(Predicate.create('tolower(Value)', FilterQueryOp.Contains, { value: filter.Modifier2.toLowerCase() }));
        }
        if (filter.ActiveModifiers2 && filter.ActiveModifiers2.length > 0 && filterVisibility.activeModifier2Visible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveModifiers2));
        }

        return predicates;
    }

    private buildVocabularyModifier3Predicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.Modifier3 && filterVisibility.modifier3Visible) {
            predicates.push(Predicate.create('tolower(Value)', FilterQueryOp.Contains, { value: filter.Modifier3.toLowerCase() }));
        }
        if (filter.ActiveModifiers3 && filter.ActiveModifiers3.length > 0 && filterVisibility.activeModifier3Visible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveModifiers3));
        }

        return predicates;
    }

    private buildVocabularyModifier4Predicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.Modifier4 && filterVisibility.modifier4Visible) {
            predicates.push(Predicate.create('tolower(Value)', FilterQueryOp.Contains, { value: filter.Modifier4.toLowerCase() }));
        }
        if (filter.ActiveModifiers4 && filter.ActiveModifiers4.length > 0 && filterVisibility.activeModifier4Visible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveModifiers4));
        }

        return predicates;
    }

    private buildVocabularySocialGroupAccessLevelPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.SocialGroupAccessLevel && filterVisibility.socialGroupAccessLevelVisible) {
            predicates.push(Predicate.create('tolower(SocialGroupAccessLevel)', FilterQueryOp.Contains, { value: filter.SocialGroupAccessLevel.toLowerCase() }));
        }
        if (filter.ActiveSocialGroupAccessLevels && filter.ActiveSocialGroupAccessLevels.length > 0 && filterVisibility.activeSocialGroupAccessLevelVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveSocialGroupAccessLevels));
        }
        if (filter.DefaultSocialGroupAccessLevels && filter.DefaultSocialGroupAccessLevels.length > 0 && filterVisibility.defaultSocialGroupAccessLevelVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultSocialGroupAccessLevels));
        }

        return predicates;
    }

    private buildVocabularyLineStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.LineStatus && filterVisibility.lineStatusVisible) {
            predicates.push(Predicate.create('tolower(LineStatus)', FilterQueryOp.Contains, { value: filter.LineStatus.toLowerCase() }));
        }

        if (filter.ActiveLineStatuses && filter.ActiveLineStatuses.length > 0 && filterVisibility.activeLineStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveLineStatuses));
        }

        if (filter.DefaultLineStatuses && filter.DefaultLineStatuses.length > 0 && filterVisibility.defaultLineStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultLineStatuses));
        }

        return predicates;
    }

    private buildVocabularyResourceTypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.ResourceType && filterVisibility.resourceTypeVisible) {

            predicates.push(Predicate.create('tolower(ResourceType)', FilterQueryOp.Contains, { value: filter.ResourceType.toLowerCase() }));
        }
        if (filter.ActiveResourceTypes && filter.ActiveResourceTypes.length > 0 && filterVisibility.activeResourceTypeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveResourceTypes));
        }
        if (filter.DefaultResourceTypes && filter.DefaultResourceTypes.length > 0 && filterVisibility.defaultResourceTypeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultResourceTypes));
        }

        return predicates;
    }

    private buildVocabularyResourceGroupPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.ResourceGroup && filterVisibility.resourceGroupVisible) {
            predicates.push(Predicate.create('tolower(ResourceGroup)', FilterQueryOp.Contains, { value: filter.ResourceGroup.toLowerCase() }));
        }

        if (filter.ActiveResourceGroups && filter.ActiveResourceGroups.length > 0 && filterVisibility.activeResourceGroupVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveResourceGroups));
        }

        if (filter.DefaultResourceGroups && filter.DefaultResourceGroups.length > 0 && filterVisibility.defaultResourceGroupVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultResourceGroups));
        }

        return predicates;
    }

    private buildVocabularyJobStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.JobStatus && filterVisibility.jobStatusVisible) {
            predicates.push(Predicate.create('tolower(JobStatus)', FilterQueryOp.Contains, { value: filter.JobStatus.toLowerCase() }));
        }

        if (filter.ActiveJobStatuses && filter.ActiveJobStatuses.length > 0 && filterVisibility.activeJobStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveJobStatuses));
        }

        if (filter.EndStateJobStatuses && filter.EndStateJobStatuses.length > 0 && filterVisibility.endStateJobStatusVisible) {
            predicates.push(Predicate.create('EndState', 'in', filter.EndStateJobStatuses));
        }

        if (filter.DraftJobStatuses && filter.DraftJobStatuses.length > 0 && filterVisibility.draftJobStatusVisible) {
            predicates.push(Predicate.create('Draft', 'in', filter.DraftJobStatuses));
        }

        if (filter.DefaultJobStatuses && filter.DefaultJobStatuses.length > 0 && filterVisibility.defaultJobStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultJobStatuses));
        }

        return predicates;
    }

    private buildVocabularyAnimalUsePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.AnimalUse && filterVisibility.animalUseVisible) {
            predicates.push(Predicate.create('tolower(AnimalUse)', FilterQueryOp.Contains, { value: filter.AnimalUse.toLowerCase() }));
        }

        if (filter.ActiveAnimalUses && filter.ActiveAnimalUses.length > 0 && filterVisibility.activeAnimalUseVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveAnimalUses));
        }

        if (filter.DefaultAnimalUses && filter.DefaultAnimalUses.length > 0 && filterVisibility.defaultAnimalUseVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultAnimalUses));
        }

        return predicates;
    }

    private buildCharacteristicStudiesPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.StudyCharacteristicName && filterVisibility.studyCharacteristicNameVisible) {
            predicates.push(Predicate.create('tolower(StudyCharacteristicName)', FilterQueryOp.Contains, { value: filter.StudyCharacteristicName.toLowerCase() }));
        }

        if (filter.ActiveStudyCharacteristics && filter.ActiveStudyCharacteristics.length > 0 && filterVisibility.activeStudyCharacteristicVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveStudyCharacteristics));
        }

        if (filter.TypeStudyCharacteristics && filter.TypeStudyCharacteristics.length > 0 && filterVisibility.typeStudyCharacteristicVisible) {
            const orPredicate: Predicate[] = [];
            filter.TypeStudyCharacteristics.forEach((item: any) => {
                orPredicate.push(Predicate.create('tolower(StudyTypes)', FilterQueryOp.Contains, { value: item.toLowerCase() }));
            });
            predicates.push(Predicate.or(orPredicate));
        }

        if ((this.isDate(filter.LastReviewedStudyCharacteristicDateStart) || this.isDate(filter.LastReviewedStudyCharacteristicDateEnd))
            && filterVisibility.lastReviewedStudyCharacteristicVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'LastReviewed',
                filter.LastReviewedStudyCharacteristicDateStart,
                filter.LastReviewedStudyCharacteristicDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        return predicates;
    }

    private buildCharacteristicSamplesPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.SampleCharacteristicName && filterVisibility.sampleCharacteristicNameVisible) {
            predicates.push(Predicate.create('tolower(SampleCharacteristicName)', FilterQueryOp.Contains, { value: filter.SampleCharacteristicName.toLowerCase() }));
        }

        if (filter.ActiveSampleCharacteristics && filter.ActiveSampleCharacteristics.length > 0 && filterVisibility.activeSampleCharacteristicVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveSampleCharacteristics));
        }

        if (filter.TypeSampleCharacteristics && filter.TypeSampleCharacteristics.length > 0 && filterVisibility.typeSampleCharacteristicVisible) {
            const orPredicate: Predicate[] = [];
            filter.TypeSampleCharacteristics.forEach((item: any) => {
                orPredicate.push(Predicate.create('tolower(SampleTypes)', FilterQueryOp.Contains, { value: item.toLowerCase() }));
            });
            predicates.push(Predicate.or(orPredicate));
        }

        if ((this.isDate(filter.LastReviewedSampleCharacteristicDateStart) || this.isDate(filter.LastReviewedSampleCharacteristicDateEnd))
            && filterVisibility.lastReviewedSampleCharacteristicVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'LastReviewed',
                filter.LastReviewedSampleCharacteristicDateStart,
                filter.LastReviewedSampleCharacteristicDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        return predicates;
    }

    private buildVocabularyTaskOutcomePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.TaskOutcome && filterVisibility.taskOutcomeVisible) {
            predicates.push(Predicate.create('tolower(TaskOutcome)', FilterQueryOp.Contains, { value: filter.TaskOutcome.toLowerCase() }));
        }

        if (filter.ActiveTaskOutcomes && filter.ActiveTaskOutcomes.length > 0 && filterVisibility.activeTaskOutcomeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveTaskOutcomes));
        }

        if (filter.DefaultTaskOutcomes && filter.DefaultTaskOutcomes.length > 0 && filterVisibility.defaultTaskOutcomeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultTaskOutcomes));
        }

        return predicates;
    }

    private buildVocabularyPhysicalMarkerTypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.PhysicalMarkerType && filterVisibility.physicalMarkerTypeVisible) {
            predicates.push(Predicate.create('tolower(PhysicalMarkerType)', FilterQueryOp.Contains, { value: filter.PhysicalMarkerType.toLowerCase() }));
        }
        if (filter.ActivePhysicalMarkerTypes && filter.ActivePhysicalMarkerTypes.length > 0 && filterVisibility.activePhysicalMarkerTypeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActivePhysicalMarkerTypes));
        }
        if (filter.DefaultPhysicalMarkerTypes && filter.DefaultPhysicalMarkerTypes.length > 0 && filterVisibility.defaultPhysicalMarkerTypeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultPhysicalMarkerTypes));
        }

        return predicates;
    }

    private buildVocabularySampleAnalysisPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.SampleAnalysis && filterVisibility.sampleAnalysisVisible) {
            predicates.push(Predicate.create('tolower(SampleAnalysisMethod)', FilterQueryOp.Contains, { value: filter.SampleAnalysis.toLowerCase() }));
        }

        if (filter.ActiveSampleAnalyses && filter.ActiveSampleAnalyses.length > 0 && filterVisibility.activeSampleAnalysisVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveSampleAnalyses));
        }

        if (filter.DefaultSampleAnalyses && filter.DefaultSampleAnalyses.length > 0 && filterVisibility.defaultSampleAnalysisVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultSampleAnalyses));
        }

        return predicates;
    }

    private buildVocabularySampleConditionPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.SampleCondition && filterVisibility.sampleConditionVisible) {
            predicates.push(Predicate.create('tolower(SampleCondition)', FilterQueryOp.Contains, { value: filter.SampleCondition.toLowerCase() }));
        }

        if (filter.ActiveSampleConditions && filter.ActiveSampleConditions.length > 0 && filterVisibility.activeSampleConditionVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveSampleConditions));
        }

        if (filter.DefaultSampleConditions && filter.DefaultSampleConditions.length > 0 && filterVisibility.defaultSampleConditionVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultSampleConditions));
        }

        return predicates;
    }

    private buildVocabularyPlateStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.PlateStatus && filterVisibility.plateStatusVisible) {

            predicates.push(Predicate.create('tolower(PlateStatus)', FilterQueryOp.Contains, { value: filter.PlateStatus.toLowerCase() }));
        }
        if (filter.ActivePlateStatuses && filter.ActivePlateStatuses.length > 0 && filterVisibility.activePlateStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActivePlateStatuses));
        }
        if (filter.DefaultPlateStatuses && filter.DefaultPlateStatuses.length > 0 && filterVisibility.defaultPlateStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultPlateStatuses));
        }
        return predicates;
    }

    private buildVocabularyMaterialOriginPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.MaterialOrigin && filterVisibility.materialOriginVisible) {
            predicates.push(Predicate.create('tolower(MaterialOrigin)', FilterQueryOp.Contains, { value: filter.MaterialOrigin.toLowerCase() }));
        }

        if (filter.ActiveMaterialOrigins && filter.ActiveMaterialOrigins.length > 0 && filterVisibility.activeMaterialOriginVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveMaterialOrigins));
        }

        if (filter.DefaultMaterialOrigins && filter.DefaultMaterialOrigins.length > 0 && filterVisibility.defaultMaterialOriginVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultMaterialOrigins));
        }

        return predicates;
    }

    private buildVocabularyBirthStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.BirthStatus && filterVisibility.birthStatusVisible) {
            predicates.push(Predicate.create('tolower(BirthStatus)', FilterQueryOp.Contains, { value: filter.BirthStatus.toLowerCase() }));
        }

        if (filter.ActiveBirthStatuses && filter.ActiveBirthStatuses.length > 0 && filterVisibility.activeBirthStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveBirthStatuses));
        }

        if (filter.DefaultBirthStatuses && filter.DefaultBirthStatuses.length > 0 && filterVisibility.defaultBirthStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultBirthStatuses));
        }

        return predicates;
    }

    private buildVocabularyMatingPurposePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }
        const predicates: Predicate[] = this.baseAuditPredicates(filter);
        if (filter.MatingPurpose && filterVisibility.matingPurposeVisible) {
            predicates.push(Predicate.create('tolower(MatingPurpose)', FilterQueryOp.Contains, { value: filter.MatingPurpose.toLowerCase() }));
        }
        if (filter.ActiveMatingPurposes && filter.ActiveMatingPurposes.length > 0 && filterVisibility.activeMatingPurposeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveMatingPurposes));
        }
        if (filter.DefaultMatingPurposes && filter.DefaultMatingPurposes.length > 0 && filterVisibility.defaultMatingPurposeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultMatingPurposes));
        }
        return predicates;
    }

    private buildVocabularyBodyConditionScores(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.BodyConditionScore && filterVisibility.bodyConditionScoreVisible) {
            predicates.push(Predicate.create('tolower(BodyConditionScore)', FilterQueryOp.Contains, { value: filter.BodyConditionScore.toLowerCase() }));
        }

        if (filter.ActiveBodyConditionScores && filter.ActiveBodyConditionScores.length > 0 && filterVisibility.activeBodyConditionScoreVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveBodyConditionScores));
        }

        if (filter.DefaultBodyConditionScores && filter.DefaultBodyConditionScores.length > 0 && filterVisibility.defaultBodyConditionScoreVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultBodyConditionScores));
        }

        return predicates;
    }

    private buildVocabularyBreedingStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.BreedingStatus && filterVisibility.breedingStatusVisible) {
            predicates.push(Predicate.create('tolower(BreedingStatus)', FilterQueryOp.Contains, { value: filter.BreedingStatus.toLowerCase() }));
        }

        if (filter.ActiveBreedingStatuses && filter.ActiveBreedingStatuses.length > 0 && filterVisibility.activeBreedingStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveBreedingStatuses));
        }

        if (filter.DefaultBreedingStatuses && filter.DefaultBreedingStatuses.length > 0 && filterVisibility.defaultBreedingStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultBreedingStatuses));
        }

        return predicates;
    }

    private buildNameFormatPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.NameFormatType && filterVisibility.nameFormatTypeVisible) {
            const nameFormatType = this.translateFilterValue(filter.NameFormatType);
            predicates.push(Predicate.create("tolower(Type)", FilterQueryOp.Contains, { value: nameFormatType.toLowerCase() }));
        }

        return predicates;
    }

    private translateFilterValue(value: any): string {
        const hasTranslatedValue = this.translationService.hasTranslatedValue(value);
        if (hasTranslatedValue) {
            return this.translationService.translateRevert(value);
        }

        const hasTranslation = this.translationService.hasTranslateKey(value);
        if (hasTranslation) {
            return "fake_filter_value";
        }

        return value;
    }

    private buildFacetSettingPredicates(filter: any, filterVisibility: AuditFilterVisibility, isCRO: boolean, isGLP: boolean, isCRL: boolean): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.FacetType && filterVisibility.facetTypeVisible) {
            const facetType = this.translateFilterValue(filter.FacetType);
            predicates.push(Predicate.create("tolower(Facet)", FilterQueryOp.Contains, { value: facetType.toLowerCase() }));
        }

        if (filter.FacetSettingField && filterVisibility.facetSettingFieldVisible) {
            predicates.push(Predicate.create("tolower(Field)", FilterQueryOp.Contains, { value: filter.FacetSettingField.toLowerCase() }));
        }

        if (filter.ActiveFacetSettings && filter.ActiveFacetSettings.length > 0 && filterVisibility.activeFacetSettingVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveFacetSettings));
        }

        if (filter.RequiredFacetSettings && filter.RequiredFacetSettings.length > 0 && filterVisibility.requiredFacetSettingVisible) {
            predicates.push(Predicate.create('Required', 'in', filter.RequiredFacetSettings));
        }

        // Filter out fields that are feature flagged
        if (!isGLP) {
            predicates.push(Predicate.create('IsGLPOnly', '==', false));
        }

        if (!isCRL) {
            predicates.push(Predicate.create('IsCRLOnly', '==', false));
        }

        if (isCRO) {
            predicates.push(Predicate.create('IsNonCROOnly', '==', false));
        } else {
            predicates.push(Predicate.create('IsCROOnly', '==', false));
        }

        return predicates;
    }

    private buildVocabularyShipmentAnimalConditionPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.ShipmentAnimalCondition && filterVisibility.shipmentAnimalConditionVisible) {

            predicates.push(Predicate.create('tolower(ShipmentAnimalCondition)', FilterQueryOp.Contains, { value: filter.ShipmentAnimalCondition.toLowerCase() }));
        }

        if (filter.ActiveShipmentAnimalConditions && filter.ActiveShipmentAnimalConditions.length > 0 && filterVisibility.activeShipmentAnimalConditionVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveShipmentAnimalConditions));
        }

        if (filter.DefaultShipmentAnimalConditions && filter.DefaultShipmentAnimalConditions.length > 0 && filterVisibility.defaultShipmentAnimalConditionVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultShipmentAnimalConditions));
        }

        return predicates;
    }

    private buildVocabularyShipmentContainerConditionPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        predicates = this._addContainsPredicate(predicates, "ShipmentContainerCondition", filter.ShipmentContainerCondition, filterVisibility.shipmentContainerConditionVisible);
        predicates = (this._addInPredicate(predicates, "Active", filter.ActiveShipmentContainerConditions, filterVisibility.activeShipmentContainerConditionVisible));
        predicates = (this._addInPredicate(predicates, "Default", filter.DefaultShipmentContainerConditions, filterVisibility.defaultShipmentContainerConditionVisible));

        return predicates;
    }

    private buildVocabularyClinicalObservationStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.ClinicalObservationStatus && filterVisibility.clinicalObservationStatusVisible) {
            predicates.push(Predicate.create('tolower(ClinicalObservationStatus)', FilterQueryOp.Contains, { value: filter.ClinicalObservationStatus.toLowerCase() }));
        }

        if (filter.ActiveClinicalObservationStatuses && filter.ActiveClinicalObservationStatuses.length > 0 && filterVisibility.activeClinicalObservationStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveClinicalObservationStatuses));
        }

        if (filter.EndStateClinicalObservationStatuses && filter.EndStateClinicalObservationStatuses.length > 0 && filterVisibility.endStateClinicalObservationStatusVisible) {
            predicates.push(Predicate.create('EndState', 'in', filter.EndStateClinicalObservationStatuses));
        }

        if (filter.DefaultClinicalObservationStatuses && filter.DefaultClinicalObservationStatuses.length > 0 && filterVisibility.defaultClinicalObservationStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultClinicalObservationStatuses));
        }

        return predicates;
    }

    private buildVocabularyClinicalObservationPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.ClinicalObservation && filterVisibility.clinicalObservationVisible) {
            predicates.push(Predicate.create('tolower(ClinicalObservation)', FilterQueryOp.Contains, { value: filter.ClinicalObservation.toLowerCase() }));
        }

        if (filter.ActiveClinicalObservations && filter.ActiveClinicalObservations.length > 0 && filterVisibility.activeClinicalObservationVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveClinicalObservations));
        }

        if (filter.ExperimentalClinicalObservations && filter.ExperimentalClinicalObservations.length > 0 && filterVisibility.experimentalClinicalObservationVisible) {
            predicates.push(Predicate.create('Experimental', 'in', filter.ExperimentalClinicalObservations));
        }

        if (filter.DefaultClinicalObservations && filter.DefaultClinicalObservations.length > 0 && filterVisibility.defaultClinicalObservationVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultClinicalObservations));
        }

        return predicates;
    }

    private buildVocabularyClinicalObservationBodySystemPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Observations && filter.Observations.length > 0 && filterVisibility.observationsVisible) {
            predicates.push(Predicate.create('ClinicalObservation', 'in', filter.Observations));
        }

        if (filter.BodySystems && filter.BodySystems.length > 0 && filterVisibility.bodySystemVisible) {
            predicates.push(Predicate.create('BodySystem', 'in', filter.BodySystems));
        }

        return predicates;
    }

    private buildVocabularyDietPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Diet && filterVisibility.dietVisible) {
            predicates.push(Predicate.create('tolower(Diet)', FilterQueryOp.Contains, { value: filter.Diet.toLowerCase() }));
        }

        if (filter.ActiveDiets && filter.ActiveDiets.length > 0 && filterVisibility.activeDietVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveDiets));
        }

        if (filter.DefaultDiets && filter.DefaultDiets.length > 0 && filterVisibility.defaultDietVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultDiets));
        }

        return predicates;
    }

    private buildVocabularyGenerationPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Generation && filterVisibility.generationVisible) {
            predicates.push(Predicate.create('tolower(Generation)', FilterQueryOp.Contains, { value: filter.Generation.toLowerCase() }));
        }

        if (filter.ActiveGenerations && filter.ActiveGenerations.length > 0 && filterVisibility.activeGenerationVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveGenerations));
        }

        if (filter.DefaultGenerations && filter.DefaultGenerations.length > 0 && filterVisibility.defaultGenerationVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultGenerations));
        }

        return predicates;
    }

    private buildVocabularyLocationTypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.LocationType && filterVisibility.locationTypeVisible) {
            predicates.push(Predicate.create('tolower(LocationType)', FilterQueryOp.Contains, { value: filter.LocationType.toLowerCase() }));
        }
        if (filter.ActiveLocationTypes && filter.ActiveLocationTypes.length > 0 && filterVisibility.activeLocationTypeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveLocationTypes));
        }
        if (filter.DefaultLocationTypes && filter.DefaultLocationTypes.length > 0 && filterVisibility.defaultLocationTypeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultLocationTypes));
        }

        return predicates;
    }

    private buildEnumerationClassPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.ClassName && filterVisibility.classNameVisible) {
            predicates.push(Predicate.create('tolower(EnumerationName)', FilterQueryOp.Contains, { value: filter.ClassName.toLowerCase() }));
        }

        return predicates;
    }

    private buildVocabularyCompliancePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Compliance && filterVisibility.complianceVisible) {
            predicates.push(Predicate.create('tolower(Compliance)', FilterQueryOp.Contains, { value: filter.Compliance.toLowerCase() }));
        }

        if (filter.ActiveCompliance && filter.ActiveCompliance.length > 0 && filterVisibility.activeComplianceVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveCompliance));
        }

        if (filter.DefaultCompliance && filter.DefaultCompliance.length > 0 && filterVisibility.defaultComplianceVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultCompliance));
        }

        return predicates;
    }

    private buildVocabularyLineTypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.LineType && filterVisibility.lineTypeVisible) {
            predicates.push(Predicate.create('tolower(LineType)', FilterQueryOp.Contains, { value: filter.LineType.toLowerCase() }));
        }

        if (filter.ActiveLineTypes && filter.ActiveLineTypes.length > 0 && filterVisibility.activeLineTypeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveLineTypes));
        }

        if (filter.DefaultLineTypes && filter.DefaultLineTypes.length > 0 && filterVisibility.defaultLineTypeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultLineTypes));
        }

        return predicates;
    }

    private buildVocabularyCourierPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Courier && filterVisibility.courierVisible) {
            predicates.push(Predicate.create('tolower(Courier)', FilterQueryOp.Contains, { value: filter.Courier.toLowerCase() }));
        }

        if (filter.ActiveCouriers && filter.ActiveCouriers.length > 0 && filterVisibility.activeCourierVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveCouriers));
        }

        if (filter.DefaultCouriers && filter.DefaultCouriers.length > 0 && filterVisibility.defaultCourierVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultCouriers));
        }

        return predicates;
    }

    private buildVocabularyExitReasonPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.ExitReason && filterVisibility.exitReasonVisible) {
            predicates.push(Predicate.create('tolower(ExitReason)', FilterQueryOp.Contains, { value: filter.ExitReason.toLowerCase() }));
        }

        if (filter.ActiveExitReasons && filter.ActiveExitReasons.length > 0 && filterVisibility.activeExitReasonVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveExitReasons));
        }

        if (filter.DefaultExitReasons && filter.DefaultExitReasons.length > 0 && filterVisibility.defaultExitReasonVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultExitReasons));
        }

        return predicates;
    }

    private buildVocabularyGenotypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Genotype && filterVisibility.genotypeVisible) {
            predicates.push(Predicate.create('tolower(Genotype)', FilterQueryOp.Contains, { value: filter.Genotype.toLowerCase() }));
        }

        if (filter.ActiveGenotypes && filter.ActiveGenotypes.length > 0 && filterVisibility.activeGenotypeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveGenotypes));
        }

        if (filter.DefaultGenotypes && filter.DefaultGenotypes.length > 0 && filterVisibility.defaultGenotypeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultGenotypes));
        }

        return predicates;
    }

    private buildVocabularyMaterialPoolStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.MaterialPoolStatus && filterVisibility.materialPoolStatusVisible) {
            predicates.push(Predicate.create('tolower(HousingStatus)', FilterQueryOp.Contains, { value: filter.MaterialPoolStatus.toLowerCase() }));
        }

        if (filter.ActiveMaterialPoolStatuses && filter.ActiveMaterialPoolStatuses.length > 0 && filterVisibility.activeMaterialPoolStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveMaterialPoolStatuses));
        }

        if (filter.EndStateMaterialPoolStatuses && filter.EndStateMaterialPoolStatuses.length > 0 && filterVisibility.endStateMaterialPoolStatusVisible) {
            predicates.push(Predicate.create('EndState', 'in', filter.EndStateMaterialPoolStatuses));
        }

        if (filter.DefaultMaterialPoolStatuses && filter.DefaultMaterialPoolStatuses.length > 0 && filterVisibility.defaultMaterialPoolStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultMaterialPoolStatuses));
        }

        if (filter.DefaultEndStateMaterialPoolStatuses && filter.DefaultEndStateMaterialPoolStatuses.length > 0 && filterVisibility.defaultEndStateMaterialPoolStatusVisible) {
            predicates.push(Predicate.create('DefaultEndState', 'in', filter.DefaultEndStateMaterialPoolStatuses));
        }

        return predicates;
    }

    private buildCharacteristicJobsPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.JobCharacteristicName && filterVisibility.jobCharacteristicNameVisible) {
            predicates.push(Predicate.create('tolower(JobCharacteristicName)', FilterQueryOp.Contains, { value: filter.JobCharacteristicName.toLowerCase() }));
        }

        if (filter.ActiveJobCharacteristics && filter.ActiveJobCharacteristics.length > 0 && filterVisibility.activeJobCharacteristicVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveJobCharacteristics));
        }

        if (filter.LinkTypeJobCharacteristics && filter.LinkTypeJobCharacteristics.length > 0 && filterVisibility.linkTypeJobCharacteristicVisible) {
            predicates.push(Predicate.create('LinkType', 'in', filter.LinkTypeJobCharacteristics));
        }

        if ((this.isDate(filter.LastReviewedJobCharacteristicDateStart) || this.isDate(filter.LastReviewedJobCharacteristicDateEnd))
            && filterVisibility.lastReviewedJobCharacteristicVisible) {
            const datePredicates: Predicate[] = getDateRangePredicatesUTC(
                'LastReviewed',
                filter.LastReviewedJobCharacteristicDateStart,
                filter.LastReviewedJobCharacteristicDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        return predicates;
    }

    private buildVocabularyContainerTypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.ContainerType && filterVisibility.containerTypeVisible) {
            predicates.push(Predicate.create('tolower(ContainerType)', FilterQueryOp.Contains, { value: filter.ContainerType.toLowerCase() }));
        }

        if (filter.ActiveContainerType && filter.ActiveContainerType.length > 0 && filterVisibility.activeContainerTypeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveContainerType));
        }

        if (filter.AnimalContainerType && filter.AnimalContainerType.length > 0 && filterVisibility.animalContainerTypeVisible) {
            predicates.push(Predicate.create('Animal', 'in', filter.AnimalContainerType));
        }

        if (filter.DefaultContainerType && filter.DefaultContainerType.length > 0 && filterVisibility.defaultContainerTypeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultContainerType));
        }

        return predicates;
    }

    private buildVocabularyJobReportPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.JobReport && filterVisibility.jobReportVisible) {
            predicates.push(Predicate.create('tolower(JobReport)', FilterQueryOp.Contains, { value: filter.JobReport.toLowerCase() }));
        }

        if (filter.ActiveJobReports && filter.ActiveJobReports.length > 0 && filterVisibility.activeJobReportVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveJobReports));
        }

        return predicates;
    }


    private buildVocabularyMaterialPoolTypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.MaterialPoolType && filterVisibility.materialPoolTypeVisible) {
            predicates.push(Predicate.create('tolower(MaterialPoolType)', FilterQueryOp.Contains, { value: filter.MaterialPoolType.toLowerCase() }));
        }

        if (filter.ActiveMaterialPoolTypes && filter.ActiveMaterialPoolTypes.length > 0 && filterVisibility.activeMaterialPoolTypesVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveMaterialPoolTypes));
        }

        if (filter.DefaultMatingMaterialPoolTypes && filter.DefaultMatingMaterialPoolTypes.length > 0 && filterVisibility.defaultMatingMaterialPoolTypesVisible) {
            predicates.push(Predicate.create('DefaultMating', 'in', filter.DefaultMatingMaterialPoolTypes));
        }

        if (filter.DefaultMaterialPoolTypes && filter.DefaultMaterialPoolTypes.length > 0 && filterVisibility.defaultMaterialPoolTypesVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultMaterialPoolTypes));
        }

        return predicates;
    }

    private buildVocabularyContactPersonTypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.ContactPersonType && filterVisibility.contactPersonTypeVisible) {
            predicates.push(Predicate.create('tolower(ContactType)', FilterQueryOp.Contains, { value: filter.ContactPersonType.toLowerCase() }));
        }

        if (filter.ActiveContactPersonType && filter.ActiveContactPersonType.length > 0 && filterVisibility.activeContactPersonTypeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveContactPersonType));
        }

        if (filter.DefaultContactPersonType && filter.DefaultContactPersonType.length > 0 && filterVisibility.defaultContactPersonTypeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultContactPersonType));
        }

        return predicates;
    }

    private buildVocabularyJobTypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.JobType && filterVisibility.jobTypeVisible) {
            predicates.push(Predicate.create('tolower(JobType)', FilterQueryOp.Contains, { value: filter.JobType.toLowerCase() }));
        }
        if (filter.ActiveJobTypes && filter.ActiveJobTypes.length > 0 && filterVisibility.activeJobTypeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveJobTypes));
        }
        if (filter.DefaultJobTypes && filter.DefaultJobTypes.length > 0 && filterVisibility.defaultJobTypeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultJobTypes));
        }

        return predicates;
    }

    private buildVocabularyQuarantineFacilityPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.QuarantineFacility && filterVisibility.quarantineFacilityVisible) {
            predicates.push(Predicate.create('tolower(QuarantineFacility)', FilterQueryOp.Contains, { value: filter.QuarantineFacility.toLowerCase() }));
        }

        if (filter.ActiveQuarantineFacilities && filter.ActiveQuarantineFacilities.length > 0 && filterVisibility.activeQuarantineFacilityVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveQuarantineFacilities));
        }

        if (filter.DefaultQuarantineFacilities && filter.DefaultQuarantineFacilities.length > 0 && filterVisibility.defaultQuarantineFacilityVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultQuarantineFacilities));
        }

        return predicates;
    }

    private buildVocabularyGenotypeAssayPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.GenotypeAssay && filterVisibility.genotypeAssayVisible) {
            predicates.push(Predicate.create('tolower(GenotypeAssay)', FilterQueryOp.Contains, { value: filter.GenotypeAssay.toLowerCase() }));
        }

        if (filter.ActiveGenotypeAssay && filter.ActiveGenotypeAssay.length > 0 && filterVisibility.activeGenotypeAssayVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveGenotypeAssay));
        }

        if (filter.DefaultGenotypeAssay && filter.DefaultGenotypeAssay.length > 0 && filterVisibility.defaultGenotypeAssayVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultGenotypeAssay));
        }

        return predicates;
    }

    private buildVocabularySiteTypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.SiteType && filterVisibility.siteTypeVisible) {
            predicates.push(Predicate.create('tolower(SiteType)', FilterQueryOp.Contains, { value: filter.SiteType.toLowerCase() }));
        }
        if (filter.ActiveSiteTypes && filter.ActiveSiteTypes.length > 0 && filterVisibility.activeSiteTypeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveSiteTypes));
        }
        if (filter.DefaultSiteTypes && filter.DefaultSiteTypes.length > 0 && filterVisibility.defaultSiteTypeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultSiteTypes));
        }

        return predicates;
    }

    private buildEnumerationItemPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.ClassName && filterVisibility.classNameVisible) {
            predicates.push(Predicate.create('tolower(EnumerationName)', FilterQueryOp.Contains, { value: filter.ClassName.toLowerCase() }));
        }

        if (filter.ItemName && filterVisibility.itemNameVisible) {
            predicates.push(Predicate.create('tolower(EnumerationValue)', FilterQueryOp.Contains, { value: filter.ItemName.toLowerCase() }));
        }

        return predicates;
    }

    private buildVocabularyUnitPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Unit && filterVisibility.unitVisible) {
            predicates.push(Predicate.create('tolower(Unit)', FilterQueryOp.Contains, { value: filter.Unit.toLowerCase() }));
        }
        if (filter.ActiveUnits && filter.ActiveUnits.length > 0 && filterVisibility.activeUnitVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveUnits));
        }
        if (filter.DefaultUnits && filter.DefaultUnits.length > 0 && filterVisibility.defaultUnitVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultUnits));
        }

        return predicates;
    }

    private buildVocabularyRouteOfAdministrationPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.RouteOfAdministration && filterVisibility.routeOfAdministrationVisible) {
            predicates.push(Predicate.create('tolower(RouteOfAdministration)', FilterQueryOp.Contains, { value: filter.RouteOfAdministration.toLowerCase() }));
        }
        if (filter.ActiveRouteOfAdministrations && filter.ActiveRouteOfAdministrations.length > 0 && filterVisibility.activeRouteOfAdministrationVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveRouteOfAdministrations));
        }
        if (filter.DefaultRouteOfAdministrations && filter.DefaultRouteOfAdministrations.length > 0 && filterVisibility.defaultRouteOfAdministrationVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultRouteOfAdministrations));
        }

        return predicates;
    }

    private buildVocabularyVeterinaryRequestStatusPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.VeterinaryRequestStatus && filterVisibility.veterinaryRequestStatusVisible) {
            predicates.push(Predicate.create('tolower(VeterinaryRequestStatus)', FilterQueryOp.Contains, { value: filter.VeterinaryRequestStatus.toLowerCase() }));
        }
        if (filter.ActiveVeterinaryRequestStatuses && filter.ActiveVeterinaryRequestStatuses.length > 0 && filterVisibility.activeVeterinaryRequestStatusVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveVeterinaryRequestStatuses));
        }
        if (filter.EndStateVeterinaryRequestStatuses && filter.EndStateVeterinaryRequestStatuses.length > 0 && filterVisibility.endStateVeterinaryRequestStatusVisible) {
            predicates.push(Predicate.create('EndState', 'in', filter.EndStateVeterinaryRequestStatuses));
        }
        if (filter.DefaultVeterinaryRequestStatuses && filter.DefaultVeterinaryRequestStatuses.length > 0 && filterVisibility.defaultVeterinaryRequestStatusVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultVeterinaryRequestStatuses));
        }

        return predicates;
    }

    private buildVocabularyJobSubtypePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.JobSubtype && filterVisibility.jobSubtypeVisible) {
            predicates.push(Predicate.create('tolower(JobSubtype)', FilterQueryOp.Contains, { value: filter.JobSubtype.toLowerCase() }));
        }

        if (filter.ActiveJobSubtypes && filter.ActiveJobSubtypes.length > 0 && filterVisibility.activeJobSubtypeVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveJobSubtypes));
        }

        if (filter.DefaultJobSubtypes && filter.DefaultJobSubtypes.length > 0 && filterVisibility.defaultJobSubtypeVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultJobSubtypes));
        }

        return predicates;
    }

    public buildJobLocationPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.Jobs && filter.Jobs.length > 0 && filterVisibility.jobsVisible) {
            const jobNames = filter.Jobs.map((x: any) => x.JobID);
            predicates.push(Predicate.create('JobID', 'in', jobNames));
        }

        if (filter.Locations && filter.Locations.length > 0 && filterVisibility.locationsVisible) {
            const names = filter.Locations.map((x: any) => x.PositionName);
            predicates.push(
                this.buildSubstringPredicates('Location', names));
        }

        return predicates;
    }

    public buildStudyCharacteristicPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.StudyName && filterVisibility.studyNameVisible) {
            predicates.push(Predicate.create('tolower(StudyName)', FilterQueryOp.Contains, { value: filter.StudyName.toLowerCase() }));
        }

        if (filter.StudyType && filter.StudyType.length > 0 && filterVisibility.studyTypeVisible) {
            const orPredicate: Predicate[] = [];
            filter.TypeStudyCharacteristics.forEach((item: any) => {
                orPredicate.push(Predicate.create('tolower(StudyTypes)', FilterQueryOp.Contains, { value: item.toLowerCase() }));
            });
            predicates.push(Predicate.or(orPredicate));
        }

        if (filter.CharacteristicName && filterVisibility.characteristicNameVisible) {
            predicates.push(Predicate.create('tolower(CharacteristicName)', FilterQueryOp.Contains, { value: filter.CharacteristicName.toLowerCase() }));
        }

        if (filter.CharacteristicValue && filterVisibility.characteristicValueVisible) {
            predicates.push(Predicate.create('tolower(CharacteristicValue)', FilterQueryOp.Contains, { value: filter.CharacteristicValue.toLowerCase() }));
        }

        return predicates;
    }

    public buildSampleCharacteristicPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.SampleIdentifier && filterVisibility.sampleIdentifiersVisible) {
            predicates.push(Predicate.create('SampleID', FilterQueryOp.Contains, { value: filter.SampleIdentifier }));
        } else if (filter.SampleIdentifiers &&
            filter.SampleIdentifiers.length > 0 &&
            filterVisibility.sampleIdentifiersVisible) {
            predicates.push(Predicate.create('SampleID', 'in', filter.SampleIdentifiers));
        }
        if (filter.Samples && filter.Samples.length > 0 && filterVisibility.samplesVisible) {
            const sampleNames = filter.Samples.map((x: any) => x.SampleName);
            predicates.push(Predicate.create('SampleName', 'in', sampleNames));
        }

        if (filter.CharacteristicName && filterVisibility.characteristicNameVisible) {
            predicates.push(Predicate.create('tolower(CharacteristicName)', FilterQueryOp.Contains, { value: filter.CharacteristicName.toLowerCase() }));
        }

        if (filter.CharacteristicValue && filterVisibility.characteristicValueVisible) {
            predicates.push(Predicate.create('tolower(CharacteristicValue)', FilterQueryOp.Contains, { value: filter.CharacteristicValue.toLowerCase() }));
        }

        return predicates;
    }

    public buildAnimalCharacteristicPredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.AnimalIdentifier && filterVisibility.animalIdentifiersVisible) {
            predicates.push(Predicate.create('AnimalID', FilterQueryOp.Contains, { value: filter.AnimalIdentifier }));
        } else if (filter.AnimalIdentifiers && filter.AnimalIdentifiers.length > 0) {
            predicates.push(Predicate.create('AnimalID', 'in', filter.AnimalIdentifiers));
        }

        if (filter.Animals && filter.Animals.length > 0 && filterVisibility.animalsVisible) {
            const animalNames = filter.Animals.map((x: any) => x.AnimalName);
            predicates.push(Predicate.create('AnimalName', 'in', animalNames));
        }

        if (filter.CharacteristicName && filterVisibility.characteristicNameVisible) {
            predicates.push(Predicate.create('tolower(CharacteristicName)', FilterQueryOp.Contains, { value: filter.CharacteristicName.toLowerCase() }));
        }

        if (filter.CharacteristicValue && filterVisibility.characteristicValueVisible) {
            predicates.push(Predicate.create('tolower(CharacteristicValue)', FilterQueryOp.Contains, { value: filter.CharacteristicValue.toLowerCase() }));
        }

        return predicates;
    }

    private buildVocabularyTestArticlePredicates(filter: any, filterVisibility: AuditFilterVisibility): Predicate[] {
        if (!filter) {
            return [];
        }

        const predicates: Predicate[] = this.baseAuditPredicates(filter);

        if (filter.TestArticle && filterVisibility.testArticleVisible) {
            predicates.push(Predicate.create('tolower(TestArticle)', 'substringof', filter.TestArticle.toLowerCase()));
        }
        if (filter.ActiveTestArticles && filter.ActiveTestArticles.length > 0 && filterVisibility.activeTestArticleVisible) {
            predicates.push(Predicate.create('Active', 'in', filter.ActiveTestArticles));
        }
        if (filter.DefaultTestArticles && filter.DefaultTestArticles.length > 0 && filterVisibility.defaultTestArticleVisible) {
            predicates.push(Predicate.create('Default', 'in', filter.DefaultTestArticles));
        }

        return predicates;
    }

    getJobAuditData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/job/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditLinesData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/joblines/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditLineDetailsData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/joblinedetails/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditTestArticlesData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/jobtestarticles/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditJobGroupData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/jobgroups/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditInstitutionData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/jobinstitutions/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditCharacteristicsData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/jobcharacteristics/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditStandardPhrasesData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/jobstandardphrases/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditAnimalsData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/jobanimals/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditSamplesData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/jobsamples/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditSampleGroupsData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/jobsamplegroups/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getCurrentJobAuditTasksData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/jobtasks/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getStudyAuditData(studyKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/study/' +
            studyKey;

        return this.webApiService.callApi(apiUrl);
    }

    getInstitutionAuditData(institutionKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/institution/' +
            institutionKey;

        return this.webApiService.callApi(apiUrl);
    }

    getOrderAuditData(orderKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/orders/' +
            orderKey;

        return this.webApiService.callApi(apiUrl);
    }

    getStudyAuditCharacteristicsData(studyKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/studycharacteristics/' +
            studyKey;

        return this.webApiService.callApi(apiUrl);
    }

    getStudyAuditLinesData(studyKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/studylines/' +
            studyKey;

        return this.webApiService.callApi(apiUrl);
    }

    getWorkflowAuditData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/workflow/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getWorkflowAuditAnimalsData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/workflowanimals/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getWorkflowAuditSamplesData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/workflowsamples/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getWorkflowAuditInputsData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/workflowinputs/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getWorkflowAuditCohortsData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/workflowcohorts/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getAnimalAuditData(materialKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/animal/' +
            materialKey;

        return this.webApiService.callApi(apiUrl);
    }

    getAnimalAuditJobsData(materialKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/animaljobs/' +
            materialKey;

        return this.webApiService.callApi(apiUrl);
    }

    getAnimalAuditGenotypesData(materialKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/animalgenotypes/' +
            materialKey;

        return this.webApiService.callApi(apiUrl);
    }

    getAnimalAuditClinicalObservationsData(materialKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/animalclinicalobservations/' +
            materialKey;

        return this.webApiService.callApi(apiUrl);
    }

    getCurrentAnimalAuditTasksData(materialKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/animaltasks/' +
            materialKey;

        return this.webApiService.callApi(apiUrl);
    }

    getSampleAuditData(materialKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/sample/' +
            materialKey;

        return this.webApiService.callApi(apiUrl);
    }

    getSampleAuditJobsData(materialKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/samplejobs/' +
            materialKey;

        return this.webApiService.callApi(apiUrl);
    }

    getSampleAuditSourcesData(materialKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/samplesources/' +
            materialKey;

        return this.webApiService.callApi(apiUrl);
    }

    getSampleAuditConstructsData(materialKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/sampleconstructs/' +
            materialKey;

        return this.webApiService.callApi(apiUrl);
    }

    getCensusAuditData(censusKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/census/' +
            censusKey;

        return this.webApiService.callApi(apiUrl);
    }

    getCensusAuditDiscrepanciesData(censusKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/censusdiscrepancies/' +
            censusKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditCohortsData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/jobcohorts/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditGroupPlaceholdersData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/jobgroupplaceholders/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getJobAuditAnimalPlaceholdersData(jobKey: number): Promise<ApiResponse> {
        const apiUrl = 'api/auditreport/jobanimalplaceholders/' +
            jobKey;

        return this.webApiService.callApi(apiUrl);
    }

    getReasonForChangeColumn(): TableColumnDef {
        return {
            displayName: 'Reason For Change',
            field: 'ReasonForChange'
        };
    }
    filterAuditResponse(results: any[]) {
        return results.filter((row) => {
            // filter out the rows where "ReasonForChange" has been changed. 
            return row.ModifiedFields !== "ReasonForChange";
        });
    }

    onAuditLinkClicked(): Subject<AuditGridView> {
        return this.auditLinkClicked$;
    }

    sendAuditLinkClicked(viewName: AuditGridView): void {
        this.auditLinkClicked$.next(viewName);
    }

    setCurrentGridView(gridView: string): void {
        this.currentGridView = gridView;
    }

    getCurrentGridView(): string {
        return this.currentGridView;
    }

    private getChildWorkflowTasks(workflowTaskKeys: any[]): Promise<any[]> {
        return this.webApiService.postApi('api/task/getChildWorkflowTasks', {
            workflowTaskKeys
        })
            .then((response) => response.data);
    }

    private buildSubstringPredicates(fieldName: string, values: string[]): Predicate {
        return Predicate.or(values.map((x) => Predicate.create(fieldName, FilterQueryOp.Contains, { value: x })));
    }

    private isDate(value: any): boolean {
        return (null != value) && !isNaN(value) && ("undefined" !== typeof value.getDate);
    }

    /**
     * Adds an "in" predicate to predicateList if the value is truthy and the filter is visible
     * @param predicateList the list of predicates that will be transformed
     * @param column the database column from which the predicate will be filtering
     * @param filterValue the desired value, a string the column should contain
     * @param isFilterVisible is the filter visible on the current audit filter modal?
     */
    private _addInPredicate(predicateList: Predicate[], column: string, filterValue: any[], isFilterVisible: boolean): Predicate[] {
        if (filterValue && filterValue.length && isFilterVisible) {
            predicateList.push(Predicate.create(column, "in", filterValue));
        }
        return predicateList;
    }

    /**
     * Adds a "contains" predicate to predicateList if the value is truthy and the filter is visible.
     * @param predicateList the list of predicates that will be transformed
     * @param column the database column from which the predicate will be filtering
     * @param filterValue the desired value, a string the column should contain
     * @param isFilterVisible is the filter visible on the current audit filter modal?
     * @returns
     */
    private _addContainsPredicate(predicateList: Predicate[], column: string, filterValue: string, isFilterVisible: boolean): Predicate[] {
        if (filterValue && isFilterVisible) {
            predicateList.push(Predicate.create(`tolower(${column})`, FilterQueryOp.Contains, { value: filterValue.toString()}));
        }
        return predicateList;
    }
}
