import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const dateOrderValidationError = (dateOneField: string, dateTwoField: string): ValidationErrors => {
    return {
        dateOrder: {
            text: `The "${dateOneField}" should be earlier than "${dateTwoField}"`,
        }
    }
};

export function dateOrderValidator(dateOneField: string, dateTwoField: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const dateOne = control.get(dateOneField)?.value;
        const dateTwo = control.get(dateTwoField)?.value;

        if (dateOne && dateTwo && new Date(dateOne) > new Date(dateTwo)) {
            return dateOrderValidationError(dateOneField, dateTwoField);
        }
        return null;
    };
}
