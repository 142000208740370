import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CellFormatterService, ColumnsState, DataResponse, TableColumnDef, TableState } from '@common/datatable';
import { BaseFacet, BaseFacetService, IFacet } from '@common/facet';
import { arrowClockwise, magnifier, squareOnSquare } from '@common/icons';
import { workspacePremium } from '@common/icons/defs/workspace-premium.def';
import { Entity } from '@common/types';
import { Permit } from '@common/types/models/permit.interface';
import { DataContextService } from '@services/data-context.service';
import { QueryDef } from '@services/query-def';
import { WorkspaceFilterService } from '@services/workspace-filter.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PermitTableOptions } from '../permit-table-options';
import { TranslationService } from '@services/translation.service';
import { CopyBufferService } from '@common/services';
import { PermitService } from '../services/permit.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermitsFilterComponent } from './permits-filter.component';
import { filterToDate } from '@common/util';
import { IPermitFilter } from '../models/permit-filter.interface';


@Component({
    selector: 'permits-facet',
    templateUrl: './permits-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class PermitsFacetComponent extends BaseFacet<Entity<Permit>> implements OnInit, OnDestroy {
    @Input() facet: IFacet;

    readonly icons = { workspacePremium, magnifier, squareOnSquare, arrowClockwise };

    componentName = 'permits';

    permitTableOptions: PermitTableOptions;

    readonly COMPONENT_LOG_TAG = 'permits-facet';

    private notifier$ = new Subject<void>();
    componentFilterSubscription: Subscription;
    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private dataContext: DataContextService,
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private copyBufferService: CopyBufferService,
        private permitService: PermitService,
        workspaceFilterService: WorkspaceFilterService,
        private modalService: NgbModal,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.permitTableOptions = new PermitTableOptions(
            this.cellFormatterService,
            this.translationService
        );

        this.dataTableColumns = new BehaviorSubject(this.permitTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemList(tableState);
            }
        };
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initialize();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }

    ngOnDestroy(): void {
        this.notifier$.next();
        this.notifier$.complete();

        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }
    }

    public initialize(): void {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    public refreshData(): void {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    public dragStart(): void {
       this.permitService.draggedPermits = this.draggedRows;
    }

    public dragStop(): void {
        setTimeout(() => {
            this.permitService.draggedPermits = [];
        }, 500);
    }

    public copyPermits(): void {
        this.copyBufferService.copy(this.selectedRows);
    }

    
    public async loadItemList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands: string[] = [
            'Resource',
            'cv_PermitStatus',
            'cv_PermitCategory',
            'cv_PermitExternalPurpose',
        ];

        this.setLoadingState(tableState.loadingMessage);

        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter(),
            expands,
        };

        try {
            const response = await this.permitService.getPermits(queryDef);
            const visibleColumns = this.getVisibleColumns(this.permitTableOptions.options);
            await this.permitService.ensureVisibleColumnsDataLoaded(response.results as Entity<Permit>[], visibleColumns);

            this.stopLoading();

            this.data = response.results as Entity<Permit>[];
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    public addItemClick(): void {
        if (this.privilegeService.readwrite) {
            this.setLoadingState();
            try {
                const permit = this.createNewPermit();
                this.stopLoading();
                this.itemToEdit = permit;
                this.changeView(this.DETAIL_VIEW);
            } catch (error) {
                this.loading = false;
                this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
            }
        }
    }

    public createNewPermit(): Entity<Permit> {
        return this.permitService.createPermit();
    }

    public modelCopied(itemCopied: Entity<Permit>): void {
        this.itemToEdit = itemCopied;

        this.loggingService.logSuccess('Permit copied', null, this.COMPONENT_LOG_TAG, true);
        this.changeView(this.DETAIL_VIEW);
    }

    public restoreFilterState(): void {
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter) as Partial<IPermitFilter>;
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.startDate = filterToDate(this.filter.startDate);
                this.filter.expiryDate = filterToDate(this.filter.expiryDate);
            } else {
                this.filter = {};
            }
        }
    }

    public openFilter(): void {
        const ref = this.modalService.open(PermitsFilterComponent, { size: 'lg' });
        const component = ref.componentInstance as PermitsFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: Partial<IPermitFilter>) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    public async selectedColumnsChange({ visible }: ColumnsState): Promise<void> {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.permitService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}
