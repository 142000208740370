import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseDetail, BaseDetailService, FacetView, IFacet, PageState } from '@common/facet';
import { cv_PermitCategory, cv_PermitExternalPurpose, cv_PermitStatus, Entity, Resource } from '@common/types';
import { Permit } from '@common/types/models/permit.interface';
import { dateOrderValidator } from '@common/validators/date-order.validator';
import { PrivilegeService } from '@services/privilege.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { PermitService } from '../services/permit.service';
import { ResourceService } from 'src/app/resources';
import { SaveChangesService } from '@services/save-changes.service';
import { TranslationService } from '@services/translation.service';
import { PermitSpeciesLine } from '@common/types/models/permit-species-line.interface';
import { IPermitForm, InstitutionFormValue, PermitSpeciesLineForm, PermitSpeciesOriginForm } from '../models/permit-form.interfaces';
import { PermitSpeciesOrigin } from '@common/types/models/permit-species-origin.interface';

@Component({
    selector: 'permit-detail',
    templateUrl: './permit-detail.component.html',
    styles: [`
        .summary-textarea {
            width: 100%;
        }
    `],
})
export class PermitDetailComponent extends BaseDetail implements OnInit, OnChanges, OnDestroy {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() permit: Entity<Permit>;
    @Input() pageState: PageState;

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() modelCopy: EventEmitter<Entity<Permit>> = new EventEmitter<Entity<Permit>>();

    readonly COMPONENT_LOG_TAG = 'permit-detail';
    public readonly MAX_SUMMARY_SYMBOLS = 8000;
    public readonly MAX_OTHER_PURPOSE_SYMBOLS = 1000;

    public readwrite: boolean;
    public readonly: boolean;
    public form: FormGroup | null;
    public permitCategories: cv_PermitCategory[];
    public permitStatuses: cv_PermitStatus[];
    public permitExternalPurposes: cv_PermitExternalPurpose[];
    public permitInstitutions: InstitutionFormValue[];
    public resources: Resource[];
    private notifier$ = new Subject<void>();

    public loadingMessage = 'Loading';
    public loading = true;
    public readonly CHANGING_ENTITIES = ['Permit', 'PermitInstitution', 'PermitSpecies', 'PermitSpeciesLine', 'PermitSpeciesOrigin'];
    public originalPermitFormValue: Omit<IPermitForm, 'Institutions' | 'PermitSpecies'>;
    private readonly subs = new Subscription();

    constructor(private baseDetailService: BaseDetailService,
        private privilegeService: PrivilegeService,
        private fb: FormBuilder,
        private vocabularyService: VocabularyService,
        private permitService: PermitService,
        private resourceService: ResourceService,
        private saveChangesService: SaveChangesService,
        public translationService: TranslationService,
    ) {
        super(baseDetailService);
    }

    get permitNumberControl(): AbstractControl {
        return this.form?.get('PermitNumber');
    }

    get permitSpeciesControl(): FormArray {
        return this.form.get('PermitSpecies') as FormArray;
    }

    get permitInstitutionControl(): AbstractControl {
        return this.form.get('Institutions');
    }

    public async ngOnInit(): Promise<void> {
        await this.initialize();
        this.subs.add(this.dataContext.onRejectEntityChange$.subscribe((entity: Entity<unknown>) => {
            this.onDiscardEntityChange(entity);
        }));
        this.subs.add(this.dataContext.changesSaved$.subscribe(() => {
            this.setOriginalPermitValues();
        }));
        this.setOriginalPermitValues();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.loading = true;
        if (changes.permit) {
            if (this.form && !changes.permit.firstChange) {
                this.form = null;
                this.initialize();
            }
        }
    }

    public ngOnDestroy(): void {
        this.notifier$.next();
        this.notifier$.complete();
        this.subs.unsubscribe();
    }

    public async initialize(): Promise<void> {
        const expands: string[] = [
            'Resource',
            'PermitInstitution.Institution',
            'PermitSpecies.cv_Taxon',
            'PermitSpecies.PermitSpeciesLine.Line',
            'PermitSpecies.PermitSpeciesOrigin.cv_MaterialOrigin',
            'cv_PermitStatus',
            'cv_PermitCategory',
            'cv_PermitExternalPurpose',
        ];
        this.setPrivileges();
        await this.permitService.getPermit(this.permit.C_Permit_key, expands);
        await this.initCVs();
        this.initForm();
        this.setDefaultVocabularies();
        this.loading = false;
    }

    public initForm(): void {
        if (this.form) {
            this.form = null;
            this.notifier$.next();
            this.notifier$.complete();
        }
        this.permitInstitutions = this.permit.PermitInstitution.map(item => ({
            InstitutionKey: item.Institution.C_Institution_key, Name: item.Institution.Name 
        }));
        this.form = this.fb.group({
            PermitNumber: [this.permit.PermitNumber, [Validators.required]],
            Owner: [this.permit.C_Resource_key, [Validators.required]],
            Title: [this.permit.Title],
            PermitStatusKey: [this.permit.C_PermitStatus_key, [Validators.required]],
            StartDate: [this.permit.StartDate],
            ExpiryDate: [this.permit.ExpiryDate],
            PermitCategoryKey: [this.permit.C_PermitCategory_key],
            IsGMOAnimalsAllowed: [this.permit.GMOAnimalsAllowed],
            PermitExternalPurposeKey: [this.permit.C_PermitExternalPurpose_key],
            OtherPurpose: [this.permit.OtherPurpose, [Validators.max(this.MAX_OTHER_PURPOSE_SYMBOLS)]],
            Institutions: [this.permitInstitutions],
            PermitSpecies: this.fb.array(this.initPermitSpeciesFormArray()),
            Summary: [this.permit.Summary, [Validators.max(this.MAX_SUMMARY_SYMBOLS)]],
        }, { validators: dateOrderValidator('StartDate', 'ExpiryDate') });

        this.form.valueChanges
            .pipe(takeUntil(this.notifier$))
            .subscribe((value: IPermitForm) => {
                this.permitService.onPermitFormValuesChanged(this.permit, value);
            });
    }

    public initPermitSpeciesFormArray(): FormGroup[] {
        return this.permit.PermitSpecies.map(item => {
            return this.fb.group({
                PermitSpeciesKey: [item.C_PermitSpecies_key],
                SpeciesKey: [item.cv_Taxon?.C_Taxon_key, [Validators.required]],
                AnimalsOnPermit: [item.AnimalsOnPermit],
                PermitSpeciesLine: [this.initFormPermitSpeciesLines(item.PermitSpeciesLine)],
                SeverityKey: [item.C_Severity_key],
                NoRecoveryAllowed: [item.NoRecoveryAllowed],
                ReuseAllowed: [item.ReuseAllowed],
                PermitSpeciesOrigin: [this.updateFormPermitSpeciesOrigins(item.PermitSpeciesOrigin)],
            });
        });
    }

    public initFormPermitSpeciesLines(permitSpeciesLine: PermitSpeciesLine[]): PermitSpeciesLineForm[] {
        return permitSpeciesLine.map(({ C_Line_key, Line, PermitSpecies, C_PermitSpecies_key }) => ({
            LineKey: C_Line_key,
            LineName: Line?.LineName,
            Taxon: PermitSpecies?.cv_Taxon.Taxon,
            TaxonKey: C_PermitSpecies_key,
        }));
    }

    public updateFormPermitSpeciesOrigins(permitSpeciesOrigin: PermitSpeciesOrigin[]): PermitSpeciesOriginForm[] {
        return permitSpeciesOrigin.map(item => ({
            MaterialOrigin: item.cv_MaterialOrigin?.MaterialOrigin,
            MaterialOriginKey: item.C_MaterialOrigin_key,
        }));
    }

    public async initCVs(): Promise<void> {
        const result = await Promise.all([
            this.vocabularyService.getCV('cv_PermitCategories'),
            this.vocabularyService.getCV('cv_PermitStatuses'),
            this.vocabularyService.getCV('cv_PermitExternalPurposes'),
            this.resourceService.getAllResources(),
        ]);
        [this.permitCategories, this.permitStatuses, this.permitExternalPurposes, this.resources] = result;
    }

    public setDefaultVocabularies(): void {
        if (this.permit.entityAspect.entityState.isAdded()) {
            const defaultPermitCategory = this.permitCategories.find(item => item.IsDefault);
            const defaultPermitStatus = this.permitStatuses.find(item => item.IsDefault);
            const defaultPermitExternalPurpose = this.permitExternalPurposes.find(item => item.IsDefault);

            this.form.get('PermitCategoryKey').setValue(defaultPermitCategory?.C_PermitCategory_key);
            this.form.get('PermitStatusKey').setValue(defaultPermitStatus?.C_PermitStatus_key);
            this.form.get('PermitExternalPurposeKey').setValue(defaultPermitExternalPurpose?.C_PermitExternalPurpose_key);
        }
    }

    private setPrivileges() {
        this.readonly = this.privilegeService.readonly;
        this.readwrite = this.privilegeService.readwrite;
    }

    async copyPermit(): Promise<void> {
        let toPermit: Entity<Permit>;
        try {
            await this.saveChangesService.promptForUnsavedChanges(this.COMPONENT_LOG_TAG);

            const fromPermit = this.permit;

            toPermit = this.permitService.createPermit();
            this.permitService.copyPermit(fromPermit, toPermit);

            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false);
            this.permit = toPermit;
            this.modelCopy.emit(this.permit);
        } catch (error) {
            if (toPermit) {
                this.permitService.cancelPermit(toPermit);
                this.loggingService.logDebug(
                    'New permit copy state was reverted locally because of error',
                    null,
                    this.COMPONENT_LOG_TAG
                );
            }
        }
    }

    public onCancel(): void {
        this.permitService.cancelPermit(this.permit);
    }

    public savePermit(): void {
        this.permitService.savePermit(this.COMPONENT_LOG_TAG);
        this.setOriginalPermitValues();
    }

    public onInstitutionsChange(institutions: InstitutionFormValue[]): void {
        this.permitInstitutionControl.setValue(institutions);
    }

    private setOriginalPermitValues(): void {
        this.originalPermitFormValue = {
            PermitNumber: this.permit.PermitNumber,
            Owner: this.permit.C_Resource_key,
            Title: this.permit.Title,
            PermitStatusKey: this.permit.C_PermitStatus_key,
            StartDate: this.permit.StartDate,
            ExpiryDate: this.permit.ExpiryDate,
            PermitCategoryKey: this.permit.C_PermitCategory_key,
            IsGMOAnimalsAllowed: this.permit.GMOAnimalsAllowed,
            PermitExternalPurposeKey: this.permit.C_PermitExternalPurpose_key,
            OtherPurpose: this.permit.OtherPurpose,
            Summary: this.permit.Summary,
        };
    }

    private onDiscardEntityChange(entity: Entity<unknown>): void {
        if (this.CHANGING_ENTITIES.includes(entity.entityType.shortName)) {
            this.initForm();
        }
    }

    resourceKeyFormatter = (value: Resource): number => {
        return value.C_Resource_key;
    }

    resourceNameFormatter = (value: Resource): string => {
        return value.ResourceName;
    }
    
    permitCategoryKeyFormatter = (value: cv_PermitCategory): number => {
        return value.C_PermitCategory_key;
    }

    permitCategoryFormatter = (value: cv_PermitCategory): string => {
        return value.PermitCategory;
    }

    permitStatusKeyFormatter = (value: cv_PermitStatus): number => {
        return value.C_PermitStatus_key;
    }

    permitStatusFormatter = (value: cv_PermitStatus): string => {
        return value.PermitStatus;
    }

    permitExternalPurposeKeyFormatter = (value: cv_PermitExternalPurpose): number => {
        return value.C_PermitExternalPurpose_key;
    }

    permitExternalPurposeFormatter = (value: cv_PermitExternalPurpose): string => {
        return value.PermitExternalPurpose;
    }
}
