import {
    Injectable
} from '@angular/core';

import { CurrentWorkgroupService } from './current-workgroup.service';
import { WebApiService } from '../services/web-api.service';

import { notEmpty } from '../common/util';
import { AuthService } from './auth.service';
import { first } from 'rxjs/operators';

export const UNHANDLED_ERROR_MESSAGE = 'An unexpected error has occurred';
export const AUTH_ERROR_TAG = "Authorization has been denied for this request."; 

@Injectable()
export class TranslationService {

    private translations: any = {};
    private translationsReverse: any = {};
    private translationKeys: string[] = [];
    private translationValues: string[] = [];
    private _initPromise: Promise<any>;

    readonly COHORT_UNIQUE_ERROR = 'Cohort Name must be unique';
    readonly CHARACTERISTIC_NAME_REQUIRED_ERROR = 'New characteristic requires a name. Please enter a name and try again.';

    readonly ANIMALCOMMENT_TOO_SHORT_KEY = 'ANIMALCOMMENT_TOO_SHORT';
    readonly ANIMALCOMMENT_TOO_SHORT_MESSAGE = 'Comments require 1 character minimum';
    readonly MICROCHIPID_ALREADY_TAKEN_KEY = 'MICROCHIPID_ALREADY_TAKEN';
    readonly ALTERNATEPHYSICALID_ALREADY_TAKEN_KEY = 'ALTERNATEPHYSICALID_ALREADY_TAKEN';
    readonly PROTOCOL_NAME_ALREADY_TAKEN_KEY = 'PROTOCOL_NAME_ALREADY_TAKEN';
    readonly PROTOCOL_NAME_ALREADY_TAKEN_MESSAGE = 'This Protocol Name has already been used. Please give this protocol a unique name.';
    readonly TASK_NAME_ALREADY_TAKEN_KEY = 'TASK_NAME_ALREADY_TAKEN';
    readonly TASK_NAME_ALREADY_TAKEN_MESSAGE = 'This Task Name has already been used. Please give this task a unique name.';
    readonly CHARACTERISTIC_LINK_ERROR = 'CHARACTERISTIC_LINK_ERROR';

    readonly API_URL = 'api/translations';

    readonly CHARACTERISTIC_LIST_VIEW_ERROR = 'CHARACTERISTIC_LIST_VIEW_ERROR';
    constructor(
        private currentWorkgroupService: CurrentWorkgroupService,
        private webApiService: WebApiService,
        private authService: AuthService
    ) {
        this.authService.authenticationSuccessSource$
            .pipe(first())
            .subscribe(() => this.initialize());
    }

    initialize(): Promise<any> {
        this._initPromise = this._getDictionary();
        return this._initPromise;
    }

    onInitComplete(): Promise<any> {
        return this._initPromise;
    }

    private _getDictionary(): Promise<any> {
        const serviceUrl = this.API_URL;

        return this.webApiService.callApi(serviceUrl).then((response) => {
            if (response) {
                const result = response.data;

                if (!result || result.length === 0) {
                    throw new Error('No translations data received from API');
                }

                this.translations = this._objectKeysToLowerCase(result);
                this.translationKeys = this._getKeys(this.translations);
                this.translationValues = this._getValuesToLowerCase(result);
                this.translationsReverse = this._objectValueToLowerCaseByKey(result);
            } else {
                throw new Error('No response received from translations API: ' + serviceUrl);
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    /**
     * Creates a shallow (one-level) copy of an object, converting all keys to lowercase.
     */
    private _objectKeysToLowerCase(input: any): any {
        if (input === null || typeof input !== 'object') {
            throw new TypeError('Expected input to be an object');
        }

        const output = {};

        // Simple shallow copy
        const keys = Object.keys(input);
        const last = keys.length - 1;
        let index = last + 1;
        while (index--) {
            // Preserve order
            const key = keys[last - index];
            output[key.toLowerCase()] = input[key];
        }

        return output;
    }

    private _objectValueToLowerCaseByKey(input: any): any {
        if (input === null || typeof input !== 'object') {
            throw new TypeError('Expected input to be an object');
        }

        let output = {};

        output = Object.fromEntries(
            Object.entries(input).map(([key, value]) => [value.toString().toLowerCase(), key])
        );

        return output;
    }

    private _getKeys(object: any): string[] {
        return Object.keys(object);
    }

    private _getValuesToLowerCase(object: any): string[] {
        return Object.values(object).map((value) => value.toString().toLowerCase());
    }

    /**
     * Translates a term (case-insensitive) to its replacement.
     */
    translate(key: string): string {
        if (!key) {
            return "";
        }

        const termCooked = key.toLowerCase();
        const replacement = this.translations[termCooked];

        if (replacement) {
            return replacement;
        } else {
            return key;
        }
    }

    /*
     * Translates a value (case-insensitive) to a term.
     */
    translateRevert(value: string): string {
        if (!value) {
            return "";
        }

        const valueCooked = value.toLowerCase();
        const replacement = this.translationsReverse[valueCooked];

        if (replacement) {
            return replacement;
        } else {
            return value;
        }
    }

    /*
    * Has a key for translate
    */
    hasTranslateKey(key: string): boolean {
        if (!key) {
            return false;
        }

        const replacement = this.translations[key.toLowerCase()];
        return !!replacement;
    }

    /*
    * Has a translated value after key replacement
    */
    hasTranslatedValue(value: string): boolean {
        if (!value) {
            return false;
        }

        const replacement = this.translationValues.find(v => v === value.toLowerCase());
        return !!replacement;
    }

    /**
     * @returns the translated name for "Job"
     */
    getTranslatedJobName(): string {
        return this.translate("Job");
    }

    /**
     * 
     * @returns The translated name for "Study"
     */
    getTranslatedStudyName(): string {
        return this.translate("Study");
    }

    /**
     * Translates embedded terms in a string.
     */
    translateMessage(message: string): string {
        if (!message) {
            return "";
        }

        let output = message;

        for (const term of this.translationKeys) {
            // Replace whole words only
            const regex = new RegExp('\\b' + term + '\\b', 'gi');

            output = output.replace(regex, (match: string) => {
                const replacement = this.translate(match);

                // Match case of the original
                if (this._isLowerCase(match)) {
                    return replacement.toLowerCase() + '_REPLACED';
                } else {
                    return replacement + '_REPLACED';
                }
            });
        }

        output = output.replace(new RegExp('_REPLACED', 'g'), '');

        output = output.replace(new RegExp('A experiment', 'g'), 'An experiment');

        return output;
    }

    private _isLowerCase(input: string): boolean {
        return (input === input.toLowerCase());
    }


    /**
     * Translate Breeze / Entity errors to be user friendly
     * @param error
     */
    translateSaveErrors(error: any): string {
        const messagePrefix = "Save failed: ";

        const messages = [];

        // first check entityErrors
        if (error.entityErrors) {
            for (const entityError of error.entityErrors) {

                let message = "";

                switch (entityError.errorMessage) {
                    // User did not select a line, which determines the taxon
                    case "'C_Taxon_key' is required":
                        if (entityError.entity.Animal) {
                            message += (entityError.entity.Animal.AnimalName || 'Animal') +
                                ' requires a ' + this.translate('line');
                        }
                        if (entityError.entity.Sample) {
                            message += (entityError.entity.Sample.SampleName || 'Sample') +
                                ' requires a ' + this.translate('line');
                        }
                        if (entityError.entity.entityType.shortName === 'TaxonCharacteristic') {
                            message += 'Taxon Characteristic requires a ' + this.translate('line');
                        }
                        break;
                    case "'C_SampleType_key' is required":
                        if (entityError.entity.entityType.shortName === 'SampleCharacteristic') {
                            message += 'Sample Characteristic requires a type';
                        } else {
                            message += (entityError.entity.SampleName || 'Sample') +
                                ' requires a type';
                        }
                        break;
                    case "'C_SampleStatus_key' is required":
                        message += (entityError.entity.SampleName || 'Sample') +
                            ' requires a status';
                        break;
                    case "'C_MaterialPool_key' is required":
                        message += (entityError.entity.BirthID || 'Birth') +
                            ' requires a mating';
                        break;
                    case "'StudyName' is required":
                        message += this.translate('Study') + ' requires a name';
                        break;
                    case "'C_Resource_key' is required":
                        if (entityError.entity.entityType.shortName === 'Permit') {
                            message += 'Permit requires an owner';
                        }
                        break;
                    default:
                        message += entityError.entity.entityType.shortName + ' ';
                        message += entityError.errorMessage.replace('C_', '').replace('_key', '');
                        break;
                }

                messages.push(message);
            }
        }

        // Also check actual error message
        if (error?.message?.includes?.('CohortName')) {
            messages.push(this.COHORT_UNIQUE_ERROR);
        }
        if (error?.message?.includes?.('AnimalName field is required')) {
            messages.push('Animal require a name.<br/>' +
                'If automatic name generation is disabled, you must enter a name.');
        }
        if (error?.message?.includes?.('Taxon field is required')) {
            messages.push(this.translate('Line') + ' is required.');
        }
        if (error?.message?.includes?.('JobID is required')) {
            messages.push('A ' + this.translate('job') + ' requires a name. Please enter a ' + this.translate('job') + ' name and try again.');
        }
        if (error?.message?.includes?.('JobID is not unique')) {
            messages.push('A ' + this.translate('job') + ' requires a unique name. Please enter a new ' + this.translate('job') + ' name and try again.');
        }
        /* Characteristic facet validation errors. */
        if (error?.message?.includes?.('JobCharacteristic Enumeration is required')) {
            messages.push(this.translate('job') + "Characteristic 'Enumeration' is required");
        }
        if (error?.message?.includes?.('JobCharacteristic Vocabulary is required')) {
            messages.push(this.translate('job') + "JobCharacteristic 'Vocabulary' is required");
        }
        if (error?.message?.includes?.('TaxonCharacteristic Enumeration is required')) {
            messages.push("AnimalCharacteristic 'Enumeration' is required");
        }
        if (error?.message?.includes?.('SampleCharacteristic Enumeration is required')) {
            messages.push("SampleCharacteristic 'Enumeration' is required");
        }
        if (error?.message?.includes?.('StudyCharacteristic Enumeration is required')) {
            messages.push(this.translate('study') + "Characteristic 'Enumeration' is required");
        }
        //
        if (error?.message?.includes?.('OrderID is not unique')) {
            messages.push('An order requires a unique number. Please enter a new order number and try again.');
        }
        if (error?.message?.includes?.('The Location field is required')) {
            messages.push('New Order requires a Location for automatic name generation.');
        }
        if (error?.message?.includes?.('OrderId field is required')) {
            messages.push('New orders require a name.<br/>' +
                'If automatic name generation is disabled, you must enter a OrderId.');
        }
        if (error?.message?.includes?.('FOREIGN KEY constraint')) {
            // Birth missing a mating ID
            if (error?.message?.includes?.('Birth') &&
                error?.message?.includes?.('MaterialPool')
            ) {
                messages.push('New birth requires a mating. ' +
                    'Please select a mating ID and try again.');
            }
            if (error?.message?.includes?.('Genotype') &&
                error?.message?.includes?.('Material')
            ) {
                messages.push('New genotype requires an animal. ' +
                    'Please associate an animal using bulk edit and try again.');
            }
            if (error?.message?.includes?.('AnimalClinicalObservation') &&
                error?.message?.includes?.('Material')
            ) {
                messages.push('New clinical observation requires an animal. ' +
                    'Please associate an animal and try again.');
            }
            if (error?.message?.includes?.('AnimalClinicalObservation') &&
                error?.message?.includes?.('cv_ClinicalObservation')
            ) {
                messages.push('New clinical observation requires an observation. ' +
                    'Please select an observation and try again.');
            }
            if (error?.message?.includes?.('cv_JobStatus')) {
                messages.push('New ' + this.translate('job') + ' requires a status. ' +
                    'Please select ' + this.translate('job') + ' status and try again.');
            }
            if (error?.message?.includes?.('cv_JobType')) {
                messages.push('New ' + this.translate('job') + ' requires a type. Please select ' + this.translate('job') + ' type and try again.');
            }
            if (error?.message?.includes?.('cv_JobSubtype')) {
                messages.push('New ' + this.translate('job') + ' requires a subtype. Please select ' + this.translate('job') + ' subtype and try again.');
            }
            if (error?.message?.includes?.('cv_PlateStatus')) {
                messages.push('New plate requires a status. ' +
                    'Please select a status and try again.');
            }
            if (error?.message?.includes?.('cv_LineType')) {
                messages.push('New ' + this.translate('line') + ' requires a type. Please select a type and try again.');
            }
            if (error?.message?.includes?.('Line') && error?.message?.includes?.('cv_Taxon')) {
                messages.push('New ' + this.translate('line') + ' requires a species. ' +
                    'Please select a species and try again.');
            }
            if (error?.message?.includes?.('Material') && error?.message?.includes?.('cv_Taxon')) {
                // error on taxon, taxon is determined by line
                messages.push('New animals and samples require a ' + this.translate('line') + '. ' +
                    'Please select a ' + this.translate('line') + ' and try again.');
            }
            if (error?.message?.includes?.('cv_StudyType')) {
                messages.push('New ' + this.translate('Study') + ' requires a type. Please select a type and try again.');
            }
        }
        if (error?.message?.includes?.('CharacteristicName')) {
            messages.push(this.CHARACTERISTIC_NAME_REQUIRED_ERROR);
        }
        if (error?.message?.includes?.(this.CHARACTERISTIC_LINK_ERROR)) {
            messages.push(error.message.replace(this.CHARACTERISTIC_LINK_ERROR + ": ", ""));
        }
        if (error?.message?.includes?.('MaterialPoolID is required')) {
            messages.push('A housing requires an ID. Please enter a housing ID and try again.');
        }
        if (error?.message?.includes?.('MaterialPoolID is not unique')) {
            messages.push('A housing requires a unique ID. Please enter a new housing ID and try again.');
        }
        if (error?.message?.includes?.('MatingID is required')) {
            messages.push('A mating requires an ID. Please enter a mating ID and try again.');
        }
        if (error?.message?.includes?.('MatingID is not unique')) {
            messages.push('A mating requires a unique ID. Please enter a new mating ID and try again.');
        }
        if (error?.message?.includes?.(this.ANIMALCOMMENT_TOO_SHORT_KEY)) {
            messages.push(this.ANIMALCOMMENT_TOO_SHORT_MESSAGE);
        }
        if (error?.message?.includes?.(this.MICROCHIPID_ALREADY_TAKEN_KEY)) {
            messages.push('The Microchip ID is already in use. Please enter a new ID and try again.');
        }
        if (error?.message?.includes?.(this.ALTERNATEPHYSICALID_ALREADY_TAKEN_KEY)) {
            messages.push('The Alternate Physical ID is already in use. Please enter a new ID and try again.');
        }
        if (error?.message?.includes?.('ProtocolName')) {
            messages.push('A protocol requires a name. Please enter a protocol name and try again.');
        }
        if (error?.message?.includes?.(this.PROTOCOL_NAME_ALREADY_TAKEN_KEY)) {
            messages.push(this.PROTOCOL_NAME_ALREADY_TAKEN_MESSAGE);
        }
        if (error?.message?.includes?.(this.TASK_NAME_ALREADY_TAKEN_KEY)) {
            messages.push(this.TASK_NAME_ALREADY_TAKEN_MESSAGE);
        }
        if (error?.message?.includes?.(this.CHARACTERISTIC_LIST_VIEW_ERROR)) {
            messages.push('Taxon Characteristics shown in list views must be unique');
        }
        if (error?.message?.includes?.('BirthID is required')) {
            messages.push('A birth requires an ID. Please enter a birth ID and try again.');
        }
        if (error?.message?.includes?.('BirthID is not unique')) {
            messages.push('A birth requires a unique ID. Please enter a new birth ID and try again.');
        }
        if (error?.message?.includes?.('StudyName is not unique')) {
            messages.push('A ' + this.translate('Study') + ' requires a unique name. Please enter a new name and try again.');
        }
        if (error?.message?.includes?.('Permit Number is required')) {
            messages.push('A permit requires a number. Please enter a number and try again.');
        }
        if (error?.message?.includes('Permit Number must be unique')) {
            messages.push('Permit Number must be unique.');
        }
        if (error?.message?.includes('Permit Start Date must be before Expiry Date')) {
            messages.push('Permit Start Date must be before Expiry Date');
        }
        if (error?.message?.includes('A PermitSpecies must have a Species selection')) {
            messages.push('A PermitSpecies must have a Species selection.');
        }

        let finalMessage = '';
        if (notEmpty(messages)) {
            finalMessage = this.translateMessage(messages.join('<br/>'));
        } else {
            finalMessage = UNHANDLED_ERROR_MESSAGE;
        }

        return messagePrefix + finalMessage;
    }
}
