<ng-template #idTmpl let-animal="item">
    <ng-container *ngIf="animal.Material">
        <input type="text"
               *ngIf="!(animal | isNew)"
               [(ngModel)]="animal.Material.Identifier"
               class="form-control input-medium"
               readonly="readonly" />
    </ng-container>
</ng-template>

<ng-template #nameEditMenuTmpl let-animal="item" let-bulkEditHeader="bulkEditHeaderComponent">
    <a onClick="event.stopPropagation();"
       class="dropdown-item"
       (click)="animal.useNameFormatInput = false; bulkEditHeader.viewBulkEditModal();"
       title="Enter, scan or paste multiple names to update each animal.  Limit is 600.">
        Enter multiple names
    </a>
    <a onClick="event.stopPropagation();"
       class="dropdown-item"
       (click)="animal.useNameFormatInput = true; bulkEditHeader.viewBulkEditModal();"
       title="Set a prefix, counter, and suffix to generate new names for each animal">
        Generate names using format
    </a>
</ng-template>

<ng-template #nameTmpl let-animal="item" let-section="section">
    <ng-container *ngIf="section === BulkEditSection.EditHeader">
        <multi-paste-input *ngIf="!animal.useNameFormatInput"
                           [(model)]="animal.AnimalNames"
                           [limit]="MULTI_PASTE_INPUT_LIMIT"
                           [rows]="10">
        </multi-paste-input>

        <animal-name-format-form *ngIf="animal.useNameFormatInput"
                                 [animal]="animal">
        </animal-name-format-form>
    </ng-container>

    <input type="text" name="animalName" *ngIf="section === BulkEditSection.InputCell"
           class="form-control input-medium"
           [(ngModel)]="animal.AnimalName"
           [disabled]="((animal | isNew) && animalNamingActive) || section == BulkEditSection.AddScreen"
           [required]="!(animal | isNew) || !animalNamingActive" />

    <ng-container *ngIf="section === BulkEditSection.AddScreen">
        <small class="form-text text-muted" *ngIf="!animalNamingActive && animalsToAdd > 200">
            Enable auto-naming to add more than 200 animals.
        </small>
        <small class="form-text text-muted" *ngIf="!animalNamingActive && animalsToAdd <= 200">
            Edit and Save records to enter names.
        </small>
    </ng-container>
</ng-template>

<ng-template #housingTmpl let-animal="item">
    {{animal.Material?.MaterialPoolMaterial | currentMaterialHousingID}}
</ng-template>

<ng-template #lineTmpl let-animal="item" let-section="section">
    <div style="min-width: 300px;" *ngIf="animal.Material">
        <!-- line is required in AddScreen and InputCell -->
        <line-select [(model)]="animal.Material.C_Line_key"
                     (modelChange)="lineChanged(animal, section)"
                     [required]="section!==BulkEditSection.EditHeader"></line-select>
    </div>
</ng-template>
<ng-template #statusTmpl let-animal="item" let-section="section">
    <active-vocab-select [(model)]="animal.C_AnimalStatus_key"
                         (modelChange)="section !== BulkEditSection.EditHeader && statusChanged(animal)"
                         [vocabChoices]="animalStatuses"
                         [keyFormatter]="animalStatusKeyFormatter"
                         [optionFormatter]="animalStatusFormatter"
                         [required]="requiredFields.includes('C_AnimalStatus_key')"
                         [nullable]="!requiredFields.includes('C_AnimalStatus_key')"
                         [defaultValue]="animalStatusDefaultKey"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen">
    </active-vocab-select>
</ng-template>

<ng-template *ngIf="isGLP" #heldForTmpl let-animal="item" let-section="section">
    <input type="text" name="heldFor"
           class="form-control input-medium"
           maxlength="250"
           [(ngModel)]="animal.HeldFor"
           [required]="requiredFields.includes('HeldFor')">
</ng-template>

<ng-template #breedingStatusTmpl let-animal="item" let-section="section">
    <active-vocab-select [(model)]="animal.C_BreedingStatus_key"
                         [vocabChoices]="breedingStatuses"
                         [keyFormatter]="breedingStatusKeyFormatter"
                         [optionFormatter]="breedingStatusFormatter"
                         [required]="requiredFields.includes('C_BreedingStatus_key')"
                         [nullable]="!requiredFields.includes('C_BreedingStatus_key')"
                         [defaultValue]="breedingStatusDefaultKey"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen">
    </active-vocab-select>
</ng-template>

<ng-template *ngIf="isGLP" #animalClassificationTmpl let-animal="item" let-section="section">
    <active-vocab-select [(model)]="animal.C_AnimalClassification_key"
                         [vocabChoices]="animalClassifications"
                         [keyFormatter]="animalClassificationKeyFormatter"
                         [optionFormatter]="animalClassificationFormatter"
                         [required]="requiredFields.includes('C_AnimalClassification_key')"
                         [nullable]="!requiredFields.includes('C_AnimalClassification_key')"
                         [defaultValue]="animalClassificationDefaultKey"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen">
    </active-vocab-select>
</ng-template>

<ng-template #ownerTmpl let-animal="item">
    <user-select [(model)]="animal.Owner"
                 [required]="requiredFields.includes('Owner')"
                 [forceValidSelection]="false">
    </user-select>
</ng-template>

<ng-template #animalUseTmpl let-animal="item" let-section="section">
    <active-vocab-select [(model)]="animal.C_AnimalUse_key"
                         [vocabChoices]="animalUses"
                         [keyFormatter]="animalUseKeyFormatter"
                         [optionFormatter]="animalUseFormatter"
                         [required]="requiredFields.includes('C_AnimalUse_key')"
                         [nullable]="!requiredFields.includes('C_AnimalUse_key')"
                         [defaultValue]="animalUseDefaultKey"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen">
    </active-vocab-select>
</ng-template>

<ng-template #iacucProtocolTmpl let-animal="item" let-section="section">
    <active-vocab-select [(model)]="animal.C_IACUCProtocol_key"
                         [vocabChoices]="iacucProtocols"
                         [keyFormatter]="iacucProtocolKeyFormatter"
                         [optionFormatter]="iacucProtocolFormatter"
                         [required]="requiredFields.includes('C_IACUCProtocol_key')"
                         [nullable]="!requiredFields.includes('C_IACUCProtocol_key')"
                         [defaultValue]="iacucProtocolDefaultKey"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen">
    </active-vocab-select>
</ng-template>

<ng-template #permitNumberTmpl let-animal="item" let-section="section">
    <div class="entity-dropdown">
        <permit-select [(model)]="animal.C_Permit_key" (modelChange)="onPermitSelect($event, section)"></permit-select>
    </div>
</ng-template>
<ng-template #permitOwnerTmpl let-animal="item" let-section="section">
    {{ section === BulkEditSection.AddScreen ? (permitOwner | userFullName) : (animal?.Permit?.Resource?.ResourceName | userFullName) }}
</ng-template>

<ng-template #originTmpl let-animal="item" let-section="section">
    <ng-container *ngIf="animal.Material">
        <active-vocab-select [(model)]="animal.Material.C_MaterialOrigin_key"
                             [vocabChoices]="materialOrigins"
                             [keyFormatter]="materialOriginKeyFormatter"
                             [optionFormatter]="materialOriginFormatter"
                             [required]="requiredFields.includes('Material.C_MaterialOrigin_key')"
                             [nullable]="!requiredFields.includes('Material.C_MaterialOrigin_key')"
                             [defaultValue]="originDefaultKey"
                             [loadDefaultValue]="section === BulkEditSection.AddScreen">
        </active-vocab-select>
    </ng-container>
</ng-template>

<ng-template #sexTmpl let-animal="item">
    <active-vocab-select [(model)]="animal.C_Sex_key"
                         [vocabChoices]="sexes"
                         [keyFormatter]="sexKeyFormatter"
                         [optionFormatter]="sexFormatter"
                         [required]="requiredFields.includes('C_Sex_key')"
                         [nullable]="!requiredFields.includes('C_Sex_key')">
    </active-vocab-select>

</ng-template>

<ng-template #generationTmpl let-animal="item" let-section="section">
    <active-vocab-select [(model)]="animal.C_Generation_key"
                         [vocabChoices]="generations"
                         [keyFormatter]="generationKeyFormatter"
                         [optionFormatter]="generationFormatter"
                         [required]="requiredFields.includes('C_Generation_key')"
                         [nullable]="!requiredFields.includes('C_Generation_key')"
                         [defaultValue]="generationsDefaultKey"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen">
    </active-vocab-select>
</ng-template>

<ng-template #dietTmpl let-animal="item" let-section="section">
    <active-vocab-select [(model)]="animal.C_Diet_key"
                         [vocabChoices]="diets"
                         [keyFormatter]="dietKeyFormatter"
                         [optionFormatter]="dietFormatter"
                         [required]="requiredFields.includes('C_Diet_key')"
                         [nullable]="!requiredFields.includes('C_Diet_key')"
                         [defaultValue]="dietDefaultKey"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen">
    </active-vocab-select>
</ng-template>

<ng-template #shipmentIdTmpl let-animal="item">
    <input type="text" name="shipmentID"
           class="form-control input-medium"
           maxlength="50"
           [(ngModel)]="animal.ShipmentID"
           [required]="requiredFields.includes('ShipmentID')">
</ng-template>

<ng-template #vendorIdTmpl let-animal="item">
    <input type="text" name="vendorID"
           class="form-control input-medium"
           maxlength="50"
           [(ngModel)]="animal.VendorID"
           [required]="requiredFields.includes('VendorID')">
</ng-template>

<ng-template #orderIdTmpl let-animal="item">
    <div class="entity-dropdown">
        <order-select [(model)]="animal.C_Order_key"
                      [animalOrdersOnly]="true"
                      [required]="requiredFields.includes('C_Order_key')">
        </order-select>
    </div>
</ng-template>

<ng-template *ngIf="isGLP" #cITESNumberTmpl let-animal="item" let-section="section">
    <input type="text" name="cITESNumber"
           class="form-control input-medium"
           maxlength="250"
           [(ngModel)]="animal.CITESNumber"
           [required]="requiredFields.includes('CITESNumber')">
</ng-template>

<ng-template #dateOriginTmpl let-animal="item">
    <climb-ngb-date #dateControl="ngModel"
                    name="Date Origin"
                    [(ngModel)]="animal.DateOrigin"
                    [required]="requiredFields.includes('DateOrigin')">
    </climb-ngb-date>
</ng-template>

<ng-template #dateBornTmpl let-animal="item">
    <climb-ngb-date #dateControl="ngModel"
                    name="Date Born"
                    [(ngModel)]="animal.DateBorn"
                    [required]="requiredFields.includes('DateBorn')">
    </climb-ngb-date>
</ng-template>
<ng-template #ageWeeksTmpl let-animal="item">
    {{animal.DateBorn | animalAgeWeeks:animal.DateExit | number}}
</ng-template>

<ng-template #dateExitTmpl let-animal="item">
    <climb-ngb-date #dateControl="ngModel"
                    name="Date Exit"
                    [(ngModel)]="animal.DateExit"
                    [required]="requiredFields.includes('DateExit')">
    </climb-ngb-date>
</ng-template>

<ng-template #exitReasonTmpl let-animal="item">
    <active-vocab-select [(model)]="animal.C_ExitReason_key"
                         [vocabChoices]="exitReasons"
                         [keyFormatter]="exitReasonKeyFormatter"
                         [optionFormatter]="exitReasonFormatter"
                         [required]="requiredFields.includes('C_ExitReason_key')"
                         [nullable]="!requiredFields.includes('C_ExitReason_key')">
    </active-vocab-select>
</ng-template>

<ng-template #markerTypeTmpl let-animal="item" let-section="section">
    <active-vocab-select [(model)]="animal.C_PhysicalMarkerType_key"
                         [vocabChoices]="physicalMarkerTypes"
                         [keyFormatter]="physicalMarkerTypeKeyFormatter"
                         [optionFormatter]="physicalMarkerTypeFormatter"
                         [defaultValue]="physicalMarkerTypeDefaultKey"
                         [loadDefaultValue]="section === BulkEditSection.AddScreen"
                         [required]="requiredFields.includes('C_PhysicalMarkerType_key')"
                         [nullable]="!requiredFields.includes('C_PhysicalMarkerType_key')">
    </active-vocab-select>
</ng-template>

<ng-template #markerTmpl let-animal="item">
    <input *ngIf="!(animal.cv_PhysicalMarkerType?.Vendor)"
           type="text"
           name="physicalMarker"
           class="form-control input-medium"
           size="10"
           [(ngModel)]="animal.PhysicalMarker"
           [required]="requiredFields.includes('PhysicalMarker')" />
    <animal-marker *ngIf="animal.cv_PhysicalMarkerType?.Vendor"
                   [marker]="animal.PhysicalMarker"
                   [markerType]="animal.cv_PhysicalMarkerType">
    </animal-marker>
</ng-template>

<ng-template #microchipIdTmpl let-animal="item" let-section="section">
    <multi-paste-input *ngIf="section === BulkEditSection.EditHeader"
                       [(model)]="animal.MicrochipIdentifiers"
                       [limit]="MULTI_PASTE_INPUT_LIMIT"
                       [rows]="10">
    </multi-paste-input>

    <ng-container *ngIf="!isGLP && section === BulkEditSection.InputCell">
        <input type="text"
               name="microchipIdentifier"
               class="form-control input-medium"
               [(ngModel)]="animal.Material.MicrochipIdentifier"
               [required]="requiredFields.includes('Material.MicrochipIdentifier')" />
    </ng-container>
    <ng-container *ngIf="isGLP && section === BulkEditSection.InputCell">
        <input type="text"
               class="form-control input-medium"
               name="microchipIdentifier"
               [ngClass]="{ 'ng-invalid': microchipIds[animal.C_Material_key] && microchipIds[animal.C_Material_key].error }"
               [(ngModel)]="animal.Material.MicrochipIdentifier"
               [ngModelOptions]="{ updateOn: 'blur' }"
               (ngModelChange)="microchipIdChanged(animal.Material)"
               [required]="requiredFields.includes('Material.MicrochipIdentifier')" />
        <small class="form-text text-muted" *ngIf="microchipIds[animal.C_Material_key] && microchipIds[animal.C_Material_key].error">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            The Microchip ID is already in use.
        </small>
    </ng-container>
</ng-template>

<ng-template #externalIdTmpl let-animal="item" let-section="section">
    <multi-paste-input *ngIf="section === BulkEditSection.EditHeader"
                       [(model)]="animal.ExternalIdentifiers"
                       [limit]="MULTI_PASTE_INPUT_LIMIT"
                       [disabled]="isDotmatics"
                       [rows]="10">
    </multi-paste-input>

    <input type="text" *ngIf="section === BulkEditSection.InputCell"
           name="externalIdentifier"
           class="form-control input-medium"
           [(ngModel)]="animal.Material.ExternalIdentifier"
           [disabled]="isDotmatics"
           [required]="requiredFields.includes('Material.ExternalIdentifier')" />
</ng-template>

<ng-template #alternatePhysicalIDTmpl let-animal="item" let-section="section">
    <multi-paste-input *ngIf="section === BulkEditSection.EditHeader"
                       [(model)]="animal.AlternatePhysicalIDs"
                       [limit]="MULTI_PASTE_INPUT_LIMIT"
                       [rows]="10">
    </multi-paste-input>
    <ng-container *ngIf="isGLP && section === BulkEditSection.InputCell">
        <input type="text"
               name="alternatePhysicalID"
               class="form-control input-medium"
               [ngClass]="{ 'ng-invalid': alternatePhysicalIds[animal.C_Material_key] && alternatePhysicalIds[animal.C_Material_key].error }"
               [(ngModel)]="animal.AlternatePhysicalID"
               [ngModelOptions]="{ updateOn: 'blur' }"
               (ngModelChange)="alternatePhysicalIdChanged(animal)"
               [required]="requiredFields.includes('AlternatePhysicalID')" />
        <small class="form-text text-muted" *ngIf="alternatePhysicalIds[animal.C_Material_key] && alternatePhysicalIds[animal.C_Material_key].error">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            The Alternate Physical ID is already in use.
        </small>
    </ng-container>
</ng-template>

<ng-template #commentsTmpl let-animal="item" let-section="section">
    <ng-container *ngIf="section === BulkEditSection.EditHeader">
        <textarea name="comments" class="form-control input-large" [(ngModel)]="bulkFillAnimalComment"></textarea>
    </ng-container>
    <ng-container *ngIf="section === BulkEditSection.InputCell">
        <ng-container *ngIf="animal.AnimalComment.length > 0">
        <label *ngIf="animal.AnimalComment[animal.AnimalComment.length - 1].entityAspect.entityState.name !== 'Added'" style="font-weight: 700;" class="col-form-label">Most Recent</label>
            <div *ngFor="let animalComment of [animal.AnimalComment[animal.AnimalComment.length - 1]]; trackBy: trackItem"
                [class]="animalComment | animalCommentStatusStyle">
                <textarea name="comments"
                        class="form-control input-large"
                        (change)="animalCommentChanged($event, animal)"
                        [value]="animalComment.Comment || ''">
                </textarea>
            </div>
        </ng-container>
        <ng-container *ngIf="animal.AnimalComment.length === 0">
            <div *ngFor="let animalComment of [animal.AnimalComment]; trackBy: trackItem"
                [class]="animalComment | animalCommentStatusStyle">
                <textarea name="comments"
                        class="form-control input-large"
                        (change)="animalCommentChanged($event, animal)"
                        [value]="animalComment.Comment || ''">
                </textarea>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #commentStatusTmpl let-animal="item" let-section="section">
    <label *ngIf="section === BulkEditSection.InputCell && animal.AnimalComment.length > 0 && animal.AnimalComment[animal.AnimalComment.length - 1].entityAspect.entityState.name !== 'Added'"
        class="col-form-label hidden">Most Recent</label>
    <ng-container *ngIf="section === BulkEditSection.InputCell && animal.AnimalComment.length > 0 && systemGeneratedAnimalCommentStatus | async">
        <div *ngFor="let animalComment of [animal.AnimalComment[animal.AnimalComment.length - 1]]; trackBy: trackItem"
             [class]="animalComment | animalCommentStatusStyle">
            <active-vocab-select [(model)]="animalComment.C_AnimalCommentStatus_key"
                                 (modelChange)="onModelChange(animalComment)"
                                 [vocabChoices]="animalCommentStatuses"
                                 [keyFormatter]="animalCommentStatusKeyFormatter"
                                 [optionFormatter]="animalCommentStatusFormatter"
                                 [loadSystemGeneratedValue]="true"
                                 [systemGeneratedValue]="systemGeneratedAnimalCommentStatus | async"
                                 [required]="true">
            </active-vocab-select>
        </div>
    </ng-container>
    <ng-container *ngIf="section === BulkEditSection.InputCell && animal.AnimalComment.length === 0">
        <active-vocab-select [vocabChoices]="animalCommentStatuses"
                             (modelChange)="onChangeAnimalCommentStatus(animal, $event)"
                             [keyFormatter]="animalCommentStatusKeyFormatter"
                             [optionFormatter]="animalCommentStatusFormatter">
        </active-vocab-select>
    </ng-container>
    <ng-container *ngIf="section === BulkEditSection.EditHeader">
        <active-vocab-select [(model)]="bulkFillAnimalCommentStatusKey"
                             [vocabChoices]="animalCommentStatuses"
                             [keyFormatter]="animalCommentStatusKeyFormatter"
                             [optionFormatter]="animalCommentStatusFormatter">
        </active-vocab-select>
    </ng-container>
</ng-template>

<ng-template #jobTmpl let-animal="item" let-section="section">
    <div class="entity-dropdown"
         *ngIf="animal.Material && section === BulkEditSection.InputCell">
        <material-job-select [material]="animal.Material" [isGLP]="isGLP"></material-job-select>
    </div>
    <ng-container *ngIf="section !== BulkEditSection.InputCell">
        <job-select [excludeLocked]="true"
                    [(model)]="animal.JobKey">
        </job-select>
    </ng-container>
</ng-template>

<ng-template #characteristicTmpl let-animal="item" let-section="section">
    <ng-container *ngIf="section === BulkEditSection.InputCell; else verticalLayout;">
        <ng-container *ngIf="!characteristicsLoading">
            <table class="table table-sm table-borderless">
                <tbody>
                    <tr>
                        <th *ngFor="let characteristic of animal.TaxonCharacteristicInstance | orderBy:'TaxonCharacteristic.SortOrder'">
                            <label *ngIf="!characteristic.TaxonCharacteristic?.ListViewOrder && characteristic.TaxonCharacteristic.IsActive" class="col-form-label">
                                {{characteristic?.TaxonCharacteristic.CharacteristicName}}
                            </label>
                        </th>
                    </tr>
                    <tr>
                        <td *ngFor="let characteristic of animal.TaxonCharacteristicInstance | orderBy:'TaxonCharacteristic.SortOrder'">
                            <characteristic-input *ngIf="!characteristic.TaxonCharacteristic?.ListViewOrder && characteristic.TaxonCharacteristic.IsActive"
                                                  #characteristicInput
                                                  [characteristic]="characteristic"
                                                  [(value)]="characteristic.CharacteristicValue"
                                                  [cssClass]="'input-small'"></characteristic-input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <ng-container *ngIf="characteristicsLoading">
            Loading Characteristics...
        </ng-container>
    </ng-container>

    <ng-template #verticalLayout>
        <div class="form-group"
             *ngFor="let characteristic of animal.TaxonCharacteristicInstance |
                    orderBy:'TaxonCharacteristic.SortOrder'">
            <label class="col-md-12 col-form-label">
                {{characteristic?.CharacteristicName}}
            </label>
            <div class="col-md-12">
                <characteristic-input #characteristicInput
                                      [characteristic]="characteristic"
                                      [(value)]="characteristic.CharacteristicValue"
                                      [cssClass]="'input-small'"></characteristic-input>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #refreshCharacteristicsButtonTmpl>
    <button
            *ngIf="isRefreshNeeded"
            type="button"
            title="Apply relevant animal characteristics to the record"
            class="bg-color-warning btn ml-1 mb-1"
            (click)="onRefreshCharacteristics()">
        <i class="fa fa-history"></i>
    </button>
</ng-template>

<ng-template #dynamicCharacteristicTmpl let-animal="item" let-section="section" let-field="field">
    <ng-container *ngIf="section === BulkEditSection.InputCell && section !== BulkEditSection.AddScreen">
        <ng-container *ngFor="let characteristic of animal.TaxonCharacteristicInstance | getTaxonCharacteristicInstance: field.label">
            <characteristic-input [characteristic]="characteristic"
                                  [(value)]="characteristic.CharacteristicValue">
            </characteristic-input>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="section === BulkEditSection.EditHeader">
        <characteristic-input [characteristic]="taxonCharacteristicMap[field.label]"
                              [(value)]="taxonCharacteristicMap[field.label].CharacteristicValue">

        </characteristic-input>
    </ng-container>
</ng-template>

<ng-template #genotypeTmpl let-animal="item" let-section="section">
    <ng-container *ngIf="section !== BulkEditSection.InputCell">
        <label class="col-md-12 col-form-label">Assay 1</label>
        <genotype-assay-select [(model)]="animal.Genotype[0].cv_GenotypeAssay.C_GenotypeAssay_key"
                               [assayChoices]="genotypeAssays"
                               [parentLineKey]="animal.Material?.C_Line_key"
                               [noFilterOnNullParent]="false"
                               *ngIf="genotypeAssays">
        </genotype-assay-select>
        <label class="col-md-12 col-form-label">Symbol 1</label>
        <genotype-symbol-select [(model)]="animal.Genotype[0].cv_GenotypeSymbol.C_GenotypeSymbol_key"
                                [symbolChoices]="genotypeSymbols"
                                [parentAssayKey]="animal.Genotype[0]?.cv_GenotypeAssay?.C_GenotypeAssay_key"
                                [noFilterOnNullParent]="false"
                                *ngIf="genotypeSymbols">
        </genotype-symbol-select>
        <ng-container *ngIf="secondGT">
            <label class="col-md-12 col-form-label">Assay 2</label>
            <genotype-assay-select [(model)]="animal.Genotype[1].cv_GenotypeAssay.C_GenotypeAssay_key"
                                   [assayChoices]="genotypeAssays"
                                   [parentLineKey]="animal.Material?.C_Line_key"
                                   [noFilterOnNullParent]="false"
                                   *ngIf="genotypeAssays">
            </genotype-assay-select>
            <label class="col-md-12 col-form-label">Symbol 2</label>
            <genotype-symbol-select [(model)]="animal.Genotype[1].cv_GenotypeSymbol.C_GenotypeSymbol_key"
                                    [symbolChoices]="genotypeSymbols"
                                    [parentAssayKey]="animal.Genotype[1]?.cv_GenotypeAssay?.C_GenotypeAssay_key"
                                    [noFilterOnNullParent]="false"
                                    *ngIf="genotypeSymbols">
            </genotype-symbol-select>
        </ng-container>
        <div class="input-group mt-2" [ngClass]="{'disabled':secondGT}">
            <div class="input-group-prepend">
                <span class="input-group-text">Add Genotype</span>
            </div>
            <div class="input-group-append">
                <a class="add-item-link input-group-text px-3 border-left-0" (click)="secondGT = true">
                    <i class="fa fa-plus-circle" title="Add Genotype"></i>
                </a>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="section === BulkEditSection.InputCell">
        <ul class="list-unstyled form-control-plaintext" *ngIf="animal.Genotype.length > 0">
            <li *ngFor="let genotype of animal.Genotype">
                {{genotype.cv_GenotypeAssay?.GenotypeAssay}}&nbsp;{{genotype.cv_GenotypeSymbol?.GenotypeSymbol}}
            </li>
        </ul>
    </ng-container>
</ng-template>
