import { isNil } from '@lodash';
import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';

import { randomId } from './util';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
    selector: 'active-vocab-select',
    template: `
    <select [attr.name]="_fieldName"
            [id]="id"
            [ngStyle]="{'width': fullWidth ? '-webkit-fill-available': ''}"
            class="form-control input-medium"
            [(ngModel)]="model"
            (ngModelChange)="modelChangeHandler($event)"
            (change)="updateSelectWidth(id)"
            [disabled]="readonly"
            [required]="required"
            [title]="tooltip">
        <option *ngIf="loadSystemGeneratedValue && systemGeneratedValue && model === _formattedSystemGeneratedValue.key"
                [ngValue]="_formattedSystemGeneratedValue.key"
                hidden>
            {{_formattedSystemGeneratedValue.option}}
        </option>
        <option *ngIf="nullable"></option>
        <option *ngFor="let item of _filteredVocabChoices"
                [attr.data-automation-id]="item.option | dataAutomationId: '': '-item'"
                [ngValue]="item.key">
            {{item.option}}
        </option>
    </select>
    `,
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ActiveVocabSelectComponent),
            multi: true,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ActiveVocabSelectComponent),
            multi: true,
        }
    ]
})
export class ActiveVocabSelectComponent implements OnChanges, OnInit, ControlValueAccessor, Validator {
    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    @Input() vocabChoices: any[];
    // Function to retrieve the key for <option> value
    @Input() keyFormatter: (item: any) => any;
    // Function to display <option> item (What user will see)
    @Input() optionFormatter: (item: any) => string;

    @Input() id: string;
    // Optional include both active and inactive values
    @Input() includeInactive: boolean;
    // a blank <option> is supplied
    @Input() nullable: boolean;
    // selector is disabled if set to have readonly access
    @Input() readonly: boolean;
    @Input() required = false;
    @Input() fullWidth = true;
    @Input() dynamicWidth: boolean;
    @Input() displayName = 'field';

    @Input() systemGeneratedValue: any;
    @Input() loadSystemGeneratedValue: boolean;

    // Default value
    @Input() defaultValue: any;
    // Default value should be selected or not?
    @Input() loadDefaultValue: boolean;

    @Input() tooltip = '';

    _fieldName: string;

    selectLengthChecked: boolean;

    // state variables
    _filteredVocabChoices: any[];
    _formattedSystemGeneratedValue: any;
    value: number;
    onChange = (value: number): void => {/*empty*/};
    onTouched = (): void => {/*empty*/};

    ngOnInit() {
        this._fieldName = "field_" + randomId();      

        if (!this.id) {
            this.id = this._fieldName;
        }

        if (!this.keyFormatter) {
            this.keyFormatter = (item: any) => item;
        }

        if (!this.optionFormatter) {
            this.optionFormatter = (item: any) => item;
        }

        this.refreshActiveVocabItems();

        if (this.loadSystemGeneratedValue && this.systemGeneratedValue) {
            this.updateFormattedSystemGeneratedValue();
        }
    }

    ngOnChanges(changes: any) {
        // re-trigger active vocab filter if
        //   inputs have changed
        if (changes.model ||
            changes.vocabChoices
        ) {
            this.refreshActiveVocabItems();
        }

        if (this.loadSystemGeneratedValue && changes.systemGeneratedValue && this.systemGeneratedValue) {
            this.updateFormattedSystemGeneratedValue();
        }
        
        if (changes.defaultValue) {
            // Select default value only if no value have been selected
            if (this.loadDefaultValue && this.defaultValue && !this.model) {
                this.model = this.defaultValue;
                this.modelChangeHandler();
            }
        }
    }

    refreshActiveVocabItems() {
        let newVocabItems = this.vocabChoices;

        if (!this.includeInactive) {
            newVocabItems = this.filterActiveVocabItems(
                this.vocabChoices,
                this.model,
                this.keyFormatter
            );
        }

        newVocabItems = this.formatActiveVocabItems(
            newVocabItems,
            this.keyFormatter,
            this.optionFormatter
        );

        this._filteredVocabChoices = newVocabItems;
    }

    updateFormattedSystemGeneratedValue() {
        this._formattedSystemGeneratedValue = this.formatActiveVocabItems(
            [this.systemGeneratedValue],
            this.keyFormatter,
            this.optionFormatter
        )[0];
    }

    /*
    * Filter out inactive vocab items,
    *   unless equal to current model
    */
    filterActiveVocabItems(
        items: any[], model: any, keyFormatter: (item: any) => any
    ) {
        if (!items) {
            return [];
        }

        return items.filter((item: any) => {
            const itemKey = keyFormatter(item);
            return item.IsActive || itemKey === model;
        });
    }

    /*
    * Format vocab items with keyFormatter
    *   and optionFormatter
    */ 
    formatActiveVocabItems(
        items: any[],
        keyFormatter: (item: any) => any,
        optionFormatter: (item: any) => string
    ) { 
        if (!items) {
            return [];
        }

        return items.map((item: any) => {
            return {
                key: keyFormatter(item),
                option: optionFormatter(item)
            };
        });
    }

    modelChangeHandler(value?: number): void {
        this.modelChange.emit(this.model);
        this.value = value;
        this.onChange(value);
    }

    updateSelectWidth(id: string): void {
        if (this.dynamicWidth) {
            const selected = jQuery("#" + id).first();
            const selectedOption = jQuery("#" + id + " option:selected").first();
            const aux = jQuery("<select/>").append(jQuery("<option/>").text(selectedOption.text()));
            selected.after(aux);
            selected.width(aux.width());
            if (aux.width() > 0) {
                this.selectLengthChecked = true;
            }
            aux.remove();
        }
    }

    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    validate(control: FormControl): ValidationErrors | null {
        if (this.required && (isNil(control.value) || control.value === '')) {
            return { message: `The "${this.displayName}" is required`};
        }
        return null;
    }

    writeValue(value: number): void {
        this.value = value;
        this.model = value;
    }
}
