<loading-overlay *ngIf="loading" [loadingMessage]="loadingMessage"></loading-overlay>
<table *ngIf="!loading && permitSpeciesControl" class="table table-bordered" [formGroup]="form">
    <thead>
        <tr class="detail-grid-header">
            <th colspan="12">
                <a class="add-item-link" (click)="addPermitSpecies()">
                    <i class="fa fa-plus-circle" title="Add species"></i>
                </a>
                <span [ngClass]="{'required-text': required && permitSpeciesControl.length == 0}">Species Information</span>
            </th>
        </tr>
        <tr class="detail-grid-header">
            <th class="icon-cell"></th>
            <th>Species</th>
            <th>{{translationService.translate('Line')}}(s)</th>
            <th *ngIf="activeFields.includes('Animals On Permit')">Animals On Permit</th>
            <th *ngIf="activeFields.includes('Highest Severity')">Highest Severity Allowed</th>
            <th *ngIf="activeFields.includes('Non-Recovery')">Non-Recovery Allowed</th>
            <th *ngIf="activeFields.includes('Reuse Allowed')">Reuse Allowed</th>
            <th *ngIf="activeFields.includes('Origin(s)')">Origin(s)</th>
            <th *ngIf="activeFields.includes('End State Animals')">End State Animals</th>
            <th *ngIf="activeFields.includes('Live Animals')">Live Animals</th>
            <th *ngIf="activeFields.includes('Approx. Animals Assigned')">Approx. Animals Assigned</th>
            <th *ngIf="activeFields.includes('Approx. Animals Remaining')">Approx. Animals Remaining</th>
        </tr>
    </thead>
    <tbody formArrayName="PermitSpecies">
        <tr *ngFor="let permitSpecies of permit?.PermitSpecies; let i = index" [formGroupName]="i">
            <td class="icon-cell">
                <a (click)="deletePermitSpecies(permitSpecies, i)">
                    <i class="fa fa-remove remove-item" title="Delete species"></i>
                </a>
            </td>
            <td>
                <active-vocab-select data-automation-id="permit-species-dropdown"
                    formControlName="SpeciesKey"
                    [vocabChoices]="species"
                    [keyFormatter]="permitSpeciesKeyFormatter"
                    [optionFormatter]="permitSpeciesFormatter"
                    [displayName]="'Species'"
                    [required]="true">
                </active-vocab-select>
            </td>
            <td>
                <line-multiselect
                    formControlName="PermitSpeciesLine"
                    [isOnlyActiveSearch]="true"
                    [displayName]="translationService.translate('Line')"
                    [required]="true">
                </line-multiselect>
            </td>
            <td *ngIf="activeFields.includes('Animals On Permit')">
                <input
                    type="number"
                    class="form-control input-medium"
                    placeholder="Enter number of animals"
                    formControlName="AnimalsOnPermit"
                    [min]="0"
                    step="1"
                    climbNgControlNumberInput
                    [required]="requiredFields.includes('AnimalsAllowedOnPermit')">
            </td>
            <td *ngIf="activeFields.includes('Highest Severity')">
                <active-vocab-select *ngIf="severities.length" data-automation-id="permit-severity-dropdown"
                    formControlName="SeverityKey"
                    [vocabChoices]="severities"
                    [keyFormatter]="severityKeyFormatter"
                    [optionFormatter]="severityFormatter"
                    [displayName]="'Highest Severity Allowed'"
                    [nullable]="true"
                    [required]="requiredFields.includes('HighestSeverity')">
                </active-vocab-select>
            </td>
            <td class="text-center" *ngIf="activeFields.includes('Non-Recovery')">
                <input type="checkbox" formControlName="NoRecoveryAllowed" name="no_recovery_allowed" id="no_recovery_allowed">
            </td>
            <td class="text-center" *ngIf="activeFields.includes('Reuse Allowed')">
                <input 
                    type="checkbox" 
                    formControlName="ReuseAllowed" 
                    name="reuse_allowed" 
                    id="reuse_allowed">
            </td>
            <td *ngIf="activeFields.includes('Origin(s)')">
                <active-vocab-typeahead-multi-select 
                    formControlName="PermitSpeciesOrigin" 
                    [vocabTableName]="'cv_MaterialOrigin'" 
                    [includeInactive]="false"
                    [required]="requiredFields.includes('Origins')">
                </active-vocab-typeahead-multi-select>
            </td>
            <td *ngIf="activeFields.includes('End State Animals')">{{permitSpecies?.EndStateAnimals}}</td>
            <td *ngIf="activeFields.includes('Live Animals')">{{permitSpecies?.LiveAnimals}}</td>
            <td *ngIf="activeFields.includes('Approx. Animals Assigned')">{{permitSpecies?.TotalAnimals}}</td>
            <td *ngIf="activeFields.includes('Approx. Animals Remaining')">{{permitSpecies?.AnimalsRemaining}}</td>
        </tr>
    </tbody>
</table>
