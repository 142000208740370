<ng-container *ngIf="!dynamicallyLoaded">
    <ng-container [ngSwitch]="facet?.FacetName | lowercase">
        <animal-facet *ngSwitchCase="'animals'" [facet]="facet"></animal-facet>
        <audit-facet *ngSwitchCase="'audit'" [facet]="facet"></audit-facet>
        <birth-facet *ngSwitchCase="'births'" [facet]="facet"></birth-facet>
        <characteristics-facet *ngSwitchCase="'characteristics'" [facet]="facet"></characteristics-facet>
        <charts-facet *ngSwitchCase="'charts'" [facet]="facet"></charts-facet>
        <clinical-facet *ngSwitchCase="'clinical'" [facet]="facet"></clinical-facet>
        <construct-facet *ngSwitchCase="'constructs'" [facet]="facet"></construct-facet>
        <!-- <cohort-facet *ngSwitchCase="'cohorts'" [facet]="facet"></cohort-facet> -->
        <device-management-facet *ngSwitchCase="'devices'" [facet]="facet"></device-management-facet>
        <enumerations-facet *ngSwitchCase="'enumerations'" [facet]="facet"></enumerations-facet>
        <genotype-facet *ngSwitchCase="'genotypes'" [facet]="facet"></genotype-facet>
        <housing-facet *ngSwitchCase="'housing'" [facet]="facet"></housing-facet>
        <import-facet *ngSwitchCase="'import'" [facet]="facet"></import-facet>
        <institution-facet *ngSwitchCase="'institutions'" [facet]="facet"></institution-facet>
        <iot-alert-definition-facet *ngSwitchCase="'iot alerts'" [facet]="facet"></iot-alert-definition-facet>
        <iot-plots-facet *ngSwitchCase="'iot plots'" [facet]="facet"></iot-plots-facet>
        <!-- <job-facet *ngSwitchCase="'jobs'" [facet]="facet"></job-facet> -->
        <!-- <job-pharma-facet *ngSwitchCase="'jobs pharma'" [facet]="facet"></job-pharma-facet> -->
        <line-facet *ngSwitchCase="'lines'" [facet]="facet"></line-facet>
        <location-facet *ngSwitchCase="'locations'" [facet]="facet"></location-facet>
        <mating-facet *ngSwitchCase="'matings'" [facet]="facet"></mating-facet>
        <monitoring-facet *ngSwitchCase="'monitoring'" [facet]="facet"></monitoring-facet>
        <!-- <order-facet *ngSwitchCase="'orders'" [facet]="facet"></order-facet> -->
        <plate-facet *ngSwitchCase="'plates'" [facet]="facet"></plate-facet>
        <protocol-facet *ngSwitchCase="'protocols'" [facet]="facet"></protocol-facet>
        <reporting-facet *ngSwitchCase="'reporting'" [facet]="facet"></reporting-facet>
        <!-- <resource-facet *ngSwitchCase="'resources'" [facet]="facet">></resource-facet> -->
        <roles-facet *ngSwitchCase="'roles'" [facet]="facet"></roles-facet>
        <sample-facet *ngSwitchCase="'samples'" [facet]="facet"></sample-facet>
        <!-- <schedule-facet *ngSwitchCase="'schedule'" [facet]="facet"></schedule-facet> -->
        <search-facet *ngSwitchCase="'search'" [facet]="facet"></search-facet>
        <settings-facet *ngSwitchCase="'settings'" [facet]="facet"></settings-facet>
        <study-facet *ngSwitchCase="'studies'" [facet]="facet"></study-facet>
        <task-facet *ngSwitchCase="'tasks'" [facet]="facet"></task-facet>
        <users-facet *ngSwitchCase="'users'" [facet]="facet"></users-facet>
        <vocabularies-facet *ngSwitchCase="'vocabularies'" [facet]="facet"></vocabularies-facet>
        <workflow-facet *ngSwitchCase="'workflows'" [facet]="facet"></workflow-facet>
        <census-facet *ngSwitchCase="'census'" [facet]="facet"></census-facet>
        <permits-facet *ngSwitchCase="'permits'" [facet]="facet"></permits-facet>

        <div *ngSwitchDefault>
            <!-- If given facet type is not configured in this app -->
            <div class="facet">
                <facet-header [caption]="facet?.FacetName"
                              [facet]="facet">
                </facet-header>
                <div class="facet-body">
                    Unconfigured Facet Type: {{facet?.FacetName}}
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="dynamicallyLoaded">
    <div #facetOutlet></div>
</ng-container>
